import React, { useEffect, useState } from "react";
import "./Payment.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import axios from "axios";
import confetti from "canvas-confetti";
import CustomScroll from "react-custom-scroll";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import ls from 'localstorage-slim'; 
// import { Loader } from "react-loader-spinner";
import { Oval } from "react-loader-spinner"; // Import the Oval spinner
import { Grid } from "react-loader-spinner"; // Import the Oval spinner

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);

const getCurrentDate = () => {
  const currentDate = new Date();
  // console.log(currentDate);
  return currentDate;
};

function CustomerPayment({ userID }) {
  const [payments, setPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loadingDataPayment, setLoadingDataPayment] = useState(true);
  const [selectedPaymentsDetails, setSelectedPaymentsDetails] = useState([]);

  const fetchData = async () => {
    try {
      if (user) {
        console.log("User connected");
  
        const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
        if (doc.exists) {
          const userData = doc.data();
          const storedToken = userData.token;
  
          if (storedToken) {
            const currentUserID = ls.get('userUid', { secret: encryptionKey });
            const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/PaymentSuivi/${currentUserID}`;
  
            const response = await fetch(apiUrl, {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            });
  
            if (!response.ok) {
              throw new Error('Réponse réseau incorrecte');
              setLoadingDataPayment(false);
            }
  
            const data = await response.json();
            setPayments(data);
            console.log('Récupération des données ventes avec succès');
            setLoadingDataPayment(false);
          } else {
            console.error("Le token n'est pas présent dans Firestore.");
            setLoadingDataPayment(false);
          }
        } else {
          console.error("L'utilisateur n'existe pas dans Firestore.");
          setLoadingDataPayment(false);
        }
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la récupération des données depuis l'API :", error);
      setLoadingDataPayment(false);
    }
    setLoadingDataPayment(false);
  };
  
  // console.log("loadingData" , loadingDataPayment);
  useEffect(() => {
    if (user) {
      console.log("User connected");

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/PaymentSuivi/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/PaymentSuivi/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte ');
                    setLoadingDataPayment(false);
                    // console.log("loadingData payment reponse" , loadingDataPayment);
                  }
                  return response.json();
                })
                .then((data) => {
                  setPayments(data);
                  console.log('Récupération des données ventes avec succès');
                  setLoadingDataPayment(false);
                  // console.log("loadingData payment" , loadingDataPayment);
                })
                .catch((error) => {
                  setError(error.message);
                  setLoadingDataPayment(false);
                  // console.log("loadingData payment" , loadingDataPayment);
                });
            } else {
              setLoadingDataPayment(false);
              console.error("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataPayment(false);
            console.error("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((error) => {
          setLoadingDataPayment(false);
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
          
        });
    }
  }, [user]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //         `http://localhost:3008/api/PaymentSuivi/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       setPayments(jsonData);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  const handleSendReminder = (payment) => {
    const email = payment.email; // Assuming the email property exists in your payment object
    const subject = 'Rappel échéance';
    const body = `Bonjour, ceci est un rappel pour votre échéance de paiement en date du ${payment.DatePayment.value} d'une valeur de ${payment.Amount.toFixed(2)} €. 
    Bien cordialement,`
  
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.location.href = mailtoUrl;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  
  const handlePaymentValidation = async (payment) => {
    try {
      // Logique pour envoyer les changements au serveur
      // console.log("Paiement validé :", payment);
      const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // Après la validation réussie, effectuez un nouvel appel à l'API
        const currentUserID = ls.get('userUid', { secret: encryptionKey });
        const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/PaymentSuivi/${currentUserID}`;
  
        // Utilisez une fonction asynchrone pour pouvoir utiliser await
        const fetchData = async () => {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Réponse réseau incorrecte');
          }
  
          const data = await response.json();
          setPayments(data);
          console.log('Données mises à jour avec succès');
        };
  
        await fetchData(); // Utilisez "await" ici pour attendre la fin de fetchData
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la validation du paiement :", error);
    }
    // console.log("Paiement validé :", payment);
  };


  // const handleSelectPayment = (payment) => {
  //   const isSelected = selectedPayments.includes(payment);
  //   if (isSelected) {
  //     setSelectedPayments(selectedPayments.filter((p) => p !== payment));
  //   } else {
  //     setSelectedPayments([...selectedPayments, payment]);
  //   }
  // };
  const handleSelectPayment = (payment) => {
    // Vérifie si le paiement est déjà sélectionné
    const isPaymentSelected = selectedPayments.includes(payment);
  
    // Crée une copie de la sélection actuelle
    let updatedSelectedPayments = [...selectedPayments];
  
    // Si le paiement est déjà sélectionné, le retire de la sélection
    if (isPaymentSelected) {
      updatedSelectedPayments = updatedSelectedPayments.filter(
        (selectedPayment) => selectedPayment !== payment
      );
    } else {
      // Si le paiement n'est pas sélectionné, l'ajoute à la sélection
      updatedSelectedPayments.push(payment);
    }
  
    // Met à jour la variable d'état avec les nouvelles sélections
    setSelectedPayments(updatedSelectedPayments);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPayments([]);
    } else {
      setSelectedPayments(payments);
    }
    setSelectAll(!selectAll);
  };

  const handleValidateAll = () => {
    // Appeler la fonction pour envoyer les données validées
    sendValidatedPayments(user);
  };

  // const handleEffectiveDateChange = (payment, event) => {
  //   const updatedPayment = { ...payment, EffectiveDate: event.target.value };
  //   setPayments((prevPayments) =>
  //     prevPayments.map((p) => (p === payment ? updatedPayment : p))
  //   );
  // };
  const handleEffectiveDateChange = (payment, event) => {
    const newEffectiveDate = event.target.value;
  
    const updatedPayments = payments.map((p) => {
      if (p === payment) {
        // Vérifie si la date de paiement effective est remplie, sinon utilise la date théorique
        const updatedPayment = {
          ...p,
          // EffectiveDate: newEffectiveDate,
          DatePayment: { value: newEffectiveDate } // Mettre à jour DatePayment avec EffectiveDate
        };
        return updatedPayment;
      }
      return p;
    });
  
    setPayments(updatedPayments);
  
    const isSelected = selectedPayments.includes(payment);
  
    if (isSelected) {
      const updatedSelected = selectedPayments.filter((p) => p !== payment);
      setSelectedPayments(updatedSelected);
    }
  };
  // console.log(user);
  const sendValidatedPayments = async (user) => {
    // console.log("user connecté pour la fonction sendValidatedPayments :" ,user);
    try {
      if (!user) {
        console.error("L'utilisateur n'est pas connecté.");
        return;
      }
  
      const currentUserID = ls.get('userUid', { secret: encryptionKey });
      // const url = `http://localhost:8080/api/insertDataPayment`;
      const url = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/insertDataPayment`;
  
      // Sélectionnez les paiements validés à envoyer
      // const validatedPayments = selectedPayments.map((payment) => ({
      //   ...payment,
      //   DatePayment: payment.DatePayment.value,
      // }));
      const validatedPayments = selectedPayments.map((payment) => ({
        Salesid: payment.Salesid,
        typePayment: payment.typePayment,
        time_indicator: payment.time_indicator,
        userId: payment.userId,
        Name: payment.Name,
        Amount: payment.Amount,
        DatePayment: payment.DatePayment.value,
      }));
  
      // Obtenez le jeton Firestore de l'utilisateur
      const doc = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

        // console.log("console : " ,  doc);
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
  
        if (storedToken) {
          // Effectuez une requête POST vers votre API Express pour envoyer les données
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Authorization': `Bearer ${storedToken}`,
            },
            body: JSON.stringify(validatedPayments),
          });
  
          if (!response.ok) {
            throw new Error("Erreur lors de l'envoi des données validées");
          }
  
          // Affichez un message de succès ou effectuez d'autres actions nécessaires
          console.log("Données validées envoyées avec succès");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données validées :", error);
      // Traitez l'erreur et affichez un message approprié à l'utilisateur
    }
    handleClick();
    setLoadingDataPayment(true);
    await fetchData();
  };

  const handleClick = () => {
    confetti({
      particleCount: 1000,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  const handlePopupConfirmation = () => {
    const paymentsInFuture = selectedPaymentsDetails.some(payment => new Date(payment.DatePayment.value) > getCurrentDate());
    // console.log(paymentsInFuture)
  if (paymentsInFuture) {
    alert("Attention, modifie la date pour le paiement, tu as mis une date dans le futur.");
    // Empêcher la validation si au moins un paiement est dans le futur
  } else {
    setShowPopup(false);
    handleValidateAll(user);
    handlePaymentValidation();
  }
  };

  const handlePopupModification = () => {
    setShowPopup(false);
    // Add your logic to handle the modification of the selection here
    // For example, you can navigate to a different page or display a form to modify the selection
  };


  const handlePopupOpen = () => {
    setShowPopup(true);
    setSelectedPaymentsDetails(selectedPayments);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
     <div className={`Container-Paiement ${showPopup ? 'popup-open' : 'popup-closed'}`}>
      <h1>Suis et valide tes paiements</h1>
      <br></br>

      {loadingDataPayment ? (
  
  <div className="loading-container">
  <div className="loading-animation">
    {/* Add your loading animation here */}
    {/* Calcul et chargement de vos données de paiement prestataires */}
    <Grid
      color="#A6B1F7" // Set the color
      height={100} // Set the size
      width={50}
      secondaryColor="#7F8BCC"
      fill="none"
      strokeWidth={8}
    />
  </div>
</div>
      ) : (
        <>
          {payments.length == 0 && (
            <span> Il n'y a pas de données paiement pour le moment </span>
          )}
          {payments.length > 0 && (
            <>
              <div>
                <div className="search-bar-Name">
                  {/* <h1>Paiements clients à valider</h1> */}
                  <input
                    type="text"
                    placeholder="Rechercher par nom"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="SearchInput"
                  />
                </div>
                <p></p>
  
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                      <th>Sélectionner</th>
                        <th>Nom du client</th>
                        <th>Montant du paiement </th>
                        <th>Date de paiement théorique</th>
                        <th>Date de paiement effective</th>
                        <th>Echéance de paiement</th>
                        <th>Envoyer un rappel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments
                        ? payments
                            .filter((payment) =>
                              (payment.Name ?? "")
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .map((payment, index) => (
                              <tr key={index}>
                                 <td>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectAll ||
                                      selectedPayments.includes(payment)
                                    }
                                    onChange={() =>
                                      handleSelectPayment(payment)
                                    }
                                  />
                                </td>
                                <td>{payment.Name}</td>
                                <td>{payment.Amount.toFixed(2)}€</td>
                                <td>{payment.DatePayment.value}</td>
                                <td>
                                  <input
                                    className="Date-effective"
                                    type="date"
                                    value={
                                      payment.EffectiveDate ||
                                      payment.DatePayment.value
                                    }
                                    onChange={(event) =>
                                      handleEffectiveDateChange(
                                        payment,
                                        event
                                      )
                                    }
                                    max={getCurrentDate().toISOString().split('T')[0]} // Définit la date maximale comme étant la date actuelle
                                  />
                                </td>
                                <td>
                                  {new Date(payment.DatePayment.value) <
                                  getCurrentDate() ? (
                                    <span className="red-text">
                                      Paiement en retard
                                    </span>
                                  ) : (
                                    <span>Paiement à venir</span>
                                  )}
                                </td>                                
                                <td>
            <button className="Reminder" onClick={() => handleSendReminder(payment)}>
              <img className="EmailImage" alt="Background" src="email.png" /><br></br>
            </button>
          </td>
                              </tr>
                            ))
                        : <p> Pas de données </p>}
                    </tbody>
                  </table>
                </div>
                <br></br>
                <button
                  onClick={handleSelectAll}
                  style={{ marginRight: "10px" }}
                >
                  {selectAll ? "Désélectionner tout" : "Sélectionner tout"}
                </button>
                <button onClick={handlePopupOpen}>Valider la sélection</button>
  
                {/* Popup */}
                {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <h2>Résumé de la sélection</h2>
                    <p>
                      Vérifiez bien votre sélection avant de valider, car cette action n'est pas modifiable.
                    </p>
                    <br></br>
                    {/* <h3>Ventes sélectionnées :</h3> */}
                    <ul>
                      {selectedPaymentsDetails.map((payment, index) => (
                        <li key={index}>
                          {payment.Name} - {payment.Amount.toFixed(2)}€
                        </li>
                      ))}
                    </ul>
                    <br></br>
                    <div className="popup-buttons">
                     
                      <button onClick={handlePopupModification}>
                        Modifier la sélection
                      </button>
                      <button onClick={handlePopupConfirmation}>
                        Valider la sélection
                      </button>
                    </div>
                  </div>
                </div>
              )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
  
}
export default CustomerPayment;
