export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";

export function addClient(client) {
  return { type: ADD_CLIENT, client };
}

export function editClient(id, client) {
  return { type: EDIT_CLIENT, id, client };
}

export function deleteClient(id) {
  return { type: DELETE_CLIENT, id };
}

export function addClientRequest(client) {
  return { type: ADD_CLIENT_REQUEST, client };
}

export function addClientSuccess(client) {
  return { type: ADD_CLIENT_SUCCESS, client };
}

export function addClientFailure(error) {
  return { type: ADD_CLIENT_FAILURE, error };
}

export function editClientRequest(id, client) {
  return { type: EDIT_CLIENT_REQUEST, id, client };
}

export function editClientSuccess(client) {
  return { type: EDIT_CLIENT_SUCCESS, client };
}

export function editClientFailure(error) {
  return { type: EDIT_CLIENT_FAILURE, error };
}

export function deleteClientRequest(id) {
  return { type: DELETE_CLIENT_REQUEST, id };
}

export function deleteClientSuccess(id) {
  return { type: DELETE_CLIENT_SUCCESS, id };
}

export function deleteClientFailure(error) {
  return { type: DELETE_CLIENT_FAILURE, error };
}
// Ces lignes de code définissent les actions pour ajouter, éditer et supprimer un client.
// Chaque action a trois types : REQUEST (pour indiquer que l'action est en cours), SUCCESS (pour indiquer que l'action a réussi) et FAILURE (pour indiquer qu'une erreur s'est produite lors de l'action).
// GET_CLIENTS_REQUEST: cette action est envoyée lorsque nous commençons à récupérer la liste des clients depuis l'API.
// GET_CLIENTS_SUCCESS: cette action est envoyée lorsque la récupération de la liste des clients est réussie. Elle contient la liste des clients sous forme de tableau.
// GET_CLIENTS_FAILURE: cette action est envoyée lorsque la récupération de la liste des clients échoue. Elle contient un message d'erreur.
// CREATE_CLIENT_REQUEST: cette action est envoyée lorsque nous commençons à créer un nouveau client.
// CREATE_CLIENT_SUCCESS: cette action est envoyée lorsque la création d'un nouveau client est réussie. Elle contient le nouveau client créé sous forme d'objet.
// CREATE_CLIENT_FAILURE: cette action est envoyée lorsque la création d'un nouveau client échoue. Elle contient un message d'erreur.
// UPDATE_CLIENT_REQUEST: cette action est envoyée lorsque nous commençons à mettre à jour un client existant.
// UPDATE_CLIENT_SUCCESS: cette action est envoyée lorsque la mise à jour d'un client existant est réussie. Elle contient le client mis à jour sous forme d'objet.
// UPDATE_CLIENT_FAILURE: cette action est envoyée lorsque la mise à jour d'un client existant échoue. Elle contient un message d'erreur.
// DELETE_CLIENT_REQUEST: cette action est envoyée lorsque nous commençons à supprimer un client existant.
// DELETE_CLIENT_SUCCESS: cette action est envoyée lorsque la suppression d'un client existant est réussie. Elle contient l'ID du client supprimé.
// DELETE_CLIENT_FAILURE: cette action est envoyée lorsque la suppression d'un client existant échoue. Elle contient un message d'erreur.
