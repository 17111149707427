import Menu from "./Menu";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Goals.css";
import FormGoalsAmount from "./FormGoalsAmount.js";
import FormGoalsCustomers from "./FormGoalsCustomers.js";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore"; 
 import firebase from "firebase/compat/app";
 import FooterComponent from "./Footer";
import ls from 'localstorage-slim'; 

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);
function Goals() {
 
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [stripeRole, setStripeRole] = useState("No"); // Ajoutez un état pour stocker le rôle Stripe
  const [redirected, setRedirected] = useState(false); // Ajoutez un état pour gérer la redirection
  
// console.log(user);
  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (error) {
      // Si une erreur d'authentification se produit, redirigez l'utilisateur vers la page de connexion
      navigate('/Login'); // Remplacez '/Login' par le chemin de votre page de connexion
    }
    if (loading) return;

    if (!currentUserID || !auth.currentUser ) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
      return;
    }
  }, [user, loading]);

  useEffect(() => {
    const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
    if (loading) return;
  
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
      return;
    }
  
    // Récupérez les revendications personnalisées de l'utilisateur
    auth.currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        const claims = idTokenResult.claims;
        const stripeRole = claims.stripeRole;
  
        if (stripeRole !== "Premium" && !redirected) {
          console.log("Redirection vers /Register");
          setRedirected(true);
          navigate('/NotPremiumPage'); // Redirige vers la page d'inscription si le rôle n'est pas "premium"
        } else if (stripeRole === "Premium" && !redirected && !user) {
          console.log("Redirection vers /Login");
          setRedirected(true);
          navigate('/Login'); // Redirige vers le tableau de bord si le rôle est "premium"
        }
  
        // Définissez le rôle Stripe dans l'état
        setStripeRole(stripeRole);
  
        // console.log("Test stripe role", stripeRole);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des revendications personnalisées :", error);
      });
  }, [user, loading, redirected, navigate]);
    // useEffect(() => {
    //   const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    //   if (loading) return;
    //   if (!currentUserID) {
    //     navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    //   }
    // }, [user, loading]);
  
    const fetchUserName = async () => {
      try {
        const currentUser = firebase.auth().currentUser;
  
        if (currentUser) {
          const q = query(collection(db, "users"), where("uid", "==", currentUser.uid));
          const doc = await getDocs(q);
          if (doc.docs.length > 0) {
            const data = doc.docs[0].data();
            setName(data.name);
          }
        }
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };


  return (
    <div className="Goals">
      <div className="Goals__container">
      
        <button className="Goals__btn" onClick={logout}>
          Se deconnecter
        </button>
      </div>
      <div className="menu__container">
        <Menu />
      </div>
      <div className="principal__container">
        <h1>Atteinte des objectifs </h1>
        <FormGoalsAmount className="FormGoalsAmountComp" />
        <br></br>
        <br></br>
        <FormGoalsCustomers className="FormGoalsCustomersComp" />
        <FooterComponent id ="Footer"  className="Footer" />
      </div>
    </div>
  );
}
export default Goals;

// Ce code est un composant React appelé "Goals" qui affiche un tableau de bord pour un utilisateur authentifié. Le composant utilise Firebase pour récupérer les données utilisateur à partir de Firestore.
// Les dépendances nécessaires pour le composant sont importées. Les hooks useAuthState et useNavigate sont utilisés pour récupérer l'état d'authentification de l'utilisateur et pour naviguer vers d'autres pages. La feuille de style pour le composant est également importée, ainsi que les fonctions d'authentification et de base de données de Firebase.
// Les variables d'état sont initialisées en utilisant les hooks useAuthState et useState. user contient l'objet utilisateur authentifié, loading est un booléen indiquant si la récupération de l'état d'authentification est en cours, error contient une erreur éventuelle, et name contiendra le nom de l'utilisateur. navigate est initialisé avec useNavigate pour pouvoir naviguer vers d'autres pages.
// fetchUserName est une fonction asynchrone qui utilise les fonctions Firestore de Firebase pour récupérer les données de l'utilisateur. La requête Firestore est effectuée en utilisant query, collection et where, qui récupèrent les données de l'utilisateur dont l'ID correspond à celui de l'utilisateur connecté. Les données récupérées sont stockées dans l'objet data, qui est utilisé pour mettre à jour la variable d'état name.
// En cas d'erreur, une alerte est affichée à l'utilisateur.
// Le hook useEffect est utilisé pour exécuter du code au moment où le composant est monté ou mise à jour. Dans ce cas, le code est exécuté à chaque fois que les variables d'état user et loading sont modifiées. Si l'état de chargement est vrai, la fonction est ignorée. Si l'utilisateur n'est pas connecté, la fonction navigate est utilisée pour rediriger l'utilisateur vers la page d'accueil.
//  Sinon, la fonction fetchUserName est appelée pour récupérer le nom de l'utilisateur.

// Enfin, le code HTML est rendu pour afficher le tableau de bord de l'utilisateur. Les éléments HTML sont enveloppés dans deux divs avec les classes `Goals` et `Goals__container`. Le nom de l'utilisateur et son adresse e-mail sont affichés dans des divs distincts, et un bouton de déconnexion est ajouté qui appelle la fonction `logout` lorsque l'utilisateur clique dessus.
// En résumé, ce composant React récupère l'état d'authentification de l'utilisateur en utilisant Firebase, récupère les données de l'utilisateur à partir de Firestore, affiche le nom et l'adresse e-mail de l'utilisateur dans un tableau de bord et permet à l'utilisateur de se déconnecter.
