import { Link } from "react-router-dom";
import "./Home.css";
import {logout } from "./firebase";
function Home() {
  return (
    <div className="home">
    

        <img src="/BackgrdHome.png" alt="Background" className="background-image" />


        <div className="text-container">
          <div className="one">
          <img className="logo2" src="logoPurpleGrey.png" alt="Logo" />
  
          <h1>Bienvenue sur Dataction !</h1>


<h2>L'application Data qui permets aux PME de suivre leurs chiffres et de mettre en place des actions</h2>
            {/* <h3>Comment augmenter son bénéfice en optimisant ses process sans y passer des heures</h3>
            <ul>
              <li>
                Votre PME manque de structure, vous perdez du temps dans les chiffres et manquez d’automatisation dans le traitement des données?
              </li>
              <li>
                Vous avez peur de passer à côté de pertes dans votre entreprise, de ne pas être rentable?
              </li>
              <li>Vous n’arrivez pas à éliminer le côté énervant de la gestion des suivis?</li>
            </ul> */}

          </div>

      
<div className="video-container">
  

          <div className="Two">

          {/* <h3>Qui sommes-nous ?</h3>
          <p>
            Chez Dataction, nous proposons à nos clients des solutions utiles et efficaces d'analyse de données totalement adaptées à leur domaine d'activité. Avec des clients faisant partie des leaders sur le marché du coaching français, notre entreprise a pour but de mettre à profit ses connaissances et son expérience afin d'en faire profiter ceux qui se rendent compte de la puissance que la data peut apporter à leur entreprise.
          </p> */}
{/* 
          <h3>En quoi la data peut aider votre PME ?</h3>
          <ul>
            <li>
              Un support essentiel pour la prise de décision : utilisez les insights obtenus à partir des données pour prendre des décisions plus éclairées et plus informées en matière de stratégie, de marketing et de finances.
            </li>
            <li>
              Optimisez vos opérations : identifiez les processus inefficaces grâce à la data, améliorez-les ce qui peut réduire les coûts et augmenter votre retour sur investissement.
            </li>
            <li>
              Améliorez votre relation client : utilisez l'analyse de données pour mieux comprendre les besoins et les attentes de vos clients, ce qui peut vous aider à améliorer la qualité de vos produits et de vos services.
            </li>
            <li>
              Découvrez de nouvelles opportunités de croissance : utilisez l'analyse de données pour identifier de nouveaux marchés, de nouveaux produits et de nouveaux segments de clientèle, ce qui peut vous aider à vous développer et à prospérer.
            </li>
          </ul> */}
          </div>

          <div className="home__buttons">
            <Link to="https://datactionapi.web.app/" className="home__button">
              S'inscrire
            </Link>
            <Link to="/Login" onClick={logout}  className="home__button">
              Se connecter
            </Link>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Home;
