
import React, { useEffect, useState } from "react";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import ls from 'localstorage-slim'; 
import { useAuthState } from "react-firebase-hooks/auth"; 
import  { auth,  db, logout } from "./firebase"
import { getFirestore } from "firebase/firestore";
import { query, getDocs, collection, where } from "firebase/firestore";
import "./ContactForm.css";
import { Grid } from "react-loader-spinner"; // Import the Oval spinner

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);


function RetractationForm({ userID }) {
  const [retractations, setRetractation] = useState([]);
  const [selectedRetractation, setSelectedRetractation] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [remboursementClient, setRemboursementClient] = useState(0);
  const [annulationPaiementsFuturs, setAnnulationPaiementsFuturs] = useState(false);
  const [recuperationCommissionPresta1, setRecuperationCommissionPresta1] = useState(0);
  const [recuperationCommissionPresta2, setRecuperationCommissionPresta2] = useState(0);
  const [recuperationCommissionPresta3, setRecuperationCommissionPresta3] = useState(0);
  const [annulationCommissionsFuturesPresta1, setAnnulationCommissionsFuturesPresta1] = useState(false);
  const [annulationCommissionsFuturesPresta2, setAnnulationCommissionsFuturesPresta2] = useState(false);
  const [annulationCommissionsFuturesPresta3, setAnnulationCommissionsFuturesPresta3] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [retractionSentSuccess, setRetractionSentSuccess] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //         `http://localhost:3008/api/Retractation/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       if (jsonData) {
  //         setRetractation(jsonData);
  //       }
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  const [error, setError] = useState(null);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/Retractation/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/Retractation/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                  }
                  return response.json();
                })
                .then((data) => {
                  setRetractation(data);
                  setLoadingData(false);
                  // console.log('Récupération des données Retractation avec succès',data);
                })
                .catch((error) => {
                  setError(error.message);
                  setLoadingData(false);
                });
            } else {
              console.error("Le token n'est pas présent dans Firestore.");
              setLoadingData(false);
            }
          } else {
            console.error("L'utilisateur n'existe pas dans Firestore.");
            setLoadingData(false);
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
          setLoadingData(false);
        });
    }
  }, [user]);

  const handleSelectRetractation = (retractation) => {
    setSelectedRetractation(retractation);
    setShowPopup(true);
  };

  const handleRemboursementClientChange = (event) => {
    setRemboursementClient(Number(event.target.value));
  };

  const handleAnnulationPaiementsFutursChange = (event) => {
    setAnnulationPaiementsFuturs(event.target.checked);
  };

  const handleRecuperationCommissionPresta1Change = (event) => {
    setRecuperationCommissionPresta1(event.target.value);
  };

  const handleRecuperationCommissionPresta2Change = (event) => {
    setRecuperationCommissionPresta2(event.target.value);
  };

  const handleRecuperationCommissionPresta3Change = (event) => {
    setRecuperationCommissionPresta3(event.target.value);
  };

  const handleAnnulationCommissionsFuturesPresta1Change = (event) => {
    setAnnulationCommissionsFuturesPresta1(event.target.checked);
  };

  const handleAnnulationCommissionsFuturesPresta2Change = (event) => {
    setAnnulationCommissionsFuturesPresta2(event.target.checked);
  };

  const handleAnnulationCommissionsFuturesPresta3Change = (event) => {
    setAnnulationCommissionsFuturesPresta3(event.target.checked);
  };

  const handleValidate = () => {
    if (selectedRetractation) {
      const validatedData = {
        ...selectedRetractation,
        RemboursementClient: remboursementClient,
        AnnulationPaiementsFuturs: annulationPaiementsFuturs,
        RecuperationCommissionPresta1: recuperationCommissionPresta1,
        RecuperationCommissionPresta2: recuperationCommissionPresta2,
        RecuperationCommissionPresta3: recuperationCommissionPresta3,
        AnnulationCommissionsFuturesPresta1: annulationCommissionsFuturesPresta1,
        AnnulationCommissionsFuturesPresta2: annulationCommissionsFuturesPresta2,
        AnnulationCommissionsFuturesPresta3: annulationCommissionsFuturesPresta3,
      };
      setShowSales(false);
      sendData(validatedData);
      handleRetractationValidation();
      setLoadingData(true);
      resetForm();
    } else {
      console.log("Aucune vente sélectionnée.");
    }

    setShowPopup(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    resetForm();
  };
  const sendData = async (data) => {
    // console.log("user connecté pour la fonction sendData :" ,user);
    try {
      if (!user) {
        console.error("L'utilisateur n'est pas connecté.");
        return;
      }
  
      const currentUserID = ls.get('userUid', { secret: encryptionKey });
      // const url = `http://localhost:8080/api/insertDataRetractation`;
      const url = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/insertDataRetractation`;
  
      // console.log("user : ", user);
  
      // Obtenez le jeton Firestore de l'utilisateur
      const doc = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

        // console.log("console : " ,  doc);
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // console.log("token" , storedToken);
        if (storedToken) {
          console.log("on envoie les données c'est partit !!")
          // Effectuez une requête POST vers votre API Express pour envoyer les données
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Authorization': `Bearer ${storedToken}`,
            },
            body: JSON.stringify([data]),
          });
 
          console.log("Données validées envoyées avec succès");
          setRetractionSentSuccess(true);
          setLoadingData(true);
          // Réinitialisez le message de succès après 3 secondes (3000 millisecondes)
          setTimeout(() => {
            setRetractionSentSuccess(false);
          }, 3000);
  
          if (!response.ok) {
            throw new Error("Erreur lors de l'envoi des données validées");
          }
  
          // Affichez un message de succès ou effectuez d'autres actions nécessaires
          console.log("Données validées envoyées avec succès");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données validées :", error);
      // Traitez l'erreur et affichez un message approprié à l'utilisateur
    }

  };

  const handleRetractationValidation = async (payment) => {
    try {
      // Logique pour envoyer les changements au serveur
      // console.log("Paiement validé :", payment);
      const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // Après la validation réussie, effectuez un nouvel appel à l'API
        const currentUserID = ls.get('userUid', { secret: encryptionKey });
        const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/Retractation/${currentUserID}`;
  
        // Utilisez une fonction asynchrone pour pouvoir utiliser await
        const fetchData = async () => {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Réponse réseau incorrecte');
            setLoadingData(false);
          }
  
          const data = await response.json();
          setRetractation(data);
          setLoadingData(false);
          console.log('Données mises à jour avec succès');
        };
  
        await fetchData(); // Utilisez "await" ici pour attendre la fin de fetchData
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la validation du paiement :", error);
    }
    // console.log("Paiement validé :", payment);
  };
  // const sendData = async (data) => {
  //   try {
  //     if (firebase.auth().currentUser) {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  //       const url = `http://localhost:3008/api/insertDataRetractation`;
  
  //       // Effectuez une requête POST vers votre API Express pour envoyer les données
  //       const response = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': process.env.REACT_APP_API_KEY,
  //         },
  //         body: JSON.stringify([data]),
  //       });
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de l'envoi des données validées");
  //       }
  
  //       // Affichez un message de succès ou effectuez d'autres actions nécessaires
  //       console.log("Données envoyées avec succès");
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de l'envoi des données :", error);
  //     // Traitez l'erreur et affichez un message approprié à l'utilisateur
  //   }
  // };
  const resetForm = () => {
    setRemboursementClient(0);
    setAnnulationPaiementsFuturs(false);
    setRecuperationCommissionPresta1(false);
    setRecuperationCommissionPresta2(false);
    setRecuperationCommissionPresta3(false);
    setAnnulationCommissionsFuturesPresta1(false);
    setAnnulationCommissionsFuturesPresta2(false);
    setAnnulationCommissionsFuturesPresta3(false);
  };

  const handleSelectSales = () => {
    setShowSales(!showSales);
    handleRetractationValidation()
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredRetractations = retractations.filter((retractation) =>
    retractation.Name.toLowerCase().includes(searchValue.toLowerCase())
  );

  // console.log("selected retractation",selectedRetractation);
  return (
    <div className="retractation-form">
      <button onClick={handleSelectSales} className="SpecialButton">
        {showSales ? "Annuler" : "Ajouter une rétractation"}
      </button>
      <br />
      <br />
      
{showSales? 
 <div className="search-box">
        {loadingData && (
 <div className="loading-container">
 <div className="loading-animation">
   {/* Add your loading animation here */}
   {/* Calcul et chargement de vos données de paiement prestataires */}
   <Grid
     color="#A6B1F7" // Set the color
     height={100} // Set the size
     width={50}
     secondaryColor="#7F8BCC"
     fill="none"
     strokeWidth={8}
   />
 </div>
</div>
      )
}

 <input
   type="text"
   value={searchValue}
   onChange={handleSearchInputChange}
   placeholder="Rechercher par nom"
   className="SearchInput"
 />
</div> : ""}
     

      <div className="retractation-list">
     
        {showSales && filteredRetractations.length > 0 ? ( <h1> Selectionne la vente correspondant à la retractation</h1> ):("")}
        {showSales && !loadingData && filteredRetractations.length > 0 ? (
          
          filteredRetractations.map((retractation, index) => (
            <div
              key={index}
              className="retractation-item"
              onClick={() => handleSelectRetractation(retractation)}
            >
              <br></br>
              {/* <span><label>
                Vente id  : 
              </label> {retractation.salesid} </span> */}
              <span><label>
                Nom du client : 
              </label> {retractation.Name} </span>
              <span><label>
                Produit/Service : 
              </label> {retractation.selectedProduct} </span>
              <span><label>
                Prix Facturé : 
              </label> {retractation.FinalPrice} </span>
              <span><label>
                Prix payé : 
              </label> {retractation.paidAmount?.toFixed(2)} </span>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {showPopup && (
        <div className="popup">

          <div className="popup-content">
            <div>
              <h2>Informations sur la vente sélectionnée :</h2>
              {/* <br />
              <span>{selectedRetractation.userid}</span>
              <br /> */}
              {/* <span>{selectedRetractation.salesid}</span> */}
              {/* <br />
              <label>
                Nom du Presta 1 : 
              </label>
              <br />
              <span>{selectedRetractation.providerName}</span>
              <br></br>
              <br />
              <label>
                Nom du Presta 2 : 
              </label>
              <br />
              <span>{selectedRetractation.providerName2}</span>
              <br></br>
              <br />
              <label>
                Nom du Presta 3 : 
              </label>
              <br />
              <span>{selectedRetractation.providerName2}</span>
              <br></br>

 */}
              <br />
              <label>
                Nom du client : 
              </label>
              <br />
              <span>{selectedRetractation.Name}</span>
              <br></br>
              <br />
              <label>
                Produit : 
              </label>
              <br></br>
              <span>{selectedRetractation.selectedProduct}</span>
              <br />
              <br></br>
              <label>
                Montant : 
              </label>
              <br></br>
              <span>{selectedRetractation.FinalPrice} €</span>
              <br />
              <br></br>
              <label>
                Montant déjà encaissé: 
              </label>
              <br></br>
              <span>{selectedRetractation.paidAmount?.toFixed(2)} €</span>
              <br />
  
              {/* <label>
                Bonus presta 3 : 
              </label>
              <br></br>
              <span>{selectedRetractation.bonus3}</span>
              <br /> */}
            </div>
            <br />
            <div>
              <label>
                Remboursement Client :
                <br></br>
                <input
                  type="number"
                  min={0}
                  value={remboursementClient}
                  onChange={handleRemboursementClientChange}
                />
              </label>
              <br></br>
              <label>
                Annulation des paiements et commissions non validés :
                <br></br>
                <input
                  type="checkbox"
                  checked={annulationPaiementsFuturs}
                  onChange={handleAnnulationPaiementsFutursChange}
                />
              </label>
            </div>
            <div>
              <label>
                Récupération Commission Presta 1 :
                <input
                  type="checkbox"
                  checked={recuperationCommissionPresta1}
                  onChange={handleRecuperationCommissionPresta1Change}
                />
                {/* <input
                  type="number"
                  min={0}
                  value={recuperationCommissionPresta1}
                  onChange={handleRecuperationCommissionPresta1Change}
                /> */}
              </label>
              <br></br>
              <label>
                Récupération Commission Presta 2 :
                {/* <input
                  type="number"
                  min={0}
                  value={recuperationCommissionPresta2}
                  onChange={handleRecuperationCommissionPresta2Change}
                /> */}
                 <input
                  type="checkbox"
                  checked={recuperationCommissionPresta2}
                  onChange={handleRecuperationCommissionPresta2Change}
                />
              </label>
              <br></br>
              <label>
                Récupération Commission Presta 3 :
                {/* <input
                  type="number"
                  min={0}
                  value={recuperationCommissionPresta3}
                  onChange={handleRecuperationCommissionPresta3Change}
                /> */}
                                 <input
                  type="checkbox"
                  checked={recuperationCommissionPresta3}
                  onChange={handleRecuperationCommissionPresta3Change}
                />
              </label>
            </div>
            <br />
            <div>
              <label>
                Annulation des Commissions Futures Presta 1 :
                <br></br>
                <input
                  type="checkbox"
                  checked={annulationCommissionsFuturesPresta1}
                  onChange={handleAnnulationCommissionsFuturesPresta1Change}
                />
              </label>
              <br></br>
              <label>
                Annulation des Commissions Futures Presta 2 :
                <br></br>
                <input
                  type="checkbox"
                  checked={annulationCommissionsFuturesPresta2}
                  onChange={handleAnnulationCommissionsFuturesPresta2Change}
                />
              </label>
              <br></br>
              <label>
                Annulation des Commissions Futures Presta 3 :
                <br></br>
                <input
                  type="checkbox"
                  checked={annulationCommissionsFuturesPresta3}
                  onChange={handleAnnulationCommissionsFuturesPresta3Change}
                />
              </label>
            </div>
            <br />
            <button onClick={handleValidate}>Valider</button>
            <br />
            <br />
            <button onClick={handlePopupClose}>Annuler</button>
            <br />
          </div>
        </div>
      )}
      {retractionSentSuccess && (
  <div className="success-message">
    Rétractation envoyée avec succès!
  </div>
)}
    </div>
  );
}

export default RetractationForm;
