import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import ls from 'localstorage-slim'; 

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
 
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "dataction-afa77.firebaseapp.com",
  projectId: "dataction-afa77",
  storageBucket: "dataction-afa77.appspot.com",
  messagingSenderId: "818052432156",
  appId: "1:818052432156:web:3c87df107f684c45fd3ad9",
  measurementId: "G-QLG4RX6J62",
};

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);
console.log(encryptionKey)

ls.config.encrypt = true; 

firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};



function storeUserIdInLocalStorage(user) {
  if (user) {
    ls.set('userUid', user.uid ,{ secret: encryptionKey });
    
  } else {
    localStorage.removeItem("userUid");
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
    storeUserIdInLocalStorage(null); // Appel de la fonction pour supprimer l'IID du local storage lors de la déconnexion
  
};
export {
  app,
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

// Ce code initialise Firebase en important les modules nécessaires pour les services Firebase Auth et Firestore. Ensuite, il crée une instance d'application Firebase avec la configuration FirebaseConfig fournie.
// Il exporte également des fonctions utiles pour interagir avec Firebase Auth et Firestore, notamment:

// auth : une instance de l'objet Auth de Firebase Auth, qui permet d'authentifier les utilisateurs et de gérer leur session.
// db : une instance de la base de données Firestore de Firebase, qui permet de stocker des données en temps réel.
// signInWithGoogle : une fonction qui permet à un utilisateur de s'authentifier avec son compte Google via une popup.
// logInWithEmailAndPassword : une fonction qui permet à un utilisateur de s'authentifier avec son adresse e-mail et son mot de passe.
// registerWithEmailAndPassword : une fonction qui permet à un nouvel utilisateur de s'inscrire en fournissant son nom, son adresse e-mail et son mot de passe.
// sendPasswordReset : une fonction qui permet d'envoyer un lien de réinitialisation de mot de passe à l'adresse e-mail fournie.
// logout : une fonction qui permet de déconnecter un utilisateur de son compte.
// Le code utilise également la syntaxe async/await pour effectuer des opérations asynchrones sur les services Firebase, notamment pour récupérer des données à partir de la base de données Firestore et pour effectuer des actions d'authentification telles que la connexion et l'inscription. Les erreurs sont gérées en utilisant try/catch pour capturer et afficher les erreurs dans une boîte de dialogue d'alerte.
