import React, { useState, useEffect } from "react";
import "./ContactForm.css";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import ls from 'localstorage-slim'; 
import { auth, logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);


const db = firebase.firestore();

function ContactForm() {
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productClass, setProductClass] = useState("");
  const [price, setPrice] = useState("");
  const [margin, setMargin] = useState("");
  const [productType, setProductType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [editingProduct, setEditingProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [showData, setShowData] = useState(false);

  const [user, loading, error] = useAuthState(auth);
   const navigate = useNavigate();
  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userUid = firebase.auth().currentUser?.uid;
        const productsRef = firebase.firestore()
          .collection("products")
          .doc(userUid)
          .collection("products_by_customer")
          .get()
          .then((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id, // Ajoutez l'ID du document Firestore au produit
              ...doc.data() // Récupère les données du produit
            }));
            setProducts(data);
          })
          .catch((error) => {
            console.log("Erreur lors de la récupération des données de la collection :", error);
          });
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  const refreshProducts = async () => {
    const userid = ls.get('userUid', { secret: encryptionKey });

    try {
      const userUid = firebase.auth().currentUser?.uid;
      const productsRef = firebase.firestore()
        .collection("products")
        .doc(userid)
        .collection("products_by_customer")
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setProducts(data);
        })
        .catch((error) => {
          console.log("Erreur lors de la récupération des données de la collection :", error);
        });
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userid = ls.get('userUid',{ secret: encryptionKey });
    const product = {
      productId,
      productName,
      productClass,
      price,
      margin,
      userId: userid,
      productType,
    };
    if (editingProduct) {
      db.collection("products")
        .doc(userid)
        .collection("products_by_customer")
        .doc(editingProduct.id)
        .update({
          ...product, // Mettre à jour les champs du produit
          id: editingProduct.id // Assurez-vous que l'ID est inclus lors de la mise à jour
        })
        .then(() => {
          console.log("Product updated successfully in Firestore!");
          setSuccessMessage("Modification du produit réussie avec succès");
          setTimeout(() => setSuccessMessage(false), 3000);
          setProductId("");
          setProductName("");
          setProductClass("");
          setPrice("");
          setProductType("");
          setMargin("");
          setEditingProduct(null);
          refreshProducts();
        })
        .catch((error) => {
          console.error("Error updating product in Firestore: ", error);
        });
    } else {
      db.collection("products")
        .doc(userid)
        .collection("products_by_customer")
        .add(product)
        .then(() => {
          console.log("Product added successfully to Firestore!");
          setSuccessMessage("Ajout du produit réussie avec succès");
          setTimeout(() => setSuccessMessage(false), 3000);
          setProductId("");
          setProductName("");
          setProductClass("");
          setPrice("");
          setProductType("");
          setMargin("");
          refreshProducts();
        })
        .catch((error) => {
          console.error("Error adding product to Firestore: ", error);
        });
    }
  };
  const toggleData = () => {
    setShowData(!showData);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setProductId(product.productId);
    setProductName(product.productName);
    setProductClass(product.productClass);
    setPrice(product.price);
    setProductType(product.productType);
    setMargin(product.margin);
  };

  const handleDeleteProduct = (productId) => {
    const userid = ls.get('userUid',{ secret: encryptionKey });
    db.collection("products")
      .doc(userid)
      .collection("products_by_customer")
      .doc(productId)
      .delete()
      .then(() => {
        console.log("Product deleted successfully from Firestore!");
        setSuccessMessage("Produit supprimé avec succès");
        setTimeout(() => setSuccessMessage(false), 3000);
        refreshProducts();
      })
      .catch((error) => {
        console.error("Error deleting product from Firestore: ", error);
      });
  };

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit}>
        <h1 className="form__title">
          {editingProduct
            ? "Modification d'un Produit/Service"
            : "Ajout d'un Produit/Service"}
        </h1>
        <div className="form__row">
          <label htmlFor="ProductType" className="form__label">
            Type (Produit / Service):
          </label>
          <select
            id="ProductType"
            type="text"
            defaultValue=""
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            required
            className="select_input"
          >
            <option value=""></option>
            <option value="Produit">Produit</option>
            <option value="Service">Service</option>
          </select>
        </div>
        <div className="form__row">
          <label htmlFor="productId" className="form__label">
            Référence Produit
          </label>
          <input
            type="text"
            name="productId"
            placeholder="Ex: 12345049"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            className="form__input"
          />
        </div>
        <div className="form__row">
          <label htmlFor="productName" className="form__label">
            Nom du produit
          </label>
          <input
            type="text"
            name="productName"
            value={productName}
            placeholder="Ex: Empire"
            onChange={(e) => setProductName(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="productClass" className="form__label">
            Classe de produit
          </label>
          <input
            type="text"
            name="productClass"
            placeholder="Ex: Accompagnement"
            value={productClass}
            onChange={(e) => setProductClass(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="price" className="form__label">
            Prix
          </label>
          <input
            type="text"
            name="price"
            value={price}
            placeholder="Ex: 10000"
            onChange={(e) => setPrice(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="margin" className="form__label">
            Marge
          </label>
          <input
            type="text"
            name="margin"
            value={margin}
            onChange={(e) => setMargin(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <br></br>
        <button type="submit" className="form__submit">
          {editingProduct
            ? "Enregistrer les modifications"
            : "Enregistrer le produit"}
        </button>
      </form>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div className="product-list">
        <button onClick={toggleData} className="form__submit">
          {showData ? "Masquer les produits" : "Afficher les produits"}
        </button>
        {showData && (
          <>
            <h2>Liste des produits</h2>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Référence </th>
                    <th>Nom </th>
                    <th>Classe </th>
                    <th>Prix </th>
                    <th>Marge </th>
                    <th>Type </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td>{product.productId}</td>
                      <td>{product.productName}</td>
                      <td>{product.productClass}</td>
                      <td>{product.price}</td>
                      <td>{product.margin}</td>
                      <td>{product.productType}</td>
                      <td>
                        <button
                          className="form__submit"
                          onClick={() => handleEditProduct(product)}
                        >
                          Modifier
                        </button>
                      </td>
                      <td>
                        <button
                          className="form__submit"
                          onClick={() => handleDeleteProduct(product.id)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default ContactForm;
