import React, { useState, useEffect } from "react";
import "./ContactForm.css";
import UpdateSales from "./UpdateSales";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ls from 'localstorage-slim'; 
import RetractationForm from './RetractationForm'; 

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);

// ls.get('userUid',{ secret: encryptionKey })

const getCurrentDate = () => {
  const currentDate = new Date();
  // console.log(currentDate);
  return currentDate;
};
function FormSales() {
  const [SalesId, setSalesId] = useState("");
  const [Number, setSalesNumber] = useState("");
  const [customers, setCustomers] = useState([]);
  const [Name, setName] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productsSold, setproductsSold] = useState([]);
  const [paymentDate, setPaymentDate] = useState("");
  const [numPayments, setNumPayments] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [clientOrigin, setClientOrigin] = useState("");
  const [providerName, setProviderName] = useState("");
  const [commission, setCommission] = useState("");
  const [bonus, setBonus] = useState("");
  const [providerName2, setProviderName2] = useState("");
  const [commission2, setCommission2] = useState("");
  const [bonus2, setBonus2] = useState("");
  const [providerName3, setProviderName3] = useState("");
  const [commission3, setCommission3] = useState("");
  const [bonus3, setBonus3] = useState("");
  const [userId, setuserId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [Tva, setTva] = useState("");
  // const [DataBQSales, setDataBQSales] = useState([]);

  const [loading, setLoading] = useState(true);
  const [discountWarning, setDiscountWarning] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [editingSales, setEditingSales] = useState(null);
  const [sales, setSales] = useState([]);
  const [showData, setShowData] = useState(false);
  const [showProviders, setShowProviders] = useState(false);
  const [paymentOptionProvider1, setPaymentOptionProvider1] = useState(
    "paiement en fonction du paiement client"
  );
  const [paymentOptionProvider2, setPaymentOptionProvider2] = useState(
    "paiement en fonction du paiement client"
  );
  const [paymentOptionProvider3, setPaymentOptionProvider3] = useState(
    "paiement en fonction du paiement client"
  );

  const handlePaymentOptionChangeProvider1 = (option) => {
    setPaymentOptionProvider1(option);
  };
  const handlePaymentOptionChangeProvider2 = (option) => {
    setPaymentOptionProvider2(option);
  };
  const handlePaymentOptionChangeProvider3 = (option) => {
    setPaymentOptionProvider3(option);
  };

  const handleDiscountChange = (e) => {
    const discount = e.target.value;
    setDiscountAmount(discount);

    // Check if the discount is greater than 70% and show the warning
    if (parseInt(discount, 10) > 70) {
      setDiscountWarning(true);
    } else {
      // Clear the warning if the discount is 70% or less
      setDiscountWarning(false);
    }
  };

  const handleProductSelection = () => {
    // Fetch products on button click
    const userUid = firebase.auth().currentUser?.uid;
    const productsRef = firebase.firestore()
      .collection("products")
      .doc(userUid)
      .collection("products_by_customer");

    productsRef
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setProducts(data);
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des données de la collection :",
          error
        );
      });
  };

  
  const [user, loadingAuthent, error] = useAuthState(auth);
  const [nameAuthent, setNameAuthent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]);


  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setNameAuthent(data.nameAuthent);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    const userUid = firebase.auth().currentUser?.uid;
    const unsubscribe = firebase.firestore().collection("sales").onSnapshot((snapshot) => {
      const sales = [];
      snapshot.forEach((doc) => {
        sales.push({ id: doc.id, ...doc.data() });
      });
      setSales(sales);
    });


    const productsRef = firebase.firestore()
      .collection("products")
      .doc(userUid)
      .collection("products_by_customer");

    productsRef
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        // console.log(data);
        setProducts(data);
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des données de la collection :",
          error
        );
      });

    // Récupère les clients
    const customersRef = firebase.firestore()
      .collection("customers")
      .doc(userUid)
      .collection("customersInfos");

    customersRef
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        // console.log(data);
        setCustomers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "Erreur lors de la récupération des données de la collection :",
          error
        );
      });

    return unsubscribe;
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userUid = ls.get('userUid',{ secret: encryptionKey });
    const sale = {
      SalesId,
      Number,
      customers,
      Tva,
      Name,
      selectedProduct,
      productsSold,
      paymentDate,
      numPayments,
      downPayment,
      totalPrice,
      discountAmount,
      startDate,
      clientOrigin,
      providerName,
      commission,
      bonus,
      paymentOptionProvider1,
      providerName2,
      commission2,
      bonus2,
      paymentOptionProvider2,
      providerName3,
      commission3,
      bonus3,
      paymentOptionProvider3,
      phone,
      email,
      userUid,
    };
  
 {
      firebase.firestore().collection("sales")
        .add(sale)
        .then(() => {
          console.log("Sale added successfully to Firestore!");
          setSuccessMessage("Ajout de la vente réussie avec succès");
          setTimeout(() => setSuccessMessage(false), 3000);
          setSalesId("");
          setSalesNumber("");
          setTva("");
          setName("");
          setproductsSold("Selectionne ton produit / service");
          setSelectedProduct("Selectionne ton produit / service");
          setPaymentDate("");
          setNumPayments("");
          setDownPayment("");
          setTotalPrice("");
          setDiscountAmount("");
          setStartDate("");
          setClientOrigin("");
          setProviderName("");
          setCommission("");
          setBonus("");
          setPaymentOptionProvider1("");
          setProviderName2("");
          setCommission2("");
          setBonus2("");
          setPaymentOptionProvider2("");
          setProviderName3("");
          setCommission3("");
          setBonus3("");
          setPaymentOptionProvider3("");
          setPhone("");
          setEmail("");
        })
        .catch((error) => {
          console.error("Error adding sale to Firestore: ", error);
        });
    }
  };
  const toggleData = () => {
    setShowData(!showData);
  };
  const toggleProviders = () => {
    setShowProviders(!showProviders);
  };

  const handleEditSale = (sale) => {
    setEditingSales(sale);
    setSalesId(sale.SalesId);
    setSalesNumber(sale.Number);
    setTva(sale.Tva);
    setName(sale.Name);
    setproductsSold(sale.productsSold);
    setPaymentDate(sale.paymentDate);
    setNumPayments(sale.numPayments);
    setDownPayment(sale.downPayment);
    setTotalPrice(sale.totalPrice);
    setDiscountAmount(sale.discountAmount);
    setStartDate(sale.startDate);
    setClientOrigin(sale.clientOrigin);
    setProviderName(sale.providerName);
    setCommission(sale.commission);
    setBonus(sale.bonus);
    setPaymentOptionProvider1(sale.paymentOptionProvider1);
    setProviderName2(sale.providerName2);
    setCommission2(sale.commission2);
    setBonus2(sale.bonus2);
    setPaymentOptionProvider2(sale.paymentOptionProvider2);
    setProviderName3(sale.providerName3);
    setCommission3(sale.commission3);
    setBonus3(sale.bonus3);
    setPaymentOptionProvider3(sale.paymentOptionProvider3);
    setPhone(sale.phone);
    setEmail(sale.email);
    setuserId(ls.get('userUid',{ secret: encryptionKey }));

    // setMargin(sale.margin);
  };


  return (
    <div className="form__container">
      <form onSubmit={handleSubmit}>
        <h1 className="form__title">
          {editingSales ? "Modification d'une vente" : "Ajout d'une vente"}
        </h1>
        <div className="form__row">
          <label htmlFor="number" className="form__label">
            Client ( Nom Prénom ) :
          </label>
          <input
            type="text"
            id="customerid"
            value={Name}
            placeholder="Ex: Dataction"
            onChange={(e) => setName(e.target.value)}
            className="form__input"
          />
        </div>
        {/* <div className="form__row">
          <label htmlFor="phone" className="form__label">
            Numéro de téléphone :
          </label>
          <input
            className="form__input"
            type="tel"
            id="phone"
            placeholder="Ex: 0793840498"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div> */}
        <div className="form__row">
          <label htmlFor="email" className="form__label">
            Adresse Email :
          </label>
          <input
            className="form__input"
            type="email"
            id="email"
            placeholder="Ex: mariecadieuxpro@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
          {/* <label htmlFor="customer" className="form__label">
            Client :
          </label>
          <select
            name="select"
            onChange={(e) => setName(e.target.value)}
            className="select_input"
            required
          >
            <option key="" value="">
              Selectionne ton client
            </option>
            {customers.map((customer) => (
              <option
                key={customer.id}
                value={customer.firstName + " " + customer.lastName}
              >
                {customer.firstName} {customer.lastName}
              </option>
            ))}
          </select>
        </div> */}
        <div className="form__row">
          <label htmlFor="product" className="form__label">
            Produit / Service :
          </label>
          <select
            id="product"
            onChange={(e) => setSelectedProduct(e.target.value)}
            className="select_input"
            required
            onClick={handleProductSelection}
          >
            <option key="" value="">
              Selectionne ton produit / service
            </option>
            {products.map((product) => (
              <option key={product.id} value={product.productName}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
        <div className="form__row">
          <label htmlFor="number" className="form__label">
            Nombre :
          </label>
          <input
            type="number"
            id="Number"
            maxLength="2"
            defaultValue={1}
            min={1}
            placeholder="Ex: 1"
            value={Number}
            onChange={(e) => setSalesNumber(e.target.value)}
            className="form__input"
          />
        </div>

        <div className="form__row">
          <label htmlFor="payment-date" className="form__label">
            Date de 1er paiement :
          </label>
          <input
            type="date"
            id="payment-date"
            placeholder="Ex: 05/01/2024"
            required
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            className="form__input"
          />
        </div>

        <div className="form__row">
          <label htmlFor="num-payments" className="form__label">
            Nombre de paiements :
          </label>
          <input
            type="number"
            min={1}
            max={12}
            placeholder="Ex: 3"
            maxLength="2"
            defaultValue={1}
            id="num-payments"
            value={numPayments}
            onChange={(e) => setNumPayments(e.target.value)}
            className="form__input"
          />
        </div>

        <div className="form__row">
          <label htmlFor="down-payment" className="form__label">
            Accompte (en €):
          </label>
          <input
            type="number"
            min={0}
            maxLength="4"
            id="down-payment"
            placeholder="Ex: 1000"
            value={downPayment}
            onChange={(e) => setDownPayment(e.target.value)}
            className="form__input"
            step="0.01"
          />
        </div>

        <div className="form__row">
          <label htmlFor="total-price" className="form__label">
            Prix total (en €) :
          </label>
          <input
            type="number"
            min={0}
            required
            maxLength="4"
            placeholder="Ex: 10000"
            id="total-price"
            value={totalPrice}
            onChange={(e) => setTotalPrice(e.target.value)}
            className="form__input"
            step="0.01"
          />
        </div>
        <div className="form__row">
          <label htmlFor="number" className="form__label">
            dont TVA (en %) :
          </label>
          <input
            type="number"
            id="Tva"
            maxLength="4"
            defaultValue={1}
            placeholder="Ex: 10"
            min={0}
            max={100}
            value={Tva}
            onChange={(e) => setTva(e.target.value)}
            className="form__input"
          />
        </div>
        <div className="form__row">
          <label htmlFor="discount-amount" className="form__label">
            Application d'une remise (en %) :
          </label>
          <input
            type="number"
            maxLength="4"
            min={0}
            max={100}
            placeholder="Ex: 10"
            id="discount-amount"
            value={discountAmount}
            onChange={handleDiscountChange}
            className="form__input"
            step="0.01"
          />
        </div>
        {discountWarning && (
          <p style={{ color: 'red' }}>
            Attention, vérifie bien la remise, elle est très élevée
          </p>
        )}

        <div className="form__row">
          <label htmlFor="start-date" className="form__label">
            Date de la vente :
          </label>
          <input
            type="date"
            id="start-date"
            placeholder="Ex: 05/01/2024"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form__input"
            max={getCurrentDate().toISOString().split('T')[0]} 
          />
        </div>

        <div className="form__row">
          <label htmlFor="client-origin" className="form__label">
            Provenance du client :
          </label>
          <input
            type="text"
            placeholder="Ex: Prospection"
            id="client-origin"
            value={clientOrigin}
            onChange={(e) => setClientOrigin(e.target.value)}
            className="form__input"
          />
        </div>
        <br></br>
       
        <button
          type="button"
          onClick={toggleProviders}
          className="SpecialButton"
        >
          {showProviders
            ? "Masquer les prestataires"
            : "Ajouter des prestataires"}
        </button>
        <br></br>
        {showProviders && (
          <div>
            <p> Prestataire 1 </p>
            <button
              type="button"
              value={paymentOptionProvider1}
              onClick={() =>
                handlePaymentOptionChangeProvider1(
                  "paiement en fonction du paiement client"
                )
              }
              className={`payment-option-btn ${
                paymentOptionProvider1 ===
                "paiement en fonction du paiement client"
                  ? "active"
                  : ""
              }`}
            >
              Paiement en fonction du paiement client
            </button>

            <button
              type="button"
              onClick={() =>
                handlePaymentOptionChangeProvider1("paiement lors de la vente")
              }
              className={`payment-option-btn ${
                paymentOptionProvider1 === "paiement lors de la vente"
                  ? "active"
                  : ""
              }`}
            >
              Paiement lors de la vente
            </button>
            <br></br>
            <div className="form__row">
              <label htmlFor="service-provider-name" className="form__label">
                Nom du prestataire:
              </label>
              <input
                type="text"
                id="service-provider-name"
                placeholder="Ex: Elisabeth"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
                className="form__input"
              />
            </div>
            <div className="form__row">
              <label htmlFor="commission" className="form__label">
                Commission (%):
              </label>
              <input
                type="number"
                min={0}
                id="commission"
                value={commission}
                max={100}
                placeholder="Ex: 20"
                onChange={(e) => setCommission(e.target.value)}
                className="form__input"
                step="0.01"
              />
            </div>
            <div className="form__row">
              <label htmlFor="bonus" className="form__label">
                Bonus (€):
              </label>
              <input
                type="number"
                min={0}
                id="bonus"
                placeholder="Ex: 100"
                value={bonus}
                onChange={(e) => setBonus(e.target.value)}
                className="form__input"
                step="0.01"
              />
            </div>

            <p> Prestataire 2 </p>
            <button
              type="button"
              onClick={() =>
                handlePaymentOptionChangeProvider2(
                  "paiement en fonction du paiement client"
                )
              }
              className={`payment-option-btn ${
                paymentOptionProvider2 ===
                "paiement en fonction du paiement client"
                  ? "active"
                  : ""
              }`}
            >
              Paiement en fonction du paiement client
            </button>

            <button
              type="button"
              onClick={() =>
                handlePaymentOptionChangeProvider2("paiement lors de la vente")
              }
              className={`payment-option-btn ${
                paymentOptionProvider2 === "paiement lors de la vente"
                  ? "active"
                  : ""
              }`}
            >
              Paiement lors de la vente
            </button>
            <br></br>
            <div>
              <div className="form__row">
                <label htmlFor="service-provider-name2" className="form__label">
                  Nom du prestataire:
                </label>
                <input
                  type="text"
                  id="service-provider-name2"
                  placeholder="Ex : Antoine"
                  value={providerName2}
                  onChange={(e) => setProviderName2(e.target.value)}
                  className="form__input"
                />
              </div>
              <div className="form__row">
                <label htmlFor="commission2" className="form__label">
                  Commission (%):
                </label>
                <input
                  type="number"
                  min={0}
                  max={100}
                  id="commission2"
                  placeholder="Ex : 10"
                  value={commission2}
                  onChange={(e) => setCommission2(e.target.value)}
                  className="form__input"
                  step="0.01"
                />
              </div>
              <div className="form__row">
                <label htmlFor="bonus2" className="form__label">
                  Bonus (€):
                </label>
                <input
                  type="number"
                  min={0}
                  id="bonus2"
                  value={bonus2}
                  placeholder="Ex : 0"
                  onChange={(e) => setBonus2(e.target.value)}
                  className="form__input"
                  step="0.01"
                />
              </div>
              <p> Prestataire 3 </p>
              <button
                type="button"
                onClick={() =>
                  handlePaymentOptionChangeProvider3(
                    "paiement en fonction du paiement client"
                  )
                }
                className={`payment-option-btn ${
                  paymentOptionProvider3 ===
                  "paiement en fonction du paiement client"
                    ? "active"
                    : ""
                }`}
              >
                Paiement en fonction du paiement client
              </button>

              <button
                type="button"
                onClick={() =>
                  handlePaymentOptionChangeProvider3(
                    "paiement lors de la vente"
                  )
                }
                className={`payment-option-btn ${
                  paymentOptionProvider3 === "paiement lors de la vente"
                    ? "active"
                    : ""
                }`}
              >
                Paiement lors de la vente
              </button>
              <br></br>
              <div>
                <div className="form__row">
                  <label
                    htmlFor="service-provider-name3"
                    className="form__label"
                  >
                    Nom du prestataire:
                  </label>
                  <input
                    type="text"
                    id="service-provider-name3"
                    placeholder="Ex : PrestaService"
                    value={providerName3}
                    onChange={(e) => setProviderName3(e.target.value)}
                    className="form__input"
                  />
                </div>
                <div className="form__row">
                  <label htmlFor="commission3" className="form__label">
                    Commission (%):
                  </label>
                  <input
                    type="number"
                    min={0}
                    max={100}
                    placeholder="Ex : 15"
                    id="commission3"
                    value={commission3}
                    onChange={(e) => setCommission3(e.target.value)}
                    className="form__input"
                    step="0.01"
                  />
                </div>
                <div className="form__row">
                  <label htmlFor="bonus3" className="form__label">
                    Bonus (€):
                  </label>
                  <input
                    type="number"
                    min={0}
                    id="bonus3"
                    value={bonus3}
                    placeholder="Ex : 0"
                    onChange={(e) => setBonus3(e.target.value)}
                    className="form__input"
                    step="0.01"
                  />
                </div>
              </div>
            </div>
            <p>* les types de paiements sont valables uniquement pour les commissions, les bonus sont automatiquement disponibles dans le suivi de paiement dès la validation de la vente</p>
          </div>
        )}
        <button type="submit" className="form__submit">
          {editingSales
            ? "Enregistrer les  modifications"
            : "Enregistrer la vente"}
        </button>
      </form>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div className="sale-list">
        <button type="button" onClick={toggleData} className="form__submit">
          {showData ? "Masquer les ventes" : "Afficher les ventes"}
        </button>
        {showData && (
          <UpdateSales />
        )}
      </div>
      <br></br>
      <RetractationForm />
    </div>
  );
}
export default FormSales;