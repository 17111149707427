import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Prevision.css";
import ls from 'localstorage-slim'; 

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "./firebase";
import { getFirestore } from "firebase/firestore";
import { Oval } from "react-loader-spinner"; // Import the Oval spinner
import { Grid } from "react-loader-spinner"; // Import the Oval spinner
const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);


const db = firebase.firestore();

function PrevisionsCharges() {


  const [user, loading, error] = useAuthState(auth);
  
  const navigate = useNavigate(); 

  const [inputValue, setInputValue] = useState({
    Monthly: "",
    Yearly: "",
    Weekly: "",
  });
  //   const [realValue, setRealValue] = useState();
  const [errorFirebaseCharge, setError] = useState(null);
  const [errorFirebasePayment, setErrorFirebasePayment] = useState(null);
  const [dataCharge, setDataCharge] = useState(null);
  const [dataPayment, setDataPayment] = useState(null);
  const [loadingDataCharge, setLoadingDataCharge] = useState(true);
  const [loadingDataPayment, setLoadingDataPayment] = useState(true);
  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]); 


  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/charges/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/charges/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    setLoadingDataCharge(false);
                    throw new Error('Réponse réseau incorrecte');
                  }
                  return response.json();
                })
                .then((data) => {
                  setLoadingDataCharge(false);
                  setDataCharge(data[0]);
                  console.log('Récupération des données de charge avec succès');
                })
                .catch((errorFirebaseCharge) => {
                  setLoadingDataCharge(false);
                  setError(errorFirebaseCharge.message);
                });
            } else {
              setLoadingDataCharge(false);
              console.errorFirebaseCharge("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataCharge(false);
            console.errorFirebaseCharge("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((errorFirebaseCharge) => {
          setLoadingDataCharge(false);
          console.errorFirebaseCharge("Erreur lors de la récupération du token depuis Firestore:", errorFirebaseCharge);
        });
    }
  }, [user]);
  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/payment/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/payment/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    setLoadingDataPayment(false);
                    throw new Error('Réponse réseau incorrecte');
                  }
                  return response.json();
                })
                .then((data) => {
                  setLoadingDataPayment(false);
                  setDataPayment(data[0]);
                  // console.log("données de vente", data[0]);
                  console.log('Récupération des données ventes avec succès');
                })
                .catch((errorFirebasePayment) => {
                  setLoadingDataPayment(false);
                  setErrorFirebasePayment(errorFirebasePayment.message);
                });
            } else {
              setLoadingDataPayment(false);
              console.errorFirebasePayment("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataPayment(false);
            console.errorFirebasePayment("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((errorFirebasePayment) => {
          setLoadingDataPayment(false);
          console.errorFirebasePayment("Erreur lors de la récupération du token depuis Firestore:", errorFirebasePayment);
        });
    }
  }, [user]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (firebase.auth().currentUser) {
  //         const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //         if (!currentUserID) {
  //           throw new Error("ID utilisateur non valide");
  //         }
  
  //         const response = await fetch(
  //           `http://localhost:3008/api/charges/${currentUserID}`,
  //           {
  //             headers: {
  //               'x-api-key': process.env.REACT_APP_API_KEY,
  //             },
  //           }
  //         );
  
  //         if (!response.ok) {
  //           throw new Error("Erreur lors de la récupération des données");
  //         }
  
  //         const jsonData = await response.json();
  
  //         // Filtrer les données par userID
  //         const filteredData = jsonData.filter(
  //           (AllCharges) => AllCharges.userId === currentUserID
  //         );
  
  //         setDataCharge(filteredData[0]);
  //         console.log(filteredData);
  //       }
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();


  //     const fetchDataPayment = async () => {
  //       try {
  //         if (firebase.auth().currentUser) {
  //           const currentUserID = ls.get('userUid', { secret: encryptionKey });
    
  //           if (!currentUserID) {
  //             throw new Error("ID utilisateur non valide");
  //           }
    
  //           const response = await fetch(
  //             `http://localhost:3008/api/payment/${currentUserID}`,
  //             {
  //               headers: {
  //                 'x-api-key': process.env.REACT_APP_API_KEY,
  //               },
  //             }
  //           );
    
  //           if (!response.ok) {
  //             throw new Error("Erreur lors de la récupération des données");
  //           }
    
  //           const jsonData = await response.json();
    
  //           // Filtrer les données par userID
  //           const filteredDataPayment = jsonData.filter(
  //             (AllPayment) => AllPayment.userId === currentUserID
  //           );
    
  //           setDataPayment(filteredDataPayment[0]);
  //           console.log(filteredDataPayment);
  //         }
  //       } catch (error) {
  //         console.error("Erreur lors de la récupération des données :", error.message);
  //         // Gérer l'erreur de manière appropriée pour l'utilisateur
  //       }
  //     };
    
  //     fetchDataPayment();
  //   }, []);

  //   const fetchDataPayment = async () => {
  //     try {
  //       if (firebase.auth().currentUser) {
  //         const currentUserID = ls.get('userUid',{ secret: encryptionKey });

  //         const response = await fetch(
  //           `http://localhost:3008/api/payment/${currentUserID}`
  //         );
  //         const jsonData = await response.json();

  //         // Filtrer les données par userID
  //         const filteredDataPayment = jsonData.filter(
  //           (AllPayment) => AllPayment.userId === currentUserID
  //         );
  //         console.log(filteredDataPayment);
  //         setDataPayment(filteredDataPayment[0]);

  //         console.log(dataPayment);
  //       }
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error);
  //     }
  //   };

  //   fetchDataPayment();
  // }, []);

  return (
    <div>
 {!dataCharge && loadingDataCharge==false &&(
            <span>Ajoute des données pour pouvoir analyser tes charges</span>
          )}
 {!dataCharge && loadingDataCharge==true &&(
             <div className="loading-container">
             <div className="loading-animation">
               {/* Add your loading animation here */}
               {/* Calcul et chargement de vos données de paiement prestataires
               <Audio
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               /> */}
                <Grid
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               />
             
             </div>
           </div>
          )}
          <br></br>
          {dataCharge && (
      <div className="containerPrevision" >
        <span className="indicator-item" title="Info-bulle pour Charges depuis le début de l'année jusqu'au mois en cours :">
          Charges depuis le début de l'année jusqu'au mois en cours : <br />
          <br></br>
          <span className="indicator-value "> {dataCharge?.ChargesYTM}€</span>
        </span>
        <br></br>
        <span className="indicator-item" title="Info-bulle pour Charges sur le mois en cours :">
          Charges à régler sur le mois en cours : <br />
          <br></br>
          <span className="indicator-value ">
            {dataCharge?.ChargesThisMonth}€
          </span>
        </span>
        <br></br>
        <span className="indicator-item" title="Info-bulle pour Charges à prévoir pour le reste de l'année :">
          Charges à prévoir pour le reste de l'année : <br />
          <br></br>
          <span className="indicator-value ">
            {dataCharge?.ChargesUntilNY}€
          </span>
        </span>
      </div>
          )}
              <br></br>
              {!dataPayment && loadingDataPayment==false &&(
            <span>Ajoute des données pour pouvoir analyser tes charges</span>
          )}
 {!dataPayment && loadingDataPayment==true &&(
             <div className="loading-container">
             <div className="loading-animation">
               {/* Add your loading animation here */}
               {/* Calcul et chargement de vos données de paiement prestataires */}
               <Grid
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               />
             </div>
           </div>
          )}

      <br></br>
      {dataPayment && (
      <div className="containerPrevision">
        <span className="indicator-item" title="Info-bulle pour Paiements validés depuis le début de l'année :">
          Paiements validés depuis le début de l'année : <br />
          <br></br>
          <span className="indicator-value ">
            {dataPayment?.yearAmountsValidated.toFixed(2)}€
          </span>
        </span>
        <br></br>
        <span className="indicator-item" title="Info-bulle pour Paiements en attente sur le mois en cours :">
          Paiements en attente sur le mois en cours : <br />
          <br></br>
          <span className="indicator-value ">
            {dataPayment?.monthAmountsWaiting.toFixed(2)}€
          </span>
        </span>
        <br></br>
        <span className="indicator-item" title="Info-bulle pour Paiements en attente après le mois en cours pour le reste de l'année :">
          Paiements en attente après le mois en cours pour le reste de l'année :{" "}
          <br />
          <br></br>
          <span className="indicator-value ">
            {dataPayment?.AmountsWaiting.toFixed(2)}€
          </span>
        </span>
      </div>
            )}
      <p className="Infos">
        {" "}
        Remarque : le total de ces chiffres peut être différent du CA total généré si les
        prévisions de paiements s'étendent au-delà de l'année en cours
      </p>

    </div>
  );
}

export default PrevisionsCharges;
