import {
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
} from "../actions/clientActions";

const initialState = {
  clients: [],
};

function clientReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.client],
      };
    case EDIT_CLIENT:
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === action.id ? action.client : client
        ),
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client.id !== action.id),
      };
    default:
      return state;
  }
}

export default clientReducer;

// Ces lignes de code définissent le reducer qui gère l'état lié aux clients.
//  Le state initial contient une propriété clients initialisée à un tableau vide.
// Le reducer écoute les actions de type ADD_CLIENT, EDIT_CLIENT et DELETE_CLIENT.
// Si une action de type ADD_CLIENT est reçue, l'état est mis à jour en ajoutant le client à la propriété clients.
// Si une action de type EDIT_CLIENT est reçue, l'état est mis à jour en remplaçant le client ayant l'id correspondant par le nouveau client.
//  Si une action de type DELETE_CLIENT est reçue, l'état est mis à jour en supprimant le client ayant l'id correspondant.
//  Si aucune action correspondante n'est reçue, le reducer renvoie simplement l'état actuel.
