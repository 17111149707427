
  // import React, { useState, useEffect } from "react";
  // import firebase from "firebase/compat/app";
  // import "firebase/compat/firestore";
  // import "./Prevision.css";
  // import ls from "localstorage-slim";
  // import { useAuthState } from "react-firebase-hooks/auth";
  // import { useNavigate } from "react-router-dom";
  // import { auth } from "./firebase";
  // import { Bar } from "react-chartjs-2";
  
  // const encryptionKey = parseInt(process.env.REACT_APP_TEST, 10);
  
  // const db = firebase.firestore();
  
  // function PrevisionsCharges() {
  //   const [user, loading] = useAuthState(auth);
  //   const navigate = useNavigate();
  
  //   const [errorFirebasePayment, setErrorFirebasePayment] = useState(null);
  //   const [dataPayment, setDataPayment] = useState(null);
  //   const [loadingDataPayment, setLoadingDataPayment] = useState(true);
  
  //   useEffect(() => {
  //     const currentUserID = ls.get("userUid", { secret: encryptionKey });
  //     if (loading) return;
  //     if (!currentUserID) {
  //       navigate("/Login");
  //     }
  //   }, [user, loading]);
  
  //   useEffect(() => {
  //     if (user) {
  //       firebase
  //         .firestore()
  //         .collection("users")
  //         .doc(user.uid)
  //         .get()
  //         .then((doc) => {
  //           if (doc.exists) {
  //             const userData = doc.data();
  //             const storedToken = userData.token;
  
  //             if (storedToken) {
  //               const currentUserID = ls.get("userUid", { secret: encryptionKey });
  //               const apiUrl = `http://localhost:3008/api/paymentMonth/${currentUserID}`;
  
  //               fetch(apiUrl, {
  //                 headers: {
  //                   Authorization: `Bearer ${storedToken}`,
  //                 },
  //               })
  //                 .then((response) => {
  //                   if (!response.ok) {
  //                     setLoadingDataPayment(false);
  //                     throw new Error("Réponse réseau incorrecte");
  //                   }
  //                   return response.json();
  //                 })
  //                 .then((data) => {
  //                   setLoadingDataPayment(false);
  //                   setDataPayment(data);
  //                 })
  //                 .catch((error) => {
  //                   setLoadingDataPayment(false);
  //                   setErrorFirebasePayment(error.message);
  //                 });
  //             } else {
  //               setLoadingDataPayment(false);
  //               setErrorFirebasePayment("Le token n'est pas présent dans Firestore.");
  //             }
  //           } else {
  //             setLoadingDataPayment(false);
  //             setErrorFirebasePayment("L'utilisateur n'existe pas dans Firestore.");
  //           }
  //         })
  //         .catch((error) => {
  //           setLoadingDataPayment(false);
  //           setErrorFirebasePayment(
  //             "Erreur lors de la récupération du token depuis Firestore:",
  //             error
  //           );
  //         });
  //     }
  //   }, [user]);
  
  //   return (
  //     <div>
  //       {dataPayment && (
  //         <div className="containerPrevision">
  //           <Bar
  //             data={{
  //               labels: dataPayment.map(item => item.Month.value),
  //               datasets: [
  //                 {
  //                   label: "Montant",
  //                   data: dataPayment.map(item => parseFloat(item.AmountsWaiting)),
  //                   borderColor: "rgba(75, 192, 192, 1)",
  //                   fill: false,
  //                   borderWidth: 1,
  //                 },
  //                 {
  //                   label: "Montant Mois",
  //                   data: dataPayment.map(item => parseFloat(item.monthAmountsWaiting)),
  //                   borderColor: "rgba(190, 192, 192, 31)",
  //                   fill: false,
  //                   borderWidth: 2,
  //                 },
  //                 {
  //                   label: "Montant Année",
  //                   data: dataPayment.map(item => parseFloat(item.yearAmountsValidated)),
  //                   borderColor: "rgba(190, 392, 192, 31)",
  //                   fill: false,
  //                   borderWidth: 2,
  //                 },
  //                 {
  //                   label: "Montant Charge",
  //                   data: dataPayment.map(item => parseFloat(item.ChargesThisMonth)),
  //                   Color: "rgba(190, 292, 192, 31)",
  //                   fill: false,
  //                   borderWidth: 2,
  //                 },
  //               ],
  //             }}
  //           />
  //         </div>
  //       )}

  //     </div>
  //   );
  // }
  
  // export default PrevisionsCharges;
  import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Prevision.css";
import ls from "localstorage-slim";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { Bar } from "react-chartjs-2";
import { Grid } from "react-loader-spinner"; // Import the Oval spinner

const encryptionKey = parseInt(process.env.REACT_APP_TEST, 10);

const db = firebase.firestore();

function PrevisionMois() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [errorFirebasePayment, setErrorFirebasePayment] = useState(null);
  const [dataPayment, setDataPayment] = useState(null);
  const [loadingDataPayment, setLoadingDataPayment] = useState(true);

  useEffect(() => {
    const currentUserID = ls.get("userUid", { secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate("/Login");
    }
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get("userUid", { secret: encryptionKey });
              // const apiUrl = `http://localhost:3008/api/paymentMonth/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/paymentMonth/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    setLoadingDataPayment(false);
                    throw new Error("Réponse réseau incorrecte");
                  }
                  return response.json();
                })
                .then((data) => {
                  // Tri des données par date (Month.value)
                  data.sort((a, b) => (a.Month.value > b.Month.value ? 1 : -1));
                  setLoadingDataPayment(false);
                  setDataPayment(data);
                })
                .catch((error) => {
                  setLoadingDataPayment(false);
                  setErrorFirebasePayment(error.message);
                });
            } else {
              setLoadingDataPayment(false);
              setErrorFirebasePayment("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataPayment(false);
            setErrorFirebasePayment("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((error) => {
          setLoadingDataPayment(false);
          setErrorFirebasePayment(
            "Erreur lors de la récupération du token depuis Firestore:",
            error
          );
        });
    }
  }, [user]);

  return (
    <div>
       {!dataPayment && loadingDataPayment == false &&(
   <span>Ajoute des données pour pouvoir voir tes previsions </span>
       )}
      {!dataPayment && loadingDataPayment==true &&(
             <div className="loading-container">
             <div className="loading-animation">
               {/* Add your loading animation here */}
               {/* Calcul et chargement de vos données de paiement prestataires
               <Audio
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               /> */}
                <Grid
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               />
             
             </div>
           </div>
          )}
      {dataPayment && (
        <div className="containerPrevisionGlobal">
          <br></br>
          <Bar
            data={{
              labels: dataPayment.map((item) => item.Month.value),
              datasets: [
                {
                  label: "Montant des revenus à valider",
                  data: dataPayment.map((item) => parseFloat(item.AmountsWaiting)),
                  backgroundColor:'rgba(153, 102, 255, 0.2)',
                  borderColor:'rgb(153, 102, 255)',
                  fill: false,
                  borderWidth: 1,
                },
                {
                  label: "Montant des revenus à valider ce mois",
                  data: dataPayment.map((item) => parseFloat(item.monthAmountsWaiting)),
                  backgroundColor:'rgba(153, 102, 255, 0.2)',
                  borderColor:'rgb(153, 102, 255)',
                  fill: false,
                  borderWidth: 2,
                },
                {
                  label: "Montant déjà validé ce mois-ci",
                  data: dataPayment.map((item) => parseFloat(item.yearAmountsValidated)),
                  backgroundColor:'rgba(153, 102, 255, 0.2)',
                    borderColor:'rgb(153, 102, 255)',
                  fill: false,
                  borderWidth: 2,
                },
                {
                  label: "Montant charge mois actuel",
                  data: dataPayment.map((item) => parseFloat(item.ChargesThisMonth)),
                  backgroundColor: "rgb(247, 204, 194 )",
                  borderColor:'rgb(255, 99, 132)',
                  fill: false,
                  borderWidth: 2,
                },
                {
                  label: "Montant Charge à venir",
                  data: dataPayment.map((item) => parseFloat(item.ChargesUntilNY)),
                  backgroundColor: "rgb(247, 204, 194 )",
                  borderColor:'rgb(255, 99, 132)',
                  fill: true ,
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PrevisionMois;
