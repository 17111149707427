import React, { useState } from 'react';
import './KnowledgeBase.css';
import { Link } from "react-router-dom";
import Menu from "./Menu";

function KnowledgeBase() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Exemple d'indicateurs (à remplacer par vos données réelles)
  const indicators = [
    { name: 'Nombre de produits - Ajoute tes données', description: 'Nombre de produits vendus, ajouté par l utilisateur dans la case "Nombre" lors de l ajout de la vente.' },
    { name: '% de Tva - Ajoute tes données', description: 'TVA (en %), ajouté par l utilisateur dans la case "dont TVA (en %)" lors de l ajout de la vente.' },
    { name: 'Prix total - Ajoute tes données', description: 'Prix total, ajouté par l utilisateur dans la case "Prix total" lors de l ajout de la vente.' },
    { name: 'Prix final sans TVA - Ajoute tes données', description: 'Cet indicateur représente le Prix total auquel on a déduit la remise et le montant de la TVA. Par exemple, pour un Prix total à 1000€, une remise de 5% et une TVA de 10 %, le prix final sera de 1000x O.95 pour enlever les 5% de remise ce qui fait 955€. On enlevera ensuite la  TVA  avec 955*0.9 ce qui fera un Prix final sans tva de 855€.' },
    { name: 'Prix final - Ajoute tes données', description: 'Cet indicateur représente le Prix total auquel on a déduit la remise. Par exemple, pour un Prix total à 1000€, une remise de 5% , le prix final sera de 1000x O.95 pour enlever les 5% de remise ce qui fait 955€.' },
    { name: '% de remise - Ajoute tes données', description: 'Cet indicateur représente le % de remise. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Application d une remise (en %)" .' },
    { name: 'Montant d accompte - Ajoute tes données', description: 'Cet indicateur représente le montant d accompte payé par le client lors de la vente. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Accompte (en €)" .' },
    { name: 'Bonus presta 1 - Ajoute tes données', description: 'Cet indicateur représente le montant de bonus accordé au prestataire 1. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Bonus (€)" sur la ligne correspondant au prestataire 1.' },
    { name: 'Bonus presta 2 - Ajoute tes données', description: 'Cet indicateur représente le montant de bonus accordé au prestataire 2. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Bonus (€)" sur la ligne correspondant au prestataire 2.' },
    { name: 'Bonus presta 3 - Ajoute tes données', description: 'Cet indicateur représente le montant de bonus accordé au prestataire 3. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Bonus (€)" sur la ligne correspondant au prestataire 3.' },
    { name: 'Commission presta 1 - Ajoute tes données', description: 'Cet indicateur représente le % de commission à reverser  au prestataire 1. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Commission (%)" sur la ligne correspondant au prestataire 1.' },
    { name: 'Commission presta 2 - Ajoute tes données', description: 'Cet indicateur représente le % de commission à reverser  au prestataire 2. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Commission (%)" sur la ligne correspondant au prestataire 2.' },
    { name: 'Commission presta 3- Ajoute tes données', description: 'Cet indicateur représente le % de commission à reverser  au prestataire 3. Il a été renseigné par l utilisateur lors de l ajout de la  vente dans la case "Commission (%)" sur la ligne correspondant au prestataire 3.' },
    { name: 'Montant du paiement - Suivi de paiement', description: 'Cet indicateur représente le montant du paiement arrondi au centime et correspondant à la date de paiement théorique associée. Prenons l exemple d un prix final de 1000€ payable en 2 fois, il y aura 2 lignes, l une  de 500€ à la date du premier paiement et l autre un mois plus tard .' },
    { name: 'Date de paiement théorique - Suivi de paiement', description: "Cette date représente la date à laquelle le paiement est associé. Pour les paiements en plusieurs fois, elle est calculée en prenant 1 date / mois jusqu'à la fin du paiement total avec pour date de début la date de 1er paiement rentrée par l'utilisateur. Par exemple : une vente de 1000€ de prix final, faite en 2 paiements avec la date de premier paiement au 27/06 va faire apparaitre 2 lignes : la première avec une date de paiement théorique au 27/06 de 500€ et la seconde avec une date de paiement théorique au 27/07 de 500€." },
    { name: 'Chiffre d affaire généré - Data 360', description: "Chiffre d'affaire généré, comprends tout le chiffre d'affaire final généré par les ventes rentrées dans les données mis à part  celles qui ont été supprimées. Les ventes ayant subi des retractations sont comptées dans cet indicateur. Les remboursements clients ne sont pas déduis de cet indicateur." },
    { name: 'Chiffre d affaire généré sans remboursement - Data 360', description: "Chiffre d'affaire généré, comprends tout le chiffre d'affaire final des ventes rentrées dans les données mis à part celles qui ont été supprimées. Les ventes ayant subi des retractations sont comptées dans cet indicateur. Les remboursements clients sont déduis de cet indicateur." },
    { name: 'TVA - Data 360', description: "L'indicateur représente la somme de TVA accumulé sur la période." },
    { name: 'Panier Moyen - Data 360', description: "Somme du chiffre d affaire divisé par le nombre de clients.On se base ici sur le chiffre d'affaire généré et sur le nombre de clients généré. La seule exclusion est celle des ventes supprimées." },
    { name: 'Nombre de clients - Data 360', description: "Nombre de client ayant acheté un produit ou service durant la période. Seuls les clients des ventes supprimées sont exclus ici " },
    { name: 'Nombre de clients sans retractations - Data 360', description: "Nombre de client ayant acheté un produit ou service durant la période et dont la vente n'a pas subi de retractation. Les clients des ventes supprimées sont exclus ici " },
    { name: 'Nombre de retractations - Data 360', description: "Nombre de retractations sur la période séléctionnée." },
    { name: 'Somme des remboursements - Data 360', description: "Somme des remboursements. Correspond aux ventes retractées sur la périodes pour lesquelles l'utilisateur a renseigné un remboursement." },
    { name: 'Chiffre d affaire - Objectifs', description: "Chiffre d'affaire généré sur la période en question. Seul le Chiffre d'affaire des ventes annulées est déduit de cet indicateur" },
    { name: 'Nombre de clients - Objectifs', description: "Nombre de client ayant effectué une vente sur la période en question. Seul les clients des ventes annulées sont déduits de cet indicateur." },
    { name: 'Paiements validés depuis le début de l année - Prévisions', description: "Représente les paiements qui ont été validés en date du 1er janvier de l'année actuelle jusqu'au 1er jour du mois en cours exclus." },
    { name: 'Paiements en attente sur le mois en cours - Prévisions', description: "Représente les paiements à venir sur le mois actuel. Les paiemens validés ne sont pas comptabilisés ici, ils sont sommés sur les paiements validés depuis le début de l'année." },
    { name: 'Paiements en attente après le mois en cours pour le reste de l année - Prévisions', description: "Représente les paiements à venir après le mois actuel et jusqu à la fin de l année. Les paiemens validés ne sont pas comptabilisés ici, ils sont sommés sur les paiements validés depuis le début de l'année." },
    { name: 'Charges depuis le début de l année jusqu au mois en cours - Prévisions', description: "Représente la somme des charges depuis le début de l'année en cours jusqu'au 1er jour du mois actuel exclus." },
    { name: 'Charges à régler sur le mois en cours - Prévisions', description: "Représente la somme des charges du mois en cours." },
    { name: 'Charges à prévoir pour le reste de l année', description: "Représente la somme des charges à prévoir pour le reste de l'année à partir du 1er jour du mois prochain." },
    
    // Ajoutez d'autres indicateurs au besoin
  ];

  // Filtrer les indicateurs en fonction du terme de recherche
  const filteredIndicators = indicators.filter(indicator =>
    indicator.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="Customers">

    
    <div className="menu__container">
      <Menu />
    </div>
    <div className="principal__container">
    <div className="indicator-definitions-container">
      <h1>Indicateurs - Page où se trouve l'indicateur</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher par nom d'indicateur"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {filteredIndicators.map((indicator, index) => (
        <div className="indicator-definition" key={index}>
          <h2>{indicator.name}</h2>
          <p>{indicator.description}</p>
        </div>
      ))}
    </div>
    </div>
    </div>
  );
}

export default KnowledgeBase;
