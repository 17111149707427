import React, { useState } from 'react';
import "./FaqPage.css";
import Menu from "./Menu";
import FooterComponent from "./Footer";
import { logout } from "./firebase"; 

const FaqPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  
  const faqData = [
    {
      question: "Comment puis-je commencer avec Dataction ?",
      answer: (
        <p>
          Pour commencer avec Dataction, suivez les étapes mentionnées dans notre <a  onClick={() => window.open("https://www.youtube.com/watch?v=naU6AiuHOsU&list=PLQxT9U4C1VtH3O1nhSLN4v1C7jym9UzRZ&index=1", "_blank")}>Guide de Démarrage Rapide</a>. 
        </p>
      )
    },
    {
      question: "Comment contacter le support client ?",
      answer: (
        <p>
          Vous pouvez nous contacter 7j/7 sur l'adresse email : <a href="mailto:mariecadieuxpro@gmail.com ">mariecadieuxpro@gmail.com </a> en laissant vos coordonnées et en expliquant votre demande. Le support client vous répondra dès que possible et fera de son mieux pour que votre demande soit traitée rapidement.
        </p>
      )
    },
    {
      question: "Quelles sont les définitions de chaque indicateur ?",
      answer: (
        <p>
          Vous pouvez trouver les définitions détaillées de chaque indicateur sur notre <a href="/KnowledgeBase">page Indicateurs</a>.
        </p>
      )
    },
    {
      question: "Y a-t-il une application mobile ?",
      answer: (
        <p>
          Pour l'instant, l'application mobile n'existe pas, mais si c'est quelque chose qui vous plairait, n'hésitez pas à en faire part au service client et nous le prioriserons en fonction de vos retours.
        </p>
      )
    },
    {
      question: "Comment puis-je fournir des commentaires ou des suggestions ?",
      answer: (
        <p>
          Si vous souhaitez proposer une nouvelle fonctionnalité, faire une suggestion d'amélioration ou simplement exprimer votre satisfaction, n'hésitez pas à nous contacter sur <a href="mailto:mariecadieuxpro@gmail.com ">mariecadieuxpro@gmail.com </a>.
        </p>
      )
    },
    {
      question: "Quelle est la politique de confidentialité de Dataction ?",
      answer: (
        <p>
          Chez Dataction, la confidentialité des données est une priorité. Nous ne revendons en aucun cas vos données clients et mettons en place des mesures de sécurité pour protéger vos données. Pour en savoir plus sur notre politique de confidentialité et la gestion des bases de données, n'hésitez pas à nous contacter.
        </p>
      )
    }
  ];
  
  const filteredFaqData = faqData.filter(item => {
    const query = searchQuery.toLowerCase();
    
    const answerText = React.Children.toArray(item.answer.props.children)
      .map(child => {
        if (typeof child === 'string') {
          return child;
        } else if (React.isValidElement(child) && child.type === 'a') {
          return child.props.children;
        } else {
          return '';
        }
      })
      .join(' ');
  
    return (
      item.question.toLowerCase().includes(query) ||
      answerText.toLowerCase().includes(query)
    );
  });

  return (
    <div className="dashboard-container">
              <div className="Customers__container">

        <button className="Customers__btn" onClick={logout}>
          Se deconnecter
        </button>
      </div>
      <div className="menu__container">
        <Menu />
      </div>
      <div className="principal__container">
      <div className="indicator-definitions-container">
      <div className="dashboard-header">
        <h1 className="dashboard-title">FAQ</h1>
        <img className="FAQImage" alt="Background" src="question.jpg" />
      </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher "
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="faq-sections">
        {filteredFaqData.map((item, index) => (
          <div key={index} className="faq-section">
            <h2>{item.question}</h2>
            {item.answer}
          </div>
        ))}
      </div>
      <FooterComponent id ="Footer"  className="Footer" />
    </div>
    </div>
    
    </div>
  );
};

export default FaqPage;