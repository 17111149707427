
import React, { useEffect, useState } from "react";
import "./ContactForm.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import axios from "axios";
import CustomScroll from "react-custom-scroll";
import ls from 'localstorage-slim'; 
import { useAuthState } from "react-firebase-hooks/auth"; 
import  { auth,  db, logout } from "./firebase"
import { getFirestore } from "firebase/firestore";
import { query, getDocs, collection, where } from "firebase/firestore";
import { Grid } from "react-loader-spinner"; // Import the Oval spinner

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);


// ls.get('userUid',{ secret: encryptionKey })

function UpdateSales({ userID }) {
  const [sales, setSales] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [user, loading] = useAuthState(auth);
  const [loadingData, setLoadingData] = useState(true);
  const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");
  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/Sales/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/Sales/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingData(false);
                  }
                  return response.json();
                })
                .then((data) => {
                  setSales(data);
                  setLoadingData(false);
                  // console.log('Récupération des données ventes avec succès',data);
                })
                .catch((error) => {
                  setError(error.message);
                  setLoadingData(false);
                });
            } else {
              console.error("Le token n'est pas présent dans Firestore.");
              setLoadingData(false);
            }
          } else {
            console.error("L'utilisateur n'existe pas dans Firestore.");
            setLoadingData(false);
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
          setLoadingData(false);
        });
    }
  }, [user]);


  
  const handlePaymentValidation = async () => {
    try {
      // Logique pour envoyer les changements au serveur
      
      const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // Après la validation réussie, effectuez un nouvel appel à l'API
        const currentUserID = ls.get('userUid', { secret: encryptionKey });
        const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/Sales/${currentUserID}`;
  
        // Utilisez une fonction asynchrone pour pouvoir utiliser await
        const fetchData = async () => {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Réponse réseau incorrecte');
          }
  
          const data = await response.json();
          setSales(data);
          console.log('Données mises à jour avec succès');
        };
  
        await fetchData(); // Utilisez "await" ici pour attendre la fin de fetchData
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la validation du paiement :", error);
    }
    
  };

  const handleSaleValidation = (sale) => {
    // Logique pour envoyer les changements au serveur
    // console.log("Paiement validé :", sale);
  };

  const handleSelectSale = (sale) => {
    const isSelected = selectedSales.includes(sale);
    if (isSelected) {
      setSelectedSales(selectedSales.filter((p) => p !== sale));
    } else {
      setSelectedSales([...selectedSales, sale]);
    }
  };


  const handleValidateAll = () => {
    // Appeler la fonction pour envoyer les données validées
    sendValidatedSales();
    handlePaymentValidation();
  };

  const handleEffectiveDateChange = (sale, event) => {
    const updatedSale = { ...sale, EffectiveDate: event.target.value };
    setSales((prevSales) =>
      prevSales.map((p) => (p === sale ? updatedSale : p))
    );
  };

  const sendValidatedSales = async (validatedSales) => {
    // console.log("user connecté pour la fonction sendData :" ,user);
    try {
      if (!user) {
        console.error("L'utilisateur n'est pas connecté.");
        return;
      }
    //       // Sélectionnez les ventes validées à envoyer
        const validatedSales = selectedSales.map((sale) => ({
          userId: sale.userid,
          salesId: sale.salesid,
          Name: sale.Name,
        }));
      const currentUserID = ls.get('userUid', { secret: encryptionKey });
      // const url = `http://localhost:8080/api/insertDatadeleted`;
      const url = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/insertDatadeleted`;
  
      // console.log("user : ", user);
  
      // Obtenez le jeton Firestore de l'utilisateur
      const doc = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

        // console.log("console : " ,  doc);
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // console.log("token" , storedToken);
        if (storedToken) {
          console.log("on envoie les données c'est partit !!")
          // Effectuez une requête POST vers votre API Express pour envoyer les données
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Authorization': `Bearer ${storedToken}`,
            },
            body: JSON.stringify(validatedSales),
          });
  
          if (!response.ok) {
            throw new Error("Erreur lors de l'envoi des données validées");
          }
          setDeletionSuccessMessage("Vente supprimée avec succès");
          setTimeout(() => setDeletionSuccessMessage(""), 3000);
          // Affichez un message de succès ou effectuez d'autres actions nécessaires
          console.log("Données validées envoyées avec succès");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données validées :", error);
      // Traitez l'erreur et affichez un message approprié à l'utilisateur
    }

  };

 
  const handlePopupConfirmation= async () => {
    setShowPopup(false);
    await handleValidateAll();
    await handlePaymentValidation();

  };

  const handlePopupModification = () => {
    setShowPopup(false);
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      {loadingData && (
 <div className="loading-container">
 <div className="loading-animation">
   {/* Add your loading animation here */}
   {/* Calcul et chargement de vos données de paiement prestataires */}
   <Grid
     color="#A6B1F7" // Set the color
     height={100} // Set the size
     width={50}
     secondaryColor="#7F8BCC"
     fill="none"
     strokeWidth={8}
   />
 </div>
</div>
      )
}
      {sales.length === 0 && !loadingData &&(
        <span> Il n'y a pas de ventes qui sont annulables</span>
      )}
      {sales.length > 0 && (
        <>
          <h1>Selectionne les ventes que tu veux annuler</h1>
          <div className="search-bar-Name">
          <input
            type="text"
            placeholder="Rechercher par nom"
            value={searchTerm}
            onChange={handleSearch}
          />
          </div>
          <p></p>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                <th>Supprimer</th>
                  <th>Référence de la vente</th>
                  <th> Nombre de produits</th>
                 <th>Nom du client</th>
                 <th> % de Tva</th>
                <th> Prix total</th>
                <th> Prix final sans TVA</th>
                <th> Prix final</th>
                <th> Origine du client</th>
                <th> % de remise </th>
                <th> Montant d'accompte</th>
                {/* <th> id</th> */}
                <th> Nombre de paiements</th>
                <th> Nom presta 1</th>
                <th> Nom presta 2</th>
                <th> Nom presta 3</th>
                <th> bonus presta 1</th>
                <th> bonus presta 2</th>
                <th> bonus presta 3</th>
                <th> Type Paiement presta 1</th>
                <th> Type Paiement presta 2</th>
                <th> Type Paiement presta 3</th>
                <th> commission presta 1</th>
                <th> commission presta 2</th>
                <th> commission presta 3</th>
                {/* <th> retractation</th>
                <th> remboursement</th> */}
                {/* <th> Montant déjà payé</th>
                <th> Commission Presta 1 déjà payée</th>
                <th> Commission Presta 2 déjà payée</th>
                <th> Commission Presta 3 déjà payée</th> */}
                <th> Remboursement Client</th>
                <th> Annulation Paiements Futurs</th>
                <th> Récuperation Commission Presta1</th>
                <th> Récuperation Commission Presta2</th>
                <th> Récuperation Commission Presta3</th>
                <th> Annulation Commissions Futures Presta1</th>
                <th> Annulation Commissions Futures Presta2</th>
                <th> Annulation Commissions Futures Presta3</th>
                <th> Rétractation ? </th>
                {/* <th> Futurs Paiements</th> */}

                  </tr>
                </thead>
                <tbody>
                  {sales
  ? sales
                    .filter((sale) =>
                    (sale.Name ?? "")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((sale, index) => (
                      <tr key={index}>
                            <td>
                          <input
                            type="checkbox"
                            checked={
                              selectedSales.includes(sale)
                            }
                            onChange={() => handleSelectSale(sale)}
                          />
                        </td>
                            <td>{sale.salesid}</td>
                  <td>{sale.number}</td>
                  <td> {sale.Name}</td>
                  <td> {sale.Tva}</td>
                  <td> {sale.totalPrice}</td>
                  <td> {sale.totalPriceWoTVA}</td>
                  <td> {sale.FinalPrice}</td>
                  <td> {sale.clientOrigin}</td>
                  <td> {sale.discountAmount}</td>
                  <td> {sale.downPayment}</td>
                  {/* <td> {sale.id}</td> */}
                  <td> {sale.numPayments}</td>
                  <td> {sale.providerName}</td>
                  <td> {sale.providerName2}</td>
                  <td> {sale.providerName3}</td>
                  <td> {sale.bonus}</td>
                  <td> {sale.bonus2}</td>
                  <td> {sale.bonus3}</td>
                  <td> {sale.paymentOptionProvider1}</td>
                  <td> {sale.paymentOptionProvider2}</td>
                  <td> {sale.paymentOptionProvider3}</td>
                  <td> {sale.commission}</td>
                  <td> {sale.commission2}</td>
                  <td> {sale.commission3}</td>

                  {/* <td> {sale.retractation}</td>
                  <td> {sale.refund}</td> */}
                  {/* <td> {sale.paidAmount}</td> */}
                  {/* <td> {sale.paidCommission1}</td>
                  <td> {sale.paidCommission2}</td>
                  <td> {sale.paidCommission3}</td> */}
                  <td> {sale.RemboursementClient}</td>
                  <td> {sale.AnnulationPaiementsFuturs}</td>
                  <td> {sale.RecuperationCommissionPresta1}</td>
                  <td> {sale.RecuperationCommissionPresta2}</td>
                  <td> {sale.RecuperationCommissionPresta3}</td>
                  <td> {sale.AnnulationCommissionsFuturesPresta1}</td>
                  <td> {sale.AnnulationCommissionsFuturesPresta2}</td>
                  <td> {sale.AnnulationCommissionsFuturesPresta3}</td>
                  <td> {sale.retractationFlag}</td>
                  {/* <td> {sale.FuturePayments}</td> */}
                    
                      </tr>
                    )):<p>Pas de données</p>}
                </tbody>
              </table>
            </div>

          <button onClick={handlePopupOpen}>Valider la sélection</button>

          {/* Popup */}
          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <h2>Résumé de la sélection</h2>
                <p>
                  Vérifie bien ta sélection avant de valider car cette action
                  n'est pas modifiable.
                </p>
                <div className="popup-buttons">
                  <button onClick={handlePopupConfirmation}>
                    Valider la sélection
                  </button>
                  <button onClick={handlePopupModification}>
                    Modifier la sélection
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {deletionSuccessMessage && (
  <div Classname="success-message">{deletionSuccessMessage}</div>
)}
    </div>
  );
}

export default UpdateSales;
