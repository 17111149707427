

import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Chart from "chart.js/auto";
import  "./ContactFormGoal.css";
import { Bar } from "react-chartjs-2";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ls from 'localstorage-slim'; 
import { Oval } from "react-loader-spinner"; // Import the Oval spinner
import { Grid } from "react-loader-spinner"; // Import the Oval spinner
const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);


function FormGoalsAmount() {
  const [inputValue, setInputValue] = useState({
    Monthly: "",
    Yearly: "",
    Weekly: "",
  });
  const [realValue, setRealValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [items, setItems] = useState([]);
  const [goalPercentage, setGoalPercentage] = useState(0);

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [errorFirebase, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]);

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.Yearly === "") return;
    if (isEditing) {
      await firebase.firestore()
        .collection("goals")
        .doc(ls.get('userUid',{ secret: encryptionKey }))
        .collection("goalsInfosAmount")
        .doc("Amount")
        .set({
          Monthly: (inputValue.Yearly / 12).toFixed(2),
          Yearly: inputValue.Yearly,
          Weekly: (inputValue.Yearly / 52).toFixed(2),
          type: "goal",
        });
      setIsEditing(false);
      setEditId(null);
    } else {
      await firebase.firestore()
        .collection("goals")
        .doc(ls.get('userUid',{ secret: encryptionKey }))
        .collection("goalsInfosAmount")
        .doc("Amount")
        .set({
          Monthly: (inputValue.Yearly / 12).toFixed(2),
          Yearly: inputValue.Yearly,
          Weekly: (inputValue.Yearly / 52).toFixed(2),
          type: "goal",
        });
    }
    setInputValue({ Monthly: "", Yearly: "", Weekly: "" });
    console.log("inputValue",inputValue )
  };


  const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //           `http://localhost:3008/api/data/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       // Filtrer les données par userID
  //       const filteredData = jsonData.filter(
  //           (realData) => realData.userId === currentUserID
  //         );
  //       setData(filteredData);
  //       setRealValue(filteredData[0]);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  useEffect(() => {
    // console.log(user)
    if (user) {
      console.log("User connected");

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/data/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/data/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingData(false);
                  }
                  return response.json();
                })
                .then((data) => {
                  setRealValue(data[0]);
                  console.log( "RealValue" , realValue );
                  // console.log('Récupération des données ventes avec succès',realValue);
                  setLoadingData(false);
                })
                .catch((errorFirebase) => {
                  setError(errorFirebase.message);
                  setLoadingData(false);
                });
            } else {
              console.errorFirebase("Le token n'est pas présent dans Firestore.");
              setLoadingData(false);
            }
          } else {
            console.errorFirebase("L'utilisateur n'existe pas dans Firestore.");
            setLoadingData(false);
          }
        })
        .catch((errorFirebase) => {
          console.errorFirebase("Erreur lors de la récupération du token depuis Firestore:", error);
          setLoadingData(false);
        });
    }
  }, [user]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID =ls.get('userUid',{ secret: encryptionKey });

  //       const response = await fetch(
  //         `http://localhost:3008/api/data/${currentUserID}`
  //       );
  //       const jsonData = await response.json();

  //       // Filtrer les données par userID
  //       const filteredData = jsonData.filter(
  //         (realData) => realData.userId === currentUserID
  //       );
  //       console.log(filteredData);
  //       setData(filteredData);
  //       setRealValue(filteredData[0]);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleEdit = async (id) => {
    const docRef = firebase.firestore()
      .collection("goals")
      .doc(ls.get('userUid',{ secret: encryptionKey }))
      .collection("goalsInfosAmount")
      .doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
      setInputValue(doc.data());
      setIsEditing(true);
      setEditId(id);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDelete = async (id) => {
    await firebase.firestore()
      .collection("goals")
      .doc(ls.get('userUid',{ secret: encryptionKey }))
      .collection("goalsInfosAmount")
      .doc(id)
      .delete();
  };

  const handleInputChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };


  useEffect(() => {
    const fetchData = async () => {
      const userId = ls.get('userUid', { secret: encryptionKey });
      const collectionRef = firebase.firestore()
        .collection("goals")
        .doc(ls.get('userUid',{ secret: encryptionKey }))
        .collection("goalsInfosAmount");
  
      const unsubscribe = collectionRef.onSnapshot(async (snapshot) => {
        const data = [];
        for (const doc of snapshot.docs) {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        }
        setItems(data);
      });
  
      return unsubscribe;
    };
  
    fetchData();
  }, []);
  let chartDataYear = {};
  let chartDataMonth = {};
  let chartDataWeek = {};
  if (realValue && items.length > 0) {
    chartDataYear = {
      labels: ["Annuel"],
      datasets: [
        {
          label: "Pourcentage d'atteinte",
          data: [
            // (1 -
            //   (parseFloat(items[0].Yearly) - realValue.Year) /
            //     parseFloat(items[0].Yearly)) *
            //   100,
              Math.min(
                (1 - (parseFloat(items[0].Yearly) - realValue.Year) / parseFloat(items[0].Yearly)) * 100,
                100
              ).toFixed(2),
          ],
          backgroundColor: "rgba(166, 177, 247, 1)",
        },
        {
          label: "Pourcentage restant",
          data: [
            // (parseFloat(items[0].Yearly) - realValue.Year) /
            //   parseFloat(items[0].Yearly) >
            // 0
            //   ? ((parseFloat(items[0].Yearly) - realValue.Year) /
            //       parseFloat(items[0].Yearly)) *
            //     100
            //   : 0,
            Math.max(((parseFloat(items[0].Yearly) - realValue.Year) /  parseFloat(items[0].Yearly))*100, 0).toFixed(2)
          ],
          backgroundColor: ["#888888"],
        },
      ],
    };

    chartDataMonth = {
      labels: ["Mensuels"],
      datasets: [
        {
          label: "Pourcentage d'atteinte",
          data: [
            Math.min((1 - (parseFloat(items[0].Monthly) - realValue.Month) /
            parseFloat(items[0].Monthly)) * 100,
                100
                ).toFixed(2),
              
            // (1 -
            //   (parseFloat(items[0].Monthly) - realValue.Month) /
            //     parseFloat(items[0].Monthly)) *
            //   100,
          ],
          backgroundColor: "rgba(166, 177, 247, 1)",
        },
        {
          label: "Pourcentage restant",
          data: [
            Math.max(((parseFloat(items[0].Monthly) - realValue.Month) /  parseFloat(items[0].Monthly))*100, 0).toFixed(2)
            // (parseFloat(items[0].Monthly) - realValue.Month) /
            //   parseFloat(items[0].Monthly) >
            // 0
            //   ? ((parseFloat(items[0].Monthly) - realValue.Month) /
            //       parseFloat(items[0].Monthly)) *
            //     100
            //   : 0,
          ],
          backgroundColor: ["#888888"],
        },
      ],
    };

    chartDataWeek = {
      labels: ["Hebdo"],
      datasets: [
        {
          label: "Pourcentage d'atteinte",
          data: [
            // (1 -
            //   (parseFloat(items[0].Weekly) - realValue.Week) /
            //     parseFloat(items[0].Weekly)) *
            //   100,
            Math.min((1-(parseFloat(items[0].Weekly) - realValue.Week) /
            parseFloat(items[0].Weekly))*100,  100).toFixed(2)
          ],
          backgroundColor: "rgba(166, 177, 247, 1)",
        },
        {
          label: "Pourcentage restant",
          data: [
            Math.max(((parseFloat(items[0].Weekly) - realValue.Week) /  parseFloat(items[0].Weekly)) *100, 0).toFixed(2)
            // (parseFloat(items[0].Weekly) - realValue.Week) /
            //   parseFloat(items[0].Weekly) >
            // 0
            //   ? ((parseFloat(items[0].Weekly) - realValue.Week) /
            //       parseFloat(items[0].Weekly)) *
            //     100
            //   : 0,
          ],
          backgroundColor: ["#888888"],
        },
      ],
    };
  }
  const options2 = {
    indexAxis: "y", // Utiliser l'axe Y pour les barres
    scales: {
      x: {
        display: true, // Masquer l'axe X
        stacked: true,
        beginAtZero: true,
      },
      y: {
        // display: false, // Masquer l'axe Y
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Masquer la légende
      },
    },
    barPercentage: 0.3,
    borderColor: "transparent",
    borderSkipped: true,
  };
  const options1 = {
    indexAxis: "y", // Utiliser l'axe Y pour les barres
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        display: true, // Masquer l'axe X
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true, // Masquer la légende
      },
    },
    barPercentage: 0.3,
    borderColor: "rgba(0, 0, 0, 0.4)",
  };

  return (
    <div className="Principal_container">
      <h2> Où en es-tu sur ton chiffre d'affaire ? </h2>
      {items.length === 0 && (
        <form onSubmit={handleSubmit} className="form__container_Goal">
          <h2> Fixe tes objectifs de chiffre d'affaire </h2>
          <div>
            <label htmlFor="Yearly" className="form__label">
              Annuel :
            </label>
            <input
              className="form__input"
              type="number"
              placeholder="Entrer une valeur"
              value={inputValue.Yearly}
              onChange={(e) =>
                setInputValue({
                  ...inputValue,
                  Yearly: e.target.value,
                })
              }
            />
          </div>
          <p>
            {" "}
            Ce qui fait : {(inputValue.Yearly / 4).toFixed(2)} € en Trimestriel,{" "}
            {(inputValue.Yearly / 12).toFixed(2)} € en Mensuel et{" "}
            {(inputValue.Yearly / 52).toFixed(2)} € en Hebdomadaire
          </p>
          <button className="form__submit" type="submit">
            {isEditing ? "UpYearly" : "Enregistrer"}
          </button>
        </form>
      )}
       {!realValue && loadingData==true &&(
      //   <div className="loading-container">
      //   <div className="loading-animation">
      //     {/* Add your loading animation here */}
      //     Calcul et chargement de tes données 
      //     <Oval
      //       color="#A6B1F7" // Set the color
      //       height={100} // Set the size
      //       width={50}
      //       secondaryColor="#7F8BCC"
      //       fill="none"
      //       strokeWidth={8}
      //     />
      //   </div>
      // </div>
      <div className="loading-container">
             <div className="loading-animation">
               {/* Add your loading animation here */}
               {/* Calcul et chargement de vos données de paiement prestataires */}
               <Grid
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               />
             </div>
           </div>
       )}
                 {!realValue && loadingData==false &&(
            <span>Ajoute des données pour pouvoir voir ta progression </span>
          )}
      {items.length > 0 && realValue && (
        <div className="chart-container">
          <div className="chart-wrapper">
            <Bar data={chartDataYear} options={options2} type="bar" />
          </div>
          <div className="chart-wrapper">
            <Bar data={chartDataMonth} options={options2} type="bar" />
          </div>
          <div className="chart-wrapper">
            <Bar data={chartDataWeek} options={options2} type="bar" />
          </div>
        </div>
      )}
      
        {items.length > 0 && realValue && (
          <div className="objectifs">
          <div className="GoalsData">
            <h3>Commentaire :</h3>
            <div className="KpisGoalsData">
            {parseFloat(items[0].Weekly) - realValue.Week > 0 ? (
              <p>
                Il te reste <span className="montant"> {parseFloat(items[0].Weekly) - realValue.Week}€ </span> à
                générer pour atteindre ton objectif hebdo car tu as généré <span className="montant">{realValue.Week}€  </span> sur la semaine.
              </p>
            ) : (
              <p>Tu as explosé tes objectifs hebdo 🎉</p>
            )}
            {parseFloat(items[0].Monthly) - realValue.Month > 0 ? (
              <p>
                Il te reste <span className="montant"> {parseFloat(items[0].Monthly) - realValue.Month}€ </span> à
                générer pour atteindre ton objectif mensuel car tu as généré <span className="montant">  {realValue.Month}€ </span> sur le mois.
              </p>
            ) : (
              <p>Tu as explosé tes objectifs mensuels 🎉</p>
            )}
            {parseFloat(items[0].Yearly) - realValue.Year > 0 ? (
              <p>
                Il te reste <span className="montant">{parseFloat(items[0].Yearly) - realValue.Year}€ </span> à
                générer pour atteindre ton objectif annuel car tu as généré  <span className="montant"> {realValue.Year}€ </span> sur l'année.
              </p>
            ) : (
              <p>Tu as explosé tes objectifs annuels 🎉</p>
            )}
          </div>
          </div>
          </div>
        )}

   
      
      {items.length > 0 && (
        <div className = "Rappel">
          <h3>Pour rappel :</h3>
          <p className="objectifs">
          Ton objectif annuel de chiffre d'affaire est de :{" "}
            <span className="montant">{(items[0].Yearly)}€</span>
            <br></br>
            Ce qui fait <span className="montant">{items[0].Monthly} €</span> /
            mois et <span className="montant">{items[0].Weekly} €</span> /
            semaine
          </p>

          <button
            className="form__submit"
            onClick={() => handleEdit(items[0].id)}
          >
            Le modifier
          </button>
        </div>
      )}
      <br></br>
      {isEditing && (
        <form onSubmit={handleSubmit} className="form__container_Goal">
          <h2>Modifier ton objectif annuel</h2>
          <div>
            <label htmlFor="Yearly" className="form__label">
              Annuel :
            </label>
            <input
              className="form__input"
             type="number"
              name="Yearly"
              placeholder="Entrer une valeur"
              value={inputValue.Yearly}
              onChange={handleEditInputChange}
            />
          </div>
          <p>
            {" "}
            Ce qui fait : {(inputValue.Yearly / 4).toFixed(2)} € en Trimestriel,{" "}
            {(inputValue.Yearly / 12).toFixed(2)} € en Mensuel et{" "}
            {(inputValue.Yearly / 52).toFixed(2)} € en Hebdomadaire
          </p>
          <div>
            <button className="form__submit" type="submit">
              Modifier
            </button>{" "}
            <button
              className="form__submit"
              onClick={() => {
                setIsEditing(false);
                setEditId(null);
                setInputValue({ Monthly: "", Yearly: "", Weekly: "" });
              }}
            >
              Annuler
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default FormGoalsAmount;
