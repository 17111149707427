// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { auth, signInWithGoogle, logInWithEmailAndPassword } from "./firebase";

// import { useAuthState } from "react-firebase-hooks/auth";
// import "./Login.css";

// import ls from 'localstorage-slim'; 

// const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);
// console.log(encryptionKey)

// ls.config.encrypt = true; 

// ls.set('Test',  'holà' ,{ secret: 20 });
// console.log(ls.get('Test',{ secret: 20 }));

// // console.log(process.env.REACT_APP_API_KEY);
// // console.log(process.env.REACT_APP_ENCRYPTIONKEY);
// // console.log(process.env.REACT_APP_TEST);

// // ls.set('Test2',  'ça maaaaarche' ,{ secret: encryptionKey });
// // console.log(ls.get('Test2',{ secret: encryptionKey }));


// // ls.set('Test2', 'test' ,{ secret: encryptionKey });
// // console.log(ls.get('Test2',{ secret: encryptionKey }));


// // ls.set('Test2', 'test' ,{ secret: encryptionKey });
// // console.log(ls.get('Test2',{ secret: encryptionKey }));


// function storeUserIdInLocalStorage(user) {
//   if (user) {
//     ls.set('userUid', user.uid ,{ secret: encryptionKey });
    
//   } else {
//     localStorage.removeItem("userUid");
//   }
// }


// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [user, loading, error] = useAuthState(auth);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (loading) {
//       // maybe trigger a loading screen
//       return;
//     }
//     if (user) {
//       navigate("/dashboard");
//       storeUserIdInLocalStorage(user); // Appel de la fonction pour stocker l'IID dans le local storage
//     }
//   }, [user, loading]);
  
//   return (
//     <div className="login">
//       <div className="background">
//       <img src="/BackLogin.png" alt="Background" className="background-image" />
//     </div>
//       <div className="back_home">
//         <Link to="/">Retour à l'accueil</Link>
//       </div>
//       <div className="login__container">
//         <input
//           type="text"
//           className="login__textBox"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Adresse E-mail"
//         />
//         <input
//           type="password"
//           className="login__textBox"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Mot de passe"
//         />
//         <button
//           className="login__btn"
//           onClick={() => logInWithEmailAndPassword(email, password)}
//         >
//           Se connecter
//         </button>
//         <button className="login__btn login__google" onClick={signInWithGoogle}>
//         Se connecter avec Google
//         </button>
//         <div>
//           <Link to="/reset">Mot de passe oublié?</Link>
//         </div>
//         <div>
//           Pas encore de compte? <Link to="/register">S'inscrire</Link> .
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Login;

// // Implémente la page de connexion pour le site Web en utilisant Firebase pour l'authentification des utilisateurs. Voici ce que chaque partie du code fait :

// // Les modules React et Firebase sont importés, ainsi que quelques éléments du module react-router-dom pour la navigation entre les pages du site.
// // La fonction Login est définie. Elle crée des états pour les champs de texte de l'e-mail et du mot de passe, ainsi que pour l'utilisateur connecté, la variable de chargement et l'erreur (à partir de la fonction useAuthState).
// // La fonction useEffect est utilisée pour gérer l'affichage de la page. Si le chargement est en cours, rien ne se passe. Si un utilisateur est connecté, la page est redirigée vers le tableau de bord.
// // Le rendu de la page est géré en utilisant des éléments HTML dans la fonction return(). Cette fonction renvoie une div avec une classe login qui contient un formulaire de connexion avec deux champs de texte pour l'e-mail et le mot de passe, ainsi que deux boutons : "Login" qui déclenche la fonction signInWithEmailAndPassword de Firebase pour l'authentification avec l'e-mail et le mot de passe, et "Login with Google" qui déclenche la fonction signInWithGoogle pour l'authentification avec Google.
// // Il y a également deux liens vers la page "Forgot Password" et la page d'inscription "Register".
// // Enfin, le composant est exporté pour pouvoir être utilisé dans d'autres parties de l'application.
///////////////////////////////// FONCTIONNE ///////////////////////////

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle, logInWithEmailAndPassword } from "./firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import ls from 'localstorage-slim'; 
import firebase from 'firebase/compat/app'; // Importez firebase de cette manière

const encryptionKey = parseInt(process.env.REACT_APP_TEST, 10);

ls.config.encrypt = true;




function storeUserIdInLocalStorage(user) {
  if (user) {
    ls.set('userUid', user.uid, { secret: encryptionKey });
  } else {
    localStorage.removeItem("userUid");
  }
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // peut-être déclencher un écran de chargement
      return;
    }
    if (user) {
      navigate("/dashboard");
      storeUserIdInLocalStorage(user); // Appeler la fonction pour stocker l'UID dans le local storage
    }
  }, [user, loading]);

  useEffect(() => {
    // Utilisez onAuthStateChanged pour attendre que Firebase soit prêt
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // console.log('user', user)
      if (user) {
        console.log("User connected");
  
        // Utilisateur connecté, obtenez le token Firebase
        user.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Vous avez maintenant le token Firebase dans la variable idToken
            console.log("récupération de l'ID token");
  
            // Enregistrez le token Firebase dans Firestore
            const userDocRef = firebase.firestore()
              .collection('users') // Utilisez le nom de votre collection Firestore
              .doc(user.uid); // Utilisez l'ID de l'utilisateur comme identifiant du document
  
            // Utilisez .set() avec merge: true pour fusionner le token dans les données utilisateur existantes
            userDocRef.set({ token: idToken }, { merge: true })
              .then(() => {
                // console.log('token écrit dans la base de données Firestore');
              })
              .catch((error) => {
                console.error("Erreur lors de l'ajout du token à Firestore: ", error);
              });
  
          })
          .catch(function (error) {
            console.error("Erreur lors de l'obtention du token Firebase:", error);
          });
      }
    });
  }, []);
  
  return (
    <div className="login">
      <div className="background">
      <img src="/BackLogin.png" alt="Background" className="background-image" />
    </div>
      <div className="back_home">
        <Link to="/">Retour à l'accueil</Link>
      </div>
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Adresse E-mail"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Se connecter
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
        Se connecter avec Google
        </button>
        <div>
          <Link to="/reset">Mot de passe oublié?</Link>
        </div>
        <div>
          Pas encore de compte? <Link to="https://datactionapi.web.app/">S'inscrire</Link>
        </div>
      </div>
    </div>
  );
}
export default Login;

// Implémente la page de connexion pour le site Web en utilisant Firebase pour l'authentification des utilisateurs. Voici ce que chaque partie du code fait :

// Les modules React et Firebase sont importés, ainsi que quelques éléments du module react-router-dom pour la navigation entre les pages du site.
// La fonction Login est définie. Elle crée des états pour les champs de texte de l'e-mail et du mot de passe, ainsi que pour l'utilisateur connecté, la variable de chargement et l'erreur (à partir de la fonction useAuthState).
// La fonction useEffect est utilisée pour gérer l'affichage de la page. Si le chargement est en cours, rien ne se passe. Si un utilisateur est connecté, la page est redirigée vers le tableau de bord.
// Le rendu de la page est géré en utilisant des éléments HTML dans la fonction return(). Cette fonction renvoie une div avec une classe login qui contient un formulaire de connexion avec deux champs de texte pour l'e-mail et le mot de passe, ainsi que deux boutons : "Login" qui déclenche la fonction signInWithEmailAndPassword de Firebase pour l'authentification avec l'e-mail et le mot de passe, et "Login with Google" qui déclenche la fonction signInWithGoogle pour l'authentification avec Google.
// Il y a également deux liens vers la page "Forgot Password" et la page d'inscription "Register".
// Enfin, le composant est exporté pour pouvoir être utilisé dans d'autres parties de l'application.
