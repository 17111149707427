import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import "./Menu.css"
function Menu() {
  const location = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubMenu(false);
  };
  return (
    <div className="menu">
      <h2 className="MenuTitle">Menu</h2>
      <ul>
        <li>
          <Link to="/InsertData" className={location.pathname === "/InsertData" ? "MenuActive" : ""}>
            Ajoute tes données
          </Link>
        </li>
        <li>
          <Link to="/Payment" className={location.pathname === "/Payment" ? "MenuActive" : ""}>
            Suivi de Paiement
          </Link>
        </li>
        <li>
          <Link to="/Data360" className={location.pathname === "/Data360" ? "MenuActive" : ""}>
            Data 360
          </Link>
        </li>
        <li>
          <Link to="/Goals" className={location.pathname === "/Goals" ? "MenuActive" : ""}>
            Objectifs
          </Link>
        </li>
        <li>
          <Link to="/Forecasts" className={location.pathname === "/Forecasts" ? "MenuActive" : ""}>
            Previsions
          </Link>
        </li>
        {/* <li>
          <Link to="/Faq" className={location.pathname === "/Faq" ? "MenuActive" : ""}>
            FAQ
          </Link>
        </li> */}
        <li>
          <div
            className="sub-menu-trigger"
            // className={location.pathname === "/FAQ" ||location.pathname === "/KnowledgeBase" ? "MenuActive" : ""}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Infos
            {showSubMenu && (
              <ul className="sub-menu">
                               
                <li>
                  <Link
                    to="/KnowledgeBase"
                    className={location.pathname === "/KnowledgeBase" ? "MenuActive" : ""}
                  >
                    Calculs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/FAQ"
                    className={location.pathname === "/FAQ" ? "MenuActive" : ""}
                  >
                    FAQ
                  </Link>
                 </li>
              </ul>
            )}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Menu;
