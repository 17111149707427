import React from "react";
import { Link } from "react-router-dom";
import "./NotPremiumPage.css"; // Assurez-vous d'importer correctement le fichier CSS
import FooterComponent from "./Footer";
import {logout } from "./firebase";

function NotPremiumPage() {
  return (
    <div className="not-premium-page">
      <div className="Section__image__NotPremium">
      <h1>Bienvenue sur Dataction</h1>
          <img className="PrevisionImage" alt="Background" src="NotPremiumImage.jpg" />
          <br></br>
      <h2>Vous n'avez pas d'abonnement actuellement</h2>
      <p>Pour accéder à ce contenu, veuillez souscrire à un abonnement premium.</p>
      <div className="button-container">
        <Link to="/"  onClick={logout} className="BackHomeButton">Retour à la page d'accueil</Link>
        <Link to="https://datactionapi.web.app/">Souscrire à un abonnement</Link>
      </div>
      </div>
      <FooterComponent id ="Footer"  className="Footer" />
    </div>
  );
}

export default NotPremiumPage;
