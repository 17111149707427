import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
import "./Register.css";
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard", { replace: true });
  }, [user, loading]);
  return (
    <div className="register">
      <div className="background">
      <img src="/BackLogin.png" alt="Background" className="background-image" />
    </div>
      <div className="back_home">
        <Link to="/">Retour à l'accueil</Link>
      </div>
      <div className="register__container">
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom complet"
        />
        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Adresse E-mail"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
        />
        <button className="register__btn" onClick={register}>
          S'inscrire
        </button>
        <button
          className="register__btn register__google"
          onClick={signInWithGoogle}
        >
          S'inscrire avec Google
        </button>
        <div>
          Vous avez déjà un compte? <Link to="/Login">Se connecter</Link> .
        </div>
      </div>
    </div>
  );
}
export default Register;

// Ce script est le composant React qui représente un formulaire d'inscription.
// Il permet aux utilisateurs de créer un compte en entrant leur nom complet, leur adresse e-mail et leur mot de passe.
// Ils peuvent également s'inscrire en utilisant leur compte Google.
// Voici ce que fait ce script en détail :
// Importation des modules nécessaires: "React", "useEffect", "useState", "useAuthState", "Link", "useNavigate" et les fonctions d'authentification de Firebase.
// Le composant "Register" est créé.
// Il contient quatre états qui gèrent les entrées de l'utilisateur : "email", "password", "name" et "user".
// La constante "loading" est définie en utilisant la fonction "useAuthState" de Firebase pour déterminer si l'utilisateur est en train de charger.
// La constante "navigate" est créée en utilisant la fonction "useNavigate" de "react-router-dom". Elle permet de naviguer vers une autre page lorsqu'un événement est déclenché.
// La fonction "register" est définie pour enregistrer un utilisateur en utilisant "registerWithEmailAndPassword" de Firebase. Si l'utilisateur n'a pas entré son nom, une alerte sera affichée.
// La fonction "useEffect" est utilisée pour rediriger l'utilisateur vers la page de tableau de bord ("/dashboard") une fois qu'il a été enregistré avec succès ou si l'utilisateur est déjà connecté.
// Le composant "Register" renvoie un formulaire HTML avec des champs d'entrée pour le nom, l'adresse e-mail et le mot de passe de l'utilisateur. Les boutons "Register" et "Register with Google" sont utilisés pour s'inscrire.
// Les liens de navigation sont utilisés pour rediriger les utilisateurs vers la page de connexion s'ils ont déjà un compte.
