
import React, { useState, useEffect } from "react";
import "./ContactForm.css";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ls from 'localstorage-slim'; 

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);

// ls.get('userUid',{ secret: encryptionKey })
function ChargeForm() {
  const [chargesName, setChargeName] = useState("");
  const [chargesClass, setChargesClass] = useState("");
  const [montant, setPrice] = useState("");
  const [Date, setDate] = useState("");
  const [periode, setperiode] = useState("");
  const [Datefin, setDatefin] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [DateError, setDateErrorMessage] = useState("");
  const [editingCharge, setEditingCharge] = useState(null);
  const [charges, setCharges] = useState([]);
  const [showData, setShowData] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();


  
  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]);

  // const fetchUserName = async () => {
  //   try {
  //     const q = query(collection(db, "users"), where("uid", "==", user?.uid));
  //     const doc = await getDocs(q);
  //     const data = doc.docs[0].data();
  //     setName(data.name);
  //   } catch (err) {
  //     console.error(err);
  //     alert("An error occured while fetching user data");
  //   }
  // };
  // auth.currentUser.then((user) => {
  //   user.getIdToken(true);
  // });

  // console.log( "test : ", auth.currentUser.getIdTokenResult(true));
  // console.log( "test : ", auth.currentUser.getIdTokenResult(true).PromiseResult );

  firebase.auth().currentUser.getIdTokenResult(true)
  .then((idTokenResult) => {
    const claims = idTokenResult.claims;
    // console.log("claims", claims);
    // console.log("claims", claims.stripeRole);
    // Vérifiez les réclamations ici
    
  }
  )
  .catch((error) => {
    console.error("Erreur lors de la récupération des réclamations :", error);
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userUid = ls.get('userUid', { secret: encryptionKey });
        const unsubscribe = firebase
          .firestore()
          .collection("charges")
          .where("userId", "==", userUid) 
          .onSnapshot((snapshot) => {
            const charges = [];
            snapshot.forEach((doc) => {
              charges.push({ id: doc.id, ...doc.data() });
            });
            setCharges(charges);
          });
  
        return unsubscribe;
      } catch (error) {
        console.error("An error occurred while fetching charges:", error);
      }
    };
  
    fetchData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const userUid = ls.get('userUid',{ secret: encryptionKey });

    if (Date >= Datefin) {
      console.log("La date de début doit être inférieure à la date de fin.");
      setDateErrorMessage("Erreur la date de début doit être inférieure à la date de fin.");
      setTimeout(() => setDateErrorMessage(false), 3000);
      return;
    }

    const charge = {
      chargesName,
      chargesClass,
      montant,
      userId: userUid,
      Date,
      Datefin,
      periode,
    };

    if (editingCharge) {
      firebase
        .firestore()
        .collection("charges")
        .doc(editingCharge.id)
        .update(charge)
        .then(() => {
          console.log("Charge updated successfully in Firestore!");
          setSuccessMessage("Modification de la charge réussie avec succès");
          setTimeout(() => setSuccessMessage(false), 3000);
          setChargeName("");
          setChargesClass("");
          setPrice("");
          setDate("");
          setperiode("");
          setDatefin("");
          setEditingCharge(null);
        })
        .catch((error) => {
          console.error("Error updating charge in Firestore: ", error);
        });
    } else {
      firebase
        .firestore()
        .collection("charges")
        .add(charge)
        .then(() => {
          console.log("Charge added successfully to Firestore!");
          setSuccessMessage("Ajout de la charge réussie avec succès");
          setTimeout(() => setSuccessMessage(false), 3000);
          setChargeName("");
          setChargesClass("");
          setPrice("");
          setDate("");
          setDatefin("");
          setperiode("");
        })
        .catch((error) => {
          console.error("Error adding charge to Firestore: ", error);
        });
    }
  };

  const toggleData = () => {
    setShowData(!showData);
  };

  const handleEditCharge = (charge) => {
    setEditingCharge(charge);
    setChargeName(charge.chargesName);
    setChargesClass(charge.chargesClass);
    setPrice(charge.montant);
    setDate(charge.Date);
    setperiode(charge.periode);
    setDatefin(charge.Datefin);
  };

  const handleDeleteCharge = (id) => {
    const userUid = ls.get('userUid',{ secret: encryptionKey });
    firebase
      .firestore()
      .collection("charges")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Charge deleted successfully from Firestore!");
        setSuccessMessage("Charge supprimée avec succès");
        setTimeout(() => setSuccessMessage(false), 3000);
      })
      .catch((error) => {
        console.error("Error deleting charge from Firestore: ", error);
      });
  };

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit}>
        <h1 className="form__title">
          {editingCharge ? "Modification d'une Charge" : "Ajout d'une Charge"}
        </h1>

        <div className="form__row">
          <label htmlFor="chargesName" className="form__label">
            Nom de la charge
          </label>
          <input
            type="text"
            name="chargesName"
            placeholder="Ex : Restaurant"
            value={chargesName}
            onChange={(e) => setChargeName(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="chargesClass" className="form__label">
            Classe de la charge
          </label>
          <input
            type="text"
            placeholder="Ex : Cohésion"
            name="chargesClass"
            value={chargesClass}
            onChange={(e) => setChargesClass(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="montant" className="form__label">
            Montant €
          </label>
          <input
            type="number"
            name="montant"
            value={montant}
            placeholder="Ex : 100"
            min={0}
            onChange={(e) => setPrice(e.target.value)}
            className="form__input"
            required
          />
        </div>
        <div className="form__row">
          <label htmlFor="periode" className="form__label">
            Période
          </label>

          <select
            id="periode"
            type="text"
            defaultValue="Ponctuelle"
            value={periode}
            onChange={(e) => setperiode(e.target.value)}
            required
            className="select_input"
          >
            <option value="Ponctuelle">Choisissez la période</option>
            <option value="Hebdomadaire">Hebdomadaire</option>
            <option value="Mensuelle">Mensuelle</option>
            <option value="Annuelle">Annuelle</option>
            <option value="Ponctuelle">Ponctuelle</option>
          </select>
        </div>
        <div className="form__row">
          <label htmlFor="Date" className="form__label">
            Date de 1er paiement
          </label>
          <input
            type="date"
            name="Date"
            value={Date}
            onChange={(e) => setDate(e.target.value)}
            className="form__input"
            required
            max={Datefin}
          />
        </div>
        <div className="form__row">
          <label htmlFor="Datefin" className="form__label">
            Date de fin
          </label>
          <input
            type="date"
            name="Datefin"
            value={Datefin}
            onChange={(e) => setDatefin(e.target.value)}
            className="form__input"
            min={Date}
          />
        </div>
        <br />
        <button type="submit" className="form__submit">
          {editingCharge
            ? "Enregistrer les modifications"
            : "Enregistrer la charge"}
        </button>
      </form>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <div className="charge-list">
        <button onClick={toggleData} className="form__submit">
          {showData ? "Masquer les charges" : "Afficher les charges"}
        </button>
        {showData && (
          <>
            <h2>Liste des charges</h2>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Classe</th>
                    <th>Montant</th>
                    <th>Période</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.map((charge) => (
                    <tr key={charge.id}>
                      <td>{charge.chargesName}</td>
                      <td>{charge.chargesClass}</td>
                      <td>{charge.montant}</td>
                      <td>{charge.periode}</td>
                      <td>{charge.Date}</td>
                      <td>{charge.Datefin}</td>
                      <td>
                        <button
                          className="form__submit"
                          onClick={() => handleEditCharge(charge)}
                        >
                          Modifier
                        </button>
                      </td>
                      <td>
                        <button
                          className="form__submit"
                          onClick={() => handleDeleteCharge(charge.id)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChargeForm;
