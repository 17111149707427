import React, { useEffect, useState } from "react";
import "./Payment.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import axios from "axios";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ls from 'localstorage-slim'; 

import { useAuthState } from "react-firebase-hooks/auth"; 
import  { auth,  db, logout } from "./firebase"
import { getFirestore } from "firebase/firestore";
import { query, getDocs, collection, where } from "firebase/firestore";
import { Grid } from "react-loader-spinner"; // Import the Oval spinner


const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);
function ProvidersKPI({ userID }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [loadingData, setLoadingData] = useState(true);

  const exportToPDF = () => {
    const container = document.querySelector(".dashboard-container");

    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("dashboard.pdf");
    });
  };
  const [user, loading] = useAuthState(auth);
  const [errorFirebaseProviderKpi, setErrorFirebaseProviderKpi] = useState(null);
  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/ProvidersKPI/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/ProvidersKPI/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingData(false);
                  }
                  return response.json();
                })
                .then((data) => {
                  setData(data);
                  setFilteredData(data);
                  setLoadingData(false);
                  // console.log('Récupération des données provider avec succès',filteredData);
                })
                .catch((errorFirebaseProviderKpi) => {
                  setLoadingData(false);
                  setErrorFirebaseProviderKpi(errorFirebaseProviderKpi.message);
                });
            } else {
              setLoadingData(false);
              console.errorFirebaseProviderKpi("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingData(false);
            console.errorFirebaseProviderKpi("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((errorFirebaseProviderKpi) => {
          setLoadingData(false);
          console.errorFirebaseProviderKpi("Erreur lors de la récupération du token depuis Firestore:", errorFirebaseProviderKpi);
        });
    }
  }, [user]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //         `http://localhost:3008/api/ProvidersKPI/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       setData(jsonData);
  //       setFilteredData(jsonData); // Initialize filteredData with all data
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  const handleProviderChange = (event) => {
    const selectedProvider = event.target.value;
    setSelectedProvider(selectedProvider);

    // Filter data based on selected provider
    if (selectedProvider === "") {
      setFilteredData([]); // Set an empty array when "Sélectionne un prestataire" is selected
    } else if (selectedProvider === "all") {
      setFilteredData(data); // Show all data when "Tous les prestataires" is selected
    } else {
      const filteredData = data.filter(
        (item) => item.providerName === selectedProvider
      );
      setFilteredData(filteredData);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2 className="dashboard-title">Prestataires KPI</h2>
        {data.length === 0 &&  loadingData==true && (
           <div className="loading-container">
           <div className="loading-animation">
             {/* Add your loading animation here */}
             {/* Calcul et chargement de tes données  */}
             <Grid
               color="#A6B1F7" // Set the color
               height={100} // Set the size
               width={50}
               secondaryColor="#7F8BCC"
               fill="none"
               strokeWidth={8}
             />
           </div>
         </div>
        )}
        {data.length === 0  &&  loadingData==false && (
          <span>
            Ajoute des données et/ou valide tes paiements pour pouvoir analyser
          </span>
        )}
        {data.length > 0 && (
          <div className="filter">
            <label htmlFor="providerFilter"></label>
            <select
              id="providerFilter"
              value={selectedProvider}
              onChange={handleProviderChange}
            >
              <option value="">Sélectionne un prestataire</option>
              {/* <option value="all">Tous les prestataires</option> */}
              {data.map((item) => (
                <option key={item.providerName} value={item.providerName}>
                  {item.providerName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {/* {console.log(selectedProvider)} */}
      {selectedProvider == "" && (<span></span>)}
      {!selectedProvider =="" && ( 
      <div className="chart-container">
        {/* Render filtered data */}
        {filteredData.map((item) => (
          <div key={item.providerName} className="indicatorPresta">
             {/* <h1>{item.providerName}</h1> */}
            <div className="indicator-item">
              <span>Nombre de ventes effectuées cette semaine:</span>
              <span className="indicator-value">{item.weekNbSales}</span>
            </div>
            <div className="indicator-item">
              <span>Commissions validées cette semaine:</span>
              <span className="indicator-value">
                {item.weekCommissionsValidated.toFixed(2)}
              </span>
            </div>
            <div className="indicator-item">
              <span>Commissions en attente cette semaine:</span>
              <span className="indicator-value">
                {item.weekCommissionsWaiting.toFixed(2)}
              </span>
            </div>
            <div className="indicator-item">
              <span>Nombre de ventes effectuées ce mois-ci:</span>
              <span className="indicator-value">{item.monthNbSales}</span>
            </div>
            <div className="indicator-item">
              <span>Commissions validées ce mois-ci:</span>
              <span className="indicator-value">
                {item.monthCommissionsValidated.toFixed(2)}
              </span>
            </div>
            <div className="indicator-item">
              <span>Commissions en attente ce mois-ci:</span>
              <span className="indicator-value">
                {item.monthCommissionsWaiting.toFixed(2)}
              </span>
            </div>
            <div className="indicator-item">
              <span>Nombre de ventes effectuées cette année:</span>
              <span className="indicator-value">{item.yearNbSales}</span>
            </div>
            <div className="indicator-item">
              <span>Commissions validées cette année:</span>
              <span className="indicator-value">
                {item.yearCommissionsValidated.toFixed(2)}
              </span>
            </div>
            <div className="indicator-item">
              <span>Commissions en attente cette année:</span>
              <span className="indicator-value">
                {item.yearCommissionsWaiting.toFixed(2)}
              </span>
            </div>
          </div>
        ))}
      </div>
      )}
      {!selectedProvider =="" && ( 
      <button onClick={exportToPDF}>Exporter en PDF</button>

      )}
      
    </div>
  );
}

export default ProvidersKPI;
