import React, { useEffect, useState } from "react";
import "./Payment.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import axios from "axios";
import confetti from "canvas-confetti";
import CustomScroll from "react-custom-scroll";
import ls from 'localstorage-slim'; 
import { useAuthState } from "react-firebase-hooks/auth"; 
import  { auth,  db, logout } from "./firebase"
import { getFirestore } from "firebase/firestore";
import { query, getDocs, collection, where } from "firebase/firestore";

// import { Loader } from "react-loader-spinner";
// import { Oval } from "react-loader-spinner"; // Import the Oval spinner
import { Grid } from "react-loader-spinner"; // Import the Oval spinner

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);

const getCurrentDate = () => {
  const currentDate = new Date();
  // console.log(currentDate);
  return currentDate;
};

function ProvidersPayment({ userID }) {
  const [payments, setPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, loading] = useAuthState(auth);
  const [errorFirebaseProvider, setErrorFirebaseProvider] = useState(null);
  const [error, setError] = useState(null);
  const [loadingDataPaymentProvider, setLoadingDataPaymentProvider] = useState(true);
  const [selectedPaymentsDetails, setSelectedPaymentsDetails] = useState([]);


  useEffect(() => {
    // console.log(user)
    if (user) {
      // console.log("User connected :",user);

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/ProviderPaymentPrevision/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/ProviderPaymentPrevision/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingDataPaymentProvider(false);
                    // console.log("loadingDataPaymentProvider payment provider reponse" , loadingDataPaymentProvider);
                  }
                  return response.json();
                })
                .then((data) => {
                  setPayments(data);
                  console.log('Récupération des données provider avec succès');
                  setLoadingDataPaymentProvider(false);
                  // console.log("loadingDataPaymentProvider payment" , loadingDataPaymentProvider);
                })
                .catch((errorFirebaseProvider) => {
                  setErrorFirebaseProvider(errorFirebaseProvider.message);
                  setLoadingDataPaymentProvider(false);
                  // console.log("loadingDataPaymentProvider payment" , loadingDataPaymentProvider);
                });
            } else {
              setLoadingDataPaymentProvider(false);
              console.errorFirebaseProvider("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataPaymentProvider(false);
            console.errorFirebaseProvider("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((errorFirebaseProvider) => {
          setLoadingDataPaymentProvider(false);
          console.errorFirebaseProvider("Erreur lors de la récupération du token depuis Firestore:", errorFirebaseProvider);
        });
    }
  }, [user]);


  const fetchData = async () => {
    try {
      if (user) {
        console.log("User connected");
  
        const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
        if (doc.exists) {
          const userData = doc.data();
          const storedToken = userData.token;
  
          if (storedToken) {
            const currentUserID = ls.get('userUid', { secret: encryptionKey });
            const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/ProviderPaymentPrevision/${currentUserID}`;
  
            const response = await fetch(apiUrl, {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            });
  
            if (!response.ok) {
              throw new Error('Réponse réseau incorrecte');
              setLoadingDataPaymentProvider(false);
            }
            setLoadingDataPaymentProvider(false);
            const data = await response.json();
            setPayments(data);
            console.log('Récupération des données ventes avec succès');
          } else {
            console.error("Le token n'est pas présent dans Firestore.");
            setLoadingDataPaymentProvider(false);
          }
        } else {
          console.error("L'utilisateur n'existe pas dans Firestore.");
          setLoadingDataPaymentProvider(false);
        }
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la récupération des données depuis l'API :", error);
      setLoadingDataPaymentProvider(false);
    }
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //         `http://localhost:3008/api/ProviderPaymentPrevision/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       setPayments(jsonData);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  const handlePaymentValidation = async (payment) => {
    try {
      // Logique pour envoyer les changements au serveur
      // console.log("Paiement validé :", payment);
      const doc = await firebase.firestore().collection("users").doc(user.uid).get();
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
        // Après la validation réussie, effectuez un nouvel appel à l'API
        const currentUserID = ls.get('userUid', { secret: encryptionKey });
        const apiUrl = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/ProviderPaymentPrevision/${currentUserID}`;
  
        // Utilisez une fonction asynchrone pour pouvoir utiliser await
        const fetchData = async () => {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Réponse réseau incorrecte');
          }
  
          const data = await response.json();
          setPayments(data);
          console.log('Données mises à jour avec succès');
        };
  
        await fetchData(); // Utilisez "await" ici pour attendre la fin de fetchData
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la validation du paiement :", error);
    }
    // console.log("Paiement validé :", payment);
  };
  const handleSelectPayment = (payment) => {
    const isSelected = selectedPayments.includes(payment);
    if (isSelected) {
      setSelectedPayments(selectedPayments.filter((p) => p !== payment));
    } else {
      setSelectedPayments([...selectedPayments, payment]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPayments([]);
    } else {
      setSelectedPayments(payments);
    }
    setSelectAll(!selectAll);
  };

  const handleValidateAll = (user) => {
    // Appeler la fonction pour envoyer les données validées
    sendValidatedPayments(user);
  };

  const handleEffectiveDateChange = (payment, event) => {
    const updatedPayment = { ...payment, EffectiveDate: event.target.value };
    setPayments((prevPayments) =>
      prevPayments.map((p) => (p === payment ? updatedPayment : p))
    );
  };



  const sendValidatedPayments = async (user) => {
    // console.log("user connecté pour la fonction sendValidatedPayments :" ,user);
    try {
      if (!user) {
        console.error("L'utilisateur n'est pas connecté.");
        return;
      }
  
      const currentUserID = ls.get('userUid', { secret: encryptionKey });
      // const url = `http://localhost:8080/api/insertDataPaymentProviders`;
      const url = `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/insertDataPaymentProviders`;
  
      // Sélectionnez les paiements validés à envoyer
      const validatedPayments = selectedPayments.map((payment) => ({
        ...payment,
        DatePayment: payment.DatePayment.value,
      }));
  
      // console.log(validatedPayments);
      // console.log(validatedPayments[0]);
      // console.log("user : ", user);
  
      // Obtenez le jeton Firestore de l'utilisateur
      const doc = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get();

        // console.log("console : " ,  doc);
  
      if (doc.exists) {
        const userData = doc.data();
        const storedToken = userData.token;
      
        if (storedToken) {
          // Effectuez une requête POST vers votre API Express pour envoyer les données
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Authorization': `Bearer ${storedToken}`,
            },
            body: JSON.stringify(validatedPayments),
          });
  
          if (!response.ok) {
            throw new Error("Erreur lors de l'envoi des données validées");
          }
  
          // Affichez un message de succès ou effectuez d'autres actions nécessaires
          console.log("Données validées envoyées avec succès");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données validées :", error);
      // Traitez l'erreur et affichez un message approprié à l'utilisateur
    }
    fetchData();
    handleClick();
    setLoadingDataPaymentProvider(true);
  };

  // const sendValidatedPayments = async () => {
  //   try {
  //     if (firebase.auth().currentUser) {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  //       const url = `http://localhost:3008/api/insertDataPaymentProviders`;
  
  //       // Sélectionnez les paiements validés à envoyer
  //       const validatedPayments = selectedPayments.map((payment) => ({
  //         ...payment,
  //         DatePayment: payment.DatePayment.value,
  //       }));
  
  //       console.log(validatedPayments);
  //       console.log(validatedPayments[0]);
  //       // Effectuez une requête POST vers votre API Express pour envoyer les données
  //       const response = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': process.env.REACT_APP_API_KEY,
  //         },
  //         body: JSON.stringify(validatedPayments),
  //       });
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de l'envoi des données validées");
  //       }
  
  //       // Affichez un message de succès ou effectuez d'autres actions nécessaires
  //       console.log("Données validées envoyées avec succès");
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de l'envoi des données validées :", error);
  //     // Traitez l'erreur et affichez un message approprié à l'utilisateur
  //   }
  //   handleClick();
  // };
  const handleClick = () => {
    confetti({
      particleCount: 1000,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  const handlePopupConfirmation = () => {
    setShowPopup(false);
    handleValidateAll(user);
    handlePaymentValidation();
  };

  const handlePopupModification = () => {
    setShowPopup(false);
    // Add your logic to handle the modification of the selection here
    // For example, you can navigate to a different page or display a form to modify the selection
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
    setSelectedPaymentsDetails(selectedPayments);
  };
  
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="Container-Paiement">
      <h1>Suis et valide les paiements de tes prestataires</h1>
  
      <br></br>
  
      {loadingDataPaymentProvider ? (
        <div className="loading-container">
          <div className="loading-animation">
            {/* Add your loading animation here */}
            {/* Calcul et chargement de vos données de paiement prestataires */}
            <Grid
              color="#A6B1F7" // Set the color
              height={100} // Set the size
              width={50}
              secondaryColor="#7F8BCC"
              fill="none"
              strokeWidth={8}
            />
          </div>
        </div>
        // <div className="loading-container">
        //   <div className="loading-animation">
        //     {/* Add your loading animation here */}
        //     Calcul et chargement de vos données de paiement prestataires
        //     <Oval
        //       color="#A6B1F7" // Set the color
        //       height={100} // Set the size
        //       width={50}
        //       secondaryColor="#7F8BCC"
        //       fill="none"
        //       strokeWidth={8}
        //     />
        //   </div>
        // </div>
      ) : (
        <>
          {payments.length === 0 ? (
            <span> Il n'y a pas de données de paiements prestataires pour le moment </span>
          ) : (
            <>
              <div className="search-bar-Name">
                {/* <h1>Paiements prestataires à valider</h1> */}
                <input
                  type="text"
                  placeholder="Rechercher par nom"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="SearchInput"
                />
              </div>
              <p></p>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                    <th> Sélectionner </th>
                      <th> Nom du prestataire </th>
                      <th> Montant de la commission </th>
                      <th> Date de paiement théorique </th>
                      <th> Date de paiement effective </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments
                      ? payments
                          .filter((payment) =>
                            (payment.providerName ?? "")
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((payment, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectAll || selectedPayments.includes(payment)
                                  }
                                  onChange={() => handleSelectPayment(payment)}
                                />
                              </td>
                              <td>{payment.providerName}</td>
                              <td>{payment.commission.toFixed(2)}€</td>
                              <td>{payment.DatePayment.value}</td>
                              <td>
                                <input
                                  type="date"
                                  value={
                                    payment.EffectiveDate ||
                                    payment.DatePayment.value
                                  }
                                  onChange={(event) =>
                                    handleEffectiveDateChange(payment, event)
                                  }
                                  max={getCurrentDate().toISOString().split('T')[0]} // Définit la date maximale comme étant la date actuelle
                                />
                              </td>
                            </tr>
                          ))
                      : <p>Pas de données</p>}
                  </tbody>
                </table>
              </div>
              <br></br>
              <button onClick={handleSelectAll} style={{ marginRight: "10px" }}>
                {selectAll ? "Désélectionner tout" : "Sélectionner tout"}
              </button>
              <button onClick={handlePopupOpen}>Valider la sélection</button>
  
              {/* Popup */}
              {showPopup && (
  <div className="popup">
    <div className="popup-content">
      <br></br>
      <h2>Résumé de la sélection</h2>
      <p>
        Vérifiez bien votre sélection avant de valider, car cette action n'est pas modifiable.
      </p>
      <br></br>
      {/* <h3>Ventes sélectionnées :</h3> */}
      <ul>
        {selectedPaymentsDetails.map((payment, index) => (
          <li key={index}>
            {payment.providerName} - {payment.commission.toFixed(2)}€
          </li>
        ))}
      </ul>
      <br></br>
      <div className="popup-buttons">
        <button onClick={handlePopupModification}>
          Modifier la sélection
        </button>
        <button onClick={handlePopupConfirmation}>
          Valider la sélection
        </button>
        
      </div>
    </div>
  </div>
)}
            </>
          )}
        </>
      )}
    </div>
  );
}  

export default ProvidersPayment;
