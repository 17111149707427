import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, logout } from "./firebase";
import ls from 'localstorage-slim';
import FooterComponent from "./Footer";

const encryptionKey = parseInt(process.env.REACT_APP_TEST, 10);

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [stripeRoleVar, setStripeRole] = useState("No"); // Ajoutez un état pour stocker le rôle Stripe
  const [redirected, setRedirected] = useState(false); // Ajoutez un état pour gérer la redirection
  const navigate = useNavigate();

  // useEffect(() => {
  //   const currentUserID = ls.get('userUid', { secret: encryptionKey });
    
  //   if (loading) return;
    
  //   // if (!currentUserID) {
  //   //   navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
  //   //   return;
  //   // }

  //   // Récupérez les revendications personnalisées de l'utilisateur
  //   auth.currentUser.getIdTokenResult(true)
  //     .then((idTokenResult) => {
  //       const claims = idTokenResult.claims;
  //       setStripeRole(claims.stripeRole); // Définissez le rôle Stripe dans l'état
  //       console.log( "Test stripe role", claims.stripeRole);
  //       console.log( "Test stripe role", stripeRoleVar);
  //     })
  //     .catch((error) => {
  //       console.error("Erreur lors de la récupération des revendications personnalisées :", error);
  //     });
  // }, [user, loading, navigate]);

  // // Vérifiez le rôle Stripe et redirigez si nécessaire
  // useEffect(() => {
  //   console.log("stripeRole:", stripeRoleVar);
  //   console.log("redirected:", redirected);
  
  //   if (stripeRoleVar !== "Premium" && !redirected) {
  //     console.log("Redirection vers /Register");
  //     setRedirected(true);
  //     navigate('/NotPremiumPage'); // Redirige vers la page d'inscription si le rôle n'est pas "premium"
  //      // Marquez la redirection comme effectuée
  //   } else if (stripeRoleVar === "Premium" && !redirected && !user ) {
  //     console.log("Redirection vers /Login");
  //     setRedirected(true); // Marquez la redirection comme effectuée
  //     navigate('/Login'); // Redirige vers le tableau de bord si le rôle est "premium"

  //   }
  // }, [stripeRoleVar, redirected, navigate]);
  useEffect(() => {
    const currentUserID = ls.get('userUid', { secret: encryptionKey });
    if (error) {
      // Si une erreur d'authentification se produit, redirigez l'utilisateur vers la page de connexion
      navigate('/Login'); // Remplacez '/Login' par le chemin de votre page de connexion
    }
    if (loading) return;
  
// console.log(auth.currentUser);
    if (!currentUserID || !auth.currentUser ) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
      return;
    }
  
    // Récupérez les revendications personnalisées de l'utilisateur
    auth.currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        const claims = idTokenResult.claims;
        // console.log("claims",claims);
        const stripeRole = claims.stripeRole;
        // console.log("stripeRole",stripeRole); 
        if (stripeRole !== "Premium" && !redirected) {
          console.log("Redirection vers /Register");
          setRedirected(true);
          navigate('/NotPremiumPage'); // Redirige vers la page d'inscription si le rôle n'est pas "premium"
        } else if (stripeRole === "Premium" && !redirected && !user) {
          console.log("Redirection vers /Login");
          setRedirected(true);
          navigate('/Login'); // Redirige vers le tableau de bord si le rôle est "premium"
        }
  
        // Définissez le rôle Stripe dans l'état
        setStripeRole(stripeRole);
  
        // console.log("Test stripe role", stripeRole);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des revendications personnalisées :", error);
      });
  }, [user, loading, redirected, navigate]);

  const sections = [

    { title: "Ajoute tes données", path: "/InsertData" , imageUrl: "DashImage1.jpg"},
    { title: "Suivi de paiement", path: "/Payment" , imageUrl: "DashImage2.jpg"},
    { title: "Data 360", path: "/Data360" , imageUrl: "DashImage3.jpg"},
    { title: "Objectifs", path: "/Goals" , imageUrl: "DashImage6.jpg"},
    { title: "Prévisions", path: "/Forecasts" , imageUrl:"DashImage4.jpg"},
    { title: "FAQ", path: "/Faq" , imageUrl: "DashImage5.jpg"},
   
  ];

  
  const Section = ({ title, path, imageUrl }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate(path);
    }; 

    return (
      <div className="Section__container" onClick={handleClick}>
         <div className="Section__image" >
        <img src={imageUrl} alt={`Image for ${title} / ${imageUrl}`} />
      </div>
        <h2>{title}</h2>

      </div>
    );
  };

  return (
<div className="dashboard">

  <div className="dashboard__container">
  
    <img className="logo" src="logoPurpleGrey.png" alt="Logo" />
  
    {/* <span>{name}</span>
    <span>{user?.email}</span> */}
    <button className="dashboard__btn" onClick={logout}>
      Se déconnecter
    </button>
  </div>
  <div>
  <button className="dashboard__tutorial-btn" onClick={() => window.open("https://www.youtube.com/watch?v=naU6AiuHOsU&list=PLQxT9U4C1VtH3O1nhSLN4v1C7jym9UzRZ&index=1", "_blank")}>
          Accéder au tutoriel
        </button>
  <div className="Section">
    {sections.map((section) => (
      <Section
        key={section.title}
        title={section.title}
        path={section.path}
        imageUrl={section.imageUrl}
      />
    ))}
    <br></br> 
  </div>
  </div>
  <FooterComponent id ="Footer"  className="FooterSection" />
</div>
  );
}
export default Dashboard;
