import React from 'react';
import './Footer.css'; // Assurez-vous d'importer votre feuille de style CSS pour le footer

function FooterComponent() {
  return (
    <footer className="footer">
      <div className="footer__container">
        
          <p> </p>
          <div className="footer__nav-section">
            <h3>Navigation</h3>
            <a href="/dashboard">Accueil</a>
            <br></br>
              {/* <li><a href="/features">Fonctionnalités</a></li> */}
              {/* <li><a href="/about">À propos</a></li> */}
            <a href="https://dataction.io/">Notre site</a>
          </div>
          <div className="footer__nav-section">
            <h3>Assistance</h3>
              {/* <li><a href="/support">Support</a></li> */}
              <a href="/knowledgeBase">Base de connaissances</a>
              <br></br>
              <a href="https://datactionapi.web.app/">Se désabonner</a>
          </div>
     
      
        <div className="footer__nav-section">
          <h3>Contact</h3>
          <p>Email: mariecadieuxpro@gmail.com</p>
          <a className="tuto" onClick={() => window.open("https://www.youtube.com/watch?v=naU6AiuHOsU&list=PLQxT9U4C1VtH3O1nhSLN4v1C7jym9UzRZ&index=1", "_blank")}>Tuto d'utilisation</a>
        </div>
      </div>
      <br></br>
      <a className="footer__nav-freepik" href="http://www.freepik.com">Images designed by Freepik</a>
      <div className="footer__copyright">
        <p>&copy; {new Date().getFullYear()} Dataction. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

export default FooterComponent;