import { combineReducers } from "redux";
import authReducer from "./authReducer";
import clientReducer from "./clientReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
});

export default rootReducer;

// Le code ci-dessus importe combineReducers de redux, puis importe les reducers authReducer et clientReducer que nous avons créés précédemment.
// Enfin, combineReducers est utilisé pour combiner ces reducers en un seul reducer racine en les assignant à des propriétés correspondantes.
// Ici, nous avons nommé les propriétés 'auth' et 'client', mais vous pouvez les nommer comme vous le souhaitez.
// Le rootReducer est exporté pour pouvoir être utilisé dans notre application.
