import {
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
} from "../actions/clientActions";

const initialState = {
  clients: [],
};

function clientReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.client],
      };
    case EDIT_CLIENT:
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === action.id ? action.client : client
        ),
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client.id !== action.id),
      };
    default:
      return state;
  }
}

export default clientReducer;

// Ce code définit un clientReducer, qui est une fonction pure qui prend un état et une action et renvoie un nouvel état, basé sur le type d'action.
//  L'état initial est un objet contenant une liste de clients vides.
// Les types d'action utilisés sont ADD_CLIENT, EDIT_CLIENT, et DELETE_CLIENT, qui sont définis dans le fichier clientActions.
// La fonction reducer effectue différentes opérations en fonction du type d'action. 
// Pour l'action ADD_CLIENT, elle renvoie un nouvel état en ajoutant le client à la liste existante. Pour l'action EDIT_CLIENT, elle renvoie un nouvel état en mettant à jour le client existant avec les nouvelles informations de client. Pour l'action DELETE_CLIENT, elle renvoie un nouvel état en supprimant le client de la liste. Si l'action n'est pas l'une des actions spécifiées, la fonction reducer renvoie simplement l'état actuel sans le modifier.
// Ce code est généralement utilisé avec une bibliothèque de gestionnaire d'état tel que Redux ou MobX pour gérer l'état de l'application React.
