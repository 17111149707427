import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "./firebase";
import "./Reset.css";
function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div className="reset">
      <div className="background">
      <img src="/BackLogin.png" alt="Background" className="background-image" />
    </div>
      <div className="back_home">
        <Link to="/">Retour à l'accueil</Link>
      </div>
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button className="reset__btn" onClick={() => sendPasswordReset(email)}>
          Réinitialisation du mot de passe par mail
        </button>
        <div>
        Pas encore de compte? <Link to="/register">S'inscrire</Link> .
        </div>
      </div>
    </div>
  );
}
export default Reset;

// Ce code  affiche un formulaire qui permet à l'utilisateur de demander la réinitialisation de son mot de passe.
// Le composant utilise plusieurs hooks React :

// Le hook useState est utilisé pour créer un état local email, qui stocke l'adresse e-mail saisie par l'utilisateur.
// Le hook useAuthState est utilisé pour accéder à l'état d'authentification actuel de l'utilisateur dans Firebase.
// Il retourne un tableau contenant trois éléments : l'utilisateur actuellement connecté (ou null s'il n'y en a pas), un indicateur de chargement (true s'il y a une requête en cours pour récupérer l'état d'authentification, false sinon) et une erreur (null s'il n'y a pas d'erreur).
// Le hook useNavigate est utilisé pour obtenir un objet "navigateur" qui permet de naviguer vers d'autres pages de l'application.
// Le hook useEffect est utilisé pour surveiller les changements de l'état d'authentification de l'utilisateur.
// Lorsque l'état d'authentification change, le code vérifie si l'utilisateur est connecté. Si c'est le cas, il navigue automatiquement vers la page du tableau de bord de l'application.
// Le code utilise également plusieurs fonctions Firebase :

// auth permet d'accéder à l'objet firebase.auth() pour l'authentification de l'utilisateur.
// sendPasswordResetEmail est une fonction de Firebase qui permet d'envoyer un e-mail de réinitialisation de mot de passe à l'adresse e-mail spécifiée.
// Enfin, le composant définit un formulaire contenant un champ de saisie pour l'adresse e-mail et un bouton pour envoyer un e-mail de réinitialisation de mot de passe.
// Il affiche également un lien pour s'inscrire à l'application s'il n'a pas déjà de compte.
