import React from "react";
import { Link } from "react-router-dom";
import "./NotPremiumPage.css"; // Assurez-vous d'importer correctement le fichier CSS
import FooterComponent from "./Footer";
import {logout } from "./firebase";

function PageNotFound() {
  return (
    <div className="not-premium-page">
      <div className="Section__image__NotPremium">
      <h1>Bienvenue sur Dataction</h1>
          <img className="PrevisionImage" alt="Background" src="404.jpg" />
          <br></br>
      <h2>Cette page n'existe pas</h2>
      <div className="button-container">
        <Link to="/dashboard"  className="BackHomeButton">Pour accéder à l'application, c'est ici </Link>
      </div>
      </div>
      <FooterComponent id ="Footer"  className="Footer" />
    </div>
  );
}

export default PageNotFound;
