import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./GraphComponent.css";
import ls from 'localstorage-slim'; 
import  { auth,  logout } from "./firebase"
import { getFirestore } from "firebase/firestore";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth"; 

const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);




const db = firebase.firestore();

const GraphComponentMonth = ({ userID }) => {
  const [dataGraphAmount, setDataGraphAmount] = useState(null);
  const [dataGraphNumber, setDataGraphNumber] = useState(null);
  const [chartDataAmount, setChartDataAmount] = useState(null);
  const [chartDataNumber, setChartDataNumber] = useState(null);
  //// AMOUNT
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
  //       if (!currentUserID) {
  //         throw new Error("ID utilisateur non valide");
  //       }
  
  //       const response = await fetch(
  //           `http://localhost:3008/api/CAMonth/${currentUserID}`,
  //         {
  //           headers: {
  //             'x-api-key': process.env.REACT_APP_API_KEY,
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Erreur lors de la récupération des données");
  //       }
  
  //       const jsonData = await response.json();
  //       // Filtrer les données par userID
  //       const filteredData = jsonData.filter(
  //           (realData) => realData.userId === currentUserID
  //         );
  //         setDataGraphAmount(filteredData);
  //         setDataGraphNumber(filteredData);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error.message);
  //       // Gérer l'erreur de manière appropriée pour l'utilisateur
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  const [error, setError] = useState(null);
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    // console.log(user)
    if (user) {
      console.log("User connected");

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/CAMonth/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/CAMonth/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                  }
                  return response.json();
                })
                .then((data) => {
                  setDataGraphNumber(data);
                  setDataGraphAmount(data);
                  console.log('Récupération des données ventes avec succès');
                })
                .catch((error) => {
                  setError(error.message);
                });
            } else {
              console.error("Le token n'est pas présent dans Firestore.");
            }
          } else {
            console.error("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
        });
    }
  }, [user]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const currentUserID = ls.get('userUid',{ secret: encryptionKey });

  //       const response = await fetch(
  //         `http://localhost:3008/api/CAMonth/${currentUserID}`
  //       );
  //       const jsonData = await response.json();

  //       // Filtrer les données par userID
  //       const filteredData = jsonData.filter(
  //         (realData) => realData.userId === currentUserID
  //       );

  //       setDataGraphAmount(filteredData);
  //       console.log(filteredData);
  //       console.log(dataGraphAmount);
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des données :", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // console.log(dataGraphAmount);
  useEffect(() => {
    if (dataGraphAmount) {
      // Sort the data by date
      const sortedData = dataGraphAmount.sort((a, b) => new Date(a.Month.value) - new Date(b.Month.value));
  
      const dailyAmounts = sortedData.reduce((accumulator, item) => {
        const { Month, Amount } = item;
        const day = Month.value;
        if (accumulator[day]) {
          accumulator[day] += Amount;
        } else {
          accumulator[day] = Amount;
        }
        return accumulator;
      }, {});
  
      const Month = Object.keys(dailyAmounts);
      const amounts = Object.values(dailyAmounts);
  
      setChartDataAmount({
        labels: Month,
        datasets: [
          {
            label: "Montant",
            data: amounts,
            backgroundColor: "rgba(166, 177, 247, 1)",
          },
        ],
      });
    }
  }, [dataGraphAmount]);


  useEffect(() => {
    if (dataGraphNumber) {
      // Sort the data by date
      const sortedData = dataGraphNumber.sort((a, b) => new Date(a.Month.value) - new Date(b.Month.value));
  
      const dailyNumbers = sortedData.reduce((accumulator, item) => {
        const { Month, numberProduct } = item;
        const day = Month.value;
        if (accumulator[day]) {
          accumulator[day] += numberProduct;
        } else {
          accumulator[day] = numberProduct;
        }
        return accumulator;
      }, {});
  
      const Month = Object.keys(dailyNumbers);
      const numberProduct = Object.values(dailyNumbers);
  
      setChartDataNumber({
        labels: Month,
        datasets: [
          {
            label: "Nombre de produits",
            data: numberProduct,
            backgroundColor: "rgba(166, 177, 247, 1)",
          },
        ],
      });
    }
  }, [dataGraphNumber]);

  // useEffect(() => {
  //   if (dataGraphAmount) {
  //     const dailyAmounts = dataGraphAmount.reduce((accumulator, item) => {
  //       const { Month, Amount } = item;
  //       const day = Month.value;
  //       if (accumulator[day]) {
  //         accumulator[day] += Amount;
  //       } else {
  //         accumulator[day] = Amount;
  //       }
  //       return accumulator;
  //     }, {});

  //     const week = Object.keys(dailyAmounts);
  //     const amounts = Object.values(dailyAmounts);

  //     // Tri des semaines dans l'ordre chronologique
  //     week.sort((a, b) => new Date(a) - new Date(b));

  //     setChartDataAmount({
  //       labels: week,
  //       datasets: [
  //         {
  //           label: "Montant",
  //           data: amounts,
  //           backgroundColor: "rgba(166, 177, 247, 1)",
  //         },
  //       ],
  //     });
  //   }
  // }, [dataGraphAmount]);

  // // ///// NUMBER
  // // useEffect(() => {
  // //   const fetchData = async () => {
  // //     try {
  // //       const currentUserID = ls.get('userUid',{ secret: encryptionKey });

  // //       const response = await fetch(
  // //         `http://localhost:3008/api/CAMonth/${currentUserID}`
  // //       );
  // //       const jsonData = await response.json();

  // //       // Filtrer les données par userID
  // //       const filteredData = jsonData.filter(
  // //         (realData) => realData.userId === currentUserID
  // //       );

  // //       setDataGraphNumber(filteredData);
  // //       console.log(filteredData);
  // //       console.log(dataGraphAmount);
  // //     } catch (error) {
  // //       console.error("Erreur lors de la récupération des données :", error);
  // //     }
  // //   };

  // //   fetchData();
  // // }, []);

  // // console.log(dataGraphNumber);

  // useEffect(() => {
  //   if (dataGraphNumber) {
  //     const dailyNumbers = dataGraphNumber.reduce((accumulator, item) => {
  //       const { Month, numberProduct } = item;
  //       const day = Month.value;
  //       if (accumulator[day]) {
  //         accumulator[day] += numberProduct;
  //       } else {
  //         accumulator[day] = numberProduct;
  //       }
  //       return accumulator;
  //     }, {});

  //     const week = Object.keys(dailyNumbers);
  //     const numberProduct = Object.values(dailyNumbers);

  //     // Tri des semaines dans l'ordre chronologique
  //     week.sort((a, b) => new Date(a) - new Date(b));

  //     setChartDataNumber({
  //       labels: week,
  //       datasets: [
  //         {
  //           label: "Nombre de produits",
  //           data: numberProduct,
  //           backgroundColor: "rgba(166, 177, 247, 1)",
  //         },
  //       ],
  //     });
  //   }
  // }, [dataGraphNumber]);

  return (
    <div>
      <div className="container">
      <h1 className="graph-title">
        Graphique d'évolution de Chiffre d'affaire généré par mois</h1>
        <div className="graph-item">
          {chartDataAmount &&
            chartDataAmount.labels &&
            chartDataAmount.datasets && (
              <Bar
                className="graph-item1"
                data={chartDataAmount}
                options={{ maintainAspectRatio: false }}
              />
            )}

        </div>
        </div>
        <br></br>
        <div className="container">
        <h1 className="graph-title">
          Graphique d'évolution du nombre de produits (ventes générées) par mois
        </h1>
        <div className="graph-item">
          {chartDataNumber &&
            chartDataNumber.labels &&
            chartDataNumber.datasets && (
              <Bar
                data={chartDataNumber}
                options={{ maintainAspectRatio: false }}
              />
            )}
        </div>
      </div>
    </div>
    
  );
};

export default GraphComponentMonth;
