import Menu from "./Menu";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Data360.css";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import DashData from "./DashData";
import ProvidersKPI from "./ProvidersKPI";
import ls from 'localstorage-slim'; 


const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);
// import GraphComponentWeek from "./GraphWeek";

function Data360() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [selectedAnalysis, setSelectedAnalysis] = useState("Global");
  // const fetchUserName = async () => {
  //   try {
  //     const q = query(collection(db, "users"), where("uid", "==", user?.uid));
  //     const doc = await getDocs(q);
  //     const data = doc.docs[0].data();
  //     setName(data.name);
  //   } catch (err) {
  //     console.error(err);
  //     alert("An error occured while fetching user data");
  //   }
  // };
  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
    if (loading) return;
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user, loading]);

  const handleAnalysisChange = (analysis) => {
    setSelectedAnalysis(analysis);
  };

  return (
    <div className="Customers">
      <div className="Customers__container">
       
        <button className="Customers__btn" onClick={logout}>
          Se deconnecter
        </button>
      </div>
    
      <div className="menu__container">
        <Menu />
      </div>
      <div className="principal__container">
        <p></p>
        <h1> Que veux-tu analyser ?</h1>
        <div className="analysis-buttons">
          <button
            onClick={() => handleAnalysisChange("Global")}
            className={
              selectedAnalysis === "Global" ? "active" : "period-button"
            }
          >
            Vision Globale
          </button>
          <button
            onClick={() => handleAnalysisChange("Providers")}
            className={
              selectedAnalysis === "Providers" ? "active" : "period-button"
            }
          >
            Vision Prestataires
          </button>
        </div>
        {selectedAnalysis === "Global" && <DashData />}
        {selectedAnalysis === "Providers" && <ProvidersKPI />}
      </div>
    </div>
  );
}
export default Data360;

// Ce code est un composant React appelé "Customers" qui affiche un tableau de bord pour un utilisateur authentifié. Le composant utilise Firebase pour récupérer les données utilisateur à partir de Firestore.
// Les dépendances nécessaires pour le composant sont importées. Les hooks useAuthState et useNavigate sont utilisés pour récupérer l'état d'authentification de l'utilisateur et pour naviguer vers d'autres pages. La feuille de style pour le composant est également importée, ainsi que les fonctions d'authentification et de base de données de Firebase.
// Les variables d'état sont initialisées en utilisant les hooks useAuthState et useState. user contient l'objet utilisateur authentifié, loading est un booléen indiquant si la récupération de l'état d'authentification est en cours, error contient une erreur éventuelle, et name contiendra le nom de l'utilisateur. navigate est initialisé avec useNavigate pour pouvoir naviguer vers d'autres pages.
// fetchUserName est une fonction asynchrone qui utilise les fonctions Firestore de Firebase pour récupérer les données de l'utilisateur. La requête Firestore est effectuée en utilisant query, collection et where, qui récupèrent les données de l'utilisateur dont l'ID correspond à celui de l'utilisateur connecté. Les données récupérées sont stockées dans l'objet data, qui est utilisé pour mettre à jour la variable d'état name.
// En cas d'erreur, une alerte est affichée à l'utilisateur.
// Le hook useEffect est utilisé pour exécuter du code au moment où le composant est monté ou mise à jour. Dans ce cas, le code est exécuté à chaque fois que les variables d'état user et loading sont modifiées. Si l'état de chargement est vrai, la fonction est ignorée. Si l'utilisateur n'est pas connecté, la fonction navigate est utilisée pour rediriger l'utilisateur vers la page d'accueil.
//  Sinon, la fonction fetchUserName est appelée pour récupérer le nom de l'utilisateur.

// Enfin, le code HTML est rendu pour afficher le tableau de bord de l'utilisateur. Les éléments HTML sont enveloppés dans deux divs avec les classes `Customers` et `Customers__container`. Le nom de l'utilisateur et son adresse e-mail sont affichés dans des divs distincts, et un bouton de déconnexion est ajouté qui appelle la fonction `logout` lorsque l'utilisateur clique dessus.
// En résumé, ce composant React récupère l'état d'authentification de l'utilisateur en utilisant Firebase, récupère les données de l'utilisateur à partir de Firestore, affiche le nom et l'adresse e-mail de l'utilisateur dans un tableau de bord et permet à l'utilisateur de se déconnecter.
