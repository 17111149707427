// import React from "react";
// import Signup from "./components/Signup";
// import Login from "./components/Login";
// import Dashboard from "./components/Dashboard";
// import PrivateRoute from "./components/PrivateRoute";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AuthProvider } from "./contexts/AuthContext";

// // function App() {
// //   return (
// //     <Router>

// //       <div className="App">
// //         <nav className="navbar navbar-expand-lg navbar-light fixed-top">
// //           <div className="container">
// //             <Link className="navbar-brand" to={"/sign-in"}>
// //               positronX
// //             </Link>
// //             <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
// //               <ul className="navbar-nav ml-auto">
// //                 <li className="nav-item">
// //                   <Link className="nav-link" to={"/sign-in"}>
// //                     Login
// //                   </Link>
// //                 </li>
// //                 <li className="nav-item">
// //                   <Link className="nav-link" to={"/Signup"}>
// //                     Sign up
// //                   </Link>
// //                 </li>
// //               </ul>
// //             </div>
// //           </div>
// //         </nav>
// //         <div className="auth-wrapper">
// //           <div className="auth-inner">
// //             <Routes>
// //               <Route exact path="/" element={<Login />} />
// //               <Route path="/sign-in" element={<Login />} />
// //               <Route path="/Signup" element={<Signup />} />
// //               <PrivateRoute exact path="/" component={Dashboard} />
// //             </Routes>
// //           </div>
// //         </div>
// //       </div>
// //     </Router>
// //   );
// // }
// // export default App;

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <AuthProvider>
//           <Routes>
//             <PrivateRoute exact path="/" component={Dashboard} />
//             <Route path="/signup" component={Signup} />
//             <Route path="/login" component={Login} />
//           </Routes>
//         </AuthProvider>
//       </Router>
//     </div>
//   );
// }

// export default App;

// // Ce code définit le composant App qui rend un composant Router de react-router-dom et contient un composant Route.
// // Le composant Route est utilisé pour afficher un composant spécifique en fonction de l'URL actuelle. Dans ce cas, la route "/login" est associée au composant Login, qui sera rendu lorsque l'utilisateur accède à l'URL "/login".
// // Le exact prop signifie que le chemin "/login" doit correspondre exactement pour que le composant Login soit rendu. Si le chemin "/login" correspondait également à une autre route, cette autre route serait affichée à la place.
// // Le composant App peut être considéré comme un point d'entrée pour l'application, où les différentes routes sont définies et les composants sont rendus en fonction de l'URL actuelle.

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Reset from "./components/Reset";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import Customers from "./components/Customers";
import Data360 from "./components/Data360";
import Payment from "./components/Payment";
import Goals from "./components/Goals";
import Prevision from "./components/Prevision";
import FaqPage from "./components/FaqPage";
import NotPremiumPage from "./components/NotPremium";
import PageNotFound from "./components/PageNotFound";
import KnowledgeBase from "./components/KnowledgeBase";
// import PrevisionsChargesMonth from "./components/PrevisionsChargesMonth";
// import ShowProducts from "./components/ShowProducts";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/InsertData" element={<Customers />} />
          <Route exact path="/Forecasts" element={<Prevision />} />
          <Route exact path="/Data360" element={<Data360 />} />
          <Route exact path="/Payment" element={<Payment />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/Goals" element={<Goals />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/Faq" element={<FaqPage />} />
          <Route exact path="/NotPremiumPage" element={<NotPremiumPage />} />
          <Route exact path="/KnowledgeBase" element={<KnowledgeBase />} />
          {/* <Route exact path="/TEST" element={<PrevisionsChargesMonth />} /> */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
