import React, { useState, useEffect , useRef }from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import GraphComponentWeek from "./GraphWeek.js";
import GraphComponentMonth from "./GraphMonth.js";
import GraphComponentYear from "./GraphYear.js";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import FooterComponent from "./Footer";
import { auth, db, logout } from "./firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import ls from 'localstorage-slim'; 
import { Oval } from "react-loader-spinner"; // Import the Oval spinner
import { Grid } from "react-loader-spinner"; // Import the Oval spinner
import { Tooltip  } from 'react-tooltip';
const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);





function DashData() {
  const [areGraphsRendered, setAreGraphsRendered] = useState(false);

  const weekGraphRef = useRef(null);
  const monthGraphRef = useRef(null);
  const yearGraphRef = useRef(null);

  const [pdfLoading, setPdfLoading] = useState(false);
  const user = ls.get('userUid', { secret: encryptionKey });
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [loadingDataWeek, setLoadingDataWeek] = useState(true);
  const [loadingValidatedPaymentValue, setLoadingDataValidated] = useState(true);

  useEffect(() => {
    const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
   
    if (!currentUserID) {
      navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
    }
  }, [user]);

  const [inputValue, setInputValue] = useState({
    Monthly: "",
    Yearly: "",
    Weekly: "",
  });
  const [realValue, setRealValue] = useState(null);
  const [ValidatedPaymentValue, setValidatedPaymentValue] = useState(null);
  const [data, setData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("Week");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (weekGraphRef.current && monthGraphRef.current && yearGraphRef.current) {
      setAreGraphsRendered(true);
    }
  }, [weekGraphRef, monthGraphRef, yearGraphRef]);

  // const exportToPDF = () => {
  //   console.log(weekGraphRef.current, monthGraphRef.current ,yearGraphRef.current );
  //   if (areGraphsRendered) {
  //     setPdfLoading(true);

  //     // Use html2canvas to capture the entire content
  //     html2canvas(document.body).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF();
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //       pdf.save("dashboard.pdf");

  //       setPdfLoading(false);
  //     });
    // }
  // };



 const [userFirebase, loading] = useAuthState(auth);
  useEffect(() => {
    // console.log(userFirebase)
    if (userFirebase) {
      console.log("User connected");

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(userFirebase.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
              // const apiUrl =  `http://localhost:8080/api/data/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/data/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingDataWeek(false);
                  }
                  return response.json();
                })
                .then((data) => {
                  setRealValue(data[0]);
                  setLoadingDataWeek(false);
                  // console.log('Récupération des données ventes avec succès',realValue);
                })
                .catch((error) => {
                  setLoadingDataWeek(false);
                  setError(error.message);
                });
            } else {
              setLoadingDataWeek(false);
              console.error("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataWeek(false);
            console.error("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((error) => {
          setLoadingDataWeek(false);
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
        });
    }
  }, [userFirebase]);



  useEffect(() => {
    // console.log(userFirebase)
    if (userFirebase) {
      console.log("User connected");

      // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
      firebase
        .firestore()
        .collection("users")
        .doc(userFirebase.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const storedToken = userData.token;

            if (storedToken) {
              const currentUserID = ls.get('userUid', { secret: encryptionKey });
              // Utilisez le token stocké pour vos requêtes API
          //    const apiUrl =  `http://localhost:3008/api/validatedPaymentAgg/${currentUserID}`;
              const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/validatedPaymentAgg/${currentUserID}`;

              fetch(apiUrl, {
                headers: {
                  Authorization: `Bearer ${storedToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Réponse réseau incorrecte');
                    setLoadingDataValidated(false);
                  }
                  return response.json();
                })
                .then((data) => {
                  setValidatedPaymentValue(data[0]);
                  setLoadingDataValidated(false);
                  // console.log('Récupération des données ventes avec succès', data[0] , ValidatedPaymentValue);
                })
                .catch((error) => {
                  setLoadingDataValidated(false);
                  setError(error.message);
                });
            } else {
              setLoadingDataValidated(false);
              console.error("Le token n'est pas présent dans Firestore.");
            }
          } else {
            setLoadingDataValidated(false);
            console.error("L'utilisateur n'existe pas dans Firestore.");
          }
        })
        .catch((error) => {
          setLoadingDataValidated(false);
          console.error("Erreur lors de la récupération du token depuis Firestore:", error);
        });
    }
  }, [userFirebase]);


  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="dashboard-container">
      <h1> Analyse tes chiffres sur les différentes périodes </h1>
      <div className="period-buttons">
        <button
          onClick={() => handlePeriodChange("Week")}
          className={selectedPeriod === "Week" ? "active" : "period-button"}
        >
          Semaine
        </button>
        <button
          onClick={() => handlePeriodChange("Month")}
          className={selectedPeriod === "Month" ? "active" : "period-button"}
        >
          Mois
        </button>
        <button
          onClick={() => handlePeriodChange("Year")}
          className={selectedPeriod === "Year" ? "active" : "period-button"}
        >
          Année
        </button>
      </div>
      <br></br>
{loadingDataWeek==true &&(
        <div className="loading-container">
             <div className="loading-animation">
               <Grid
                 color="#A6B1F7" // Set the color
                 height={100} // Set the size
                 width={50}
                 secondaryColor="#7F8BCC"
                 fill="none"
                 strokeWidth={8}
               />
             </div>
           </div>
)}
      {!realValue && !loadingDataWeek && (
        <span> Il n'y a pas de données pour le moment, ajoute des données pour pouvoir les analyser </span>
      )}
      {realValue  &&(
        <div className="chart-container">
          <p>
<Tooltip id="my-tooltip" />
            {selectedPeriod === "Week" && (
              <div className="indicator">
               
                <div className="LastPeriode" >
                  <h1>Indicateurs sur la semaine en cours</h1>
                  <div className="indicator-item" >
                    <span className="indicator-title">
                      Chiffre d'affaire généré          
                        {" "}
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de la semaine en cours" /><br></br>
                    <span className="indicator-value" >
                      {/* {realValue.Week.toFixed(2)} */}
                      {realValue != null && realValue.Week != null
                    ? realValue.Week.toFixed(2)
                      : 0}
                      </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" >
                      {" "}
                      Chiffre d'affaire généré sans remboursement <br></br>
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de la semaine en cours remboursements déduits" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {realValue.WeekWoRetractation.toFixed(2)} */}
                      {realValue != null && realValue.WeekWoRetractation != null
                    ? realValue.WeekWoRetractation.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé depuis le début de la semaine en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {ValidatedPaymentValue?.validatedPaymentWeek} */}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentWeek != null
                    ? ValidatedPaymentValue.validatedPaymentWeek.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA  
                </span>
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée depuis le début de la semaine en cours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVAWeek.toFixed(2)} */}
                  {realValue != null && realValue.totalTVAWeek != null
                    ? realValue.totalTVAWeek.toFixed(2)
                      : 0}
                </span>
                </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span>
                <img className="infoImage" alt="Background" src="question7.png" data-html="true"   data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen des clients depuis le début de la semaine en cours, équivaut à la dépense moyenne d'un client (Somme du chiffre d'affaire / Nombre de client ) " /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.Week!= null && realValue.nbCustomerWeek != null
                    ? realValue.nbCustomerWeek !== 0
                      ? realValue.Week / realValue.nbCustomerWeek
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>
                  <div className="indicator-item">
                    <span className="indicator-title" >Nombre de clients</span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de la semaine en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerWeek}
                    </span>
                    
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title"  >
                      Nombre de clients sans retractations
                    </span> 
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de la semaine en cours, retractations déduites" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerWeekWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" >
                      Nombre de retractations  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations depuis le début de la semaine en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbRetractationWeek}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements depuis le début de la semaine en cours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.sumRefundWeek.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundWeek != null
                    ? realValue.sumRefundWeek.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="RollingPeriod">
                  <h1>Indicateurs sur la semaine roulante</h1>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.RollingWeek.toFixed(2)} */}
                      {realValue != null && realValue.RollingWeek != null
                    ? realValue.RollingWeek.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" >
                      Chiffre d'affaire généré sans remboursement  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur les 7 derniers jours retractations déduites" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.RollingWeekWoRetractation.toFixed(2)} */}
                      {realValue != null && realValue.RollingWeekWoRetractation != null
                    ? realValue.RollingWeekWoRetractation.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentRollingWeek != null
                    ? ValidatedPaymentValue.validatedPaymentRollingWeek.toFixed(2)
                      : 0}
                      {/* {ValidatedPaymentValue?.validatedPaymentRollingWeek} */}
                    </span>
                  </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA 
                </span>
                 <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée sur les 7 derniers jours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVARollingWeek.toFixed(2)} */}
                  {realValue != null && realValue.totalTVARollingWeek != null
                    ? realValue.totalTVARollingWeek.toFixed(2)
                      : 0}
                </span>
                </div>
                  <div className="indicator-item">
                  <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span>
                 <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen sur les 7 derniers jours, équivaut à la dépense moyenne d'un client (somme du Chiffre d'affaire / nombre de clients)" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.RollingWeek != null && realValue.nbCustomerRollingWeek != null
                    ? realValue.nbCustomerRollingWeek !== 0
                      ? realValue.RollingWeek / realValue.nbCustomerRollingWeek
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>
                  <div className="indicator-item">
                    <span className="indicator-title" >Nombre de clients</span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingWeek}
                      
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de clients sans retractation  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingWeekWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de retractations 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbRetractationRollingWeek}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements sur les 7 derniers jours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {realValue.sumRefundRollingWeek.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundRollingWeek != null
                    ? realValue.sumRefundRollingWeek.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="GraphSection"  ref={weekGraphRef}>
                  <GraphComponentWeek />
                </div>
              </div>
            )}
            {selectedPeriod === "Month" && (
              <div className="indicator">
                <div className="LastPeriode">
                  <h1>Indicateurs sur le mois en cours</h1>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.Month.toFixed(2)} */}
                      {realValue != null && realValue.Month != null
                    ? realValue.Month.toFixed(2)
                      : 0}
                      </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      {" "}
                      Chiffre d'affaire généré sans remboursement 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début du mois en cours remboursement déduis" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.MonthWoRetractation?realValue.MonthWoRetractation.toFixed(2):0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {ValidatedPaymentValue?.validatedPaymentMonth} */}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentMonth != null
                    ? ValidatedPaymentValue.validatedPaymentMonth.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA 
                </span>
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée depuis le début du mois en cours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVAMonth.toFixed(2)} */}
                  {realValue != null && realValue.totalTVAMonth != null
                    ? realValue.totalTVAMonth.toFixed(2)
                      : 0}
                </span>
                </div>
                  <div className="indicator-item">
                  <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span>
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen depuis le début du mois en cours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.Month!= null && realValue.nbCustomerMonth != null
                    ? realValue.nbCustomerMonth !== 0
                      ? realValue.Month / realValue.nbCustomerMonth
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>


                  <div className="indicator-item">
                    <span className="indicator-title">Nombre de clients</span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerMonth}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de clients sans retractations  
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerMonthWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de retractations
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbRetractationMonth}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements depuis le début du mois en cours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.sumRefundMonth.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundMonth != null
                    ? realValue.sumRefundMonth.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="RollingPeriod">
                  <h1>Indicateurs sur le mois roulant</h1>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.RollingMonth.toFixed(2)} */}
                      {realValue != null && realValue.RollingMonth != null
                    ? realValue.RollingMonth.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré sans remboursement 
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {realValue.RollingMonthWoRetractation?realValue.RollingMonthWoRetractation.toFixed(2):0}
                    </span>
                  </div>     
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {ValidatedPaymentValue?.validatedPaymentRollingMonth} */}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentRollingMonth != null
                    ? ValidatedPaymentValue.validatedPaymentRollingMonth.toFixed(2)
                      : 0}
                    </span>
                  </div>      
                <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA 
                </span>
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée sur le dernier mois" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVARollingMonth.toFixed(2)} */}
                  {realValue != null && realValue.totalTVARollingMonth != null
                    ? realValue.totalTVARollingMonth.toFixed(2)
                      : 0}
                </span>
                </div>

                  <div className="indicator-item">
                  <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span> 
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen généré sur le dernier mois" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.RollingMonth != null && realValue.nbCustomerRollingMonth != null
                    ? realValue.nbCustomerRollingMonth !== 0
                      ? realValue.RollingMonth / realValue.nbCustomerRollingMonth
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>

                  <div className="indicator-item">
                    <span className="indicator-title">Nombre de clients</span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingMonth}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de clients sans retractation 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingMonthWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de retractations 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbRetractationRollingMonth}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements sur le dernier mois" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {realValue.sumRefundRollingMonth.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundRollingMonth != null
                    ? realValue.sumRefundRollingMonth.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="GraphSection"ref={monthGraphRef}>
                  <GraphComponentMonth />
                </div>
              </div>
            )}
            {selectedPeriod === "Year" && (
              <div className="indicator">
                <div className="LastPeriode">
                  <h1>Indicateurs sur l'année en cours</h1>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.Year.toFixed(2)} */}
                      {realValue != null && realValue.Year != null
                    ? realValue.Year.toFixed(2)
                      : 0}
                      </span>
                  </div>

                  <div className="indicator-item">
                    <span className="indicator-title">
                      {" "}
                      Chiffre d'affaire généré sans remboursement
                    </span>
                      <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.YearWoRetractation ? realValue.YearWoRetractation.toFixed(2):0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {ValidatedPaymentValue?.validatedPaymentYear} */}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentYear != null
                    ? ValidatedPaymentValue.validatedPaymentYear.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA
                </span>
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée depuis le début de l'année en cours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVAYear.toFixed(2)} */}
                  {realValue != null && realValue.totalTVAYear != null
                    ? realValue.totalTVAYear.toFixed(2)
                      : 0}
                </span>
                </div>
                  <div className="indicator-item">
                  <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span> 
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen généré depuis le début de l'année en cours" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.Year != null && realValue.nbCustomerYear != null
                    ? realValue.nbCustomerYear !== 0
                      ? realValue.Year / realValue.nbCustomerYear
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>
                  <div className="indicator-item">
                    <span className="indicator-title">Nombre de clients</span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerYear}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de clients sans retractations  
                    </span>
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbCustomerYearWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de retractations 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations généré depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {realValue.nbRetractationYear}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements depuis le début de l'année en cours" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.sumRefundYear.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundYear != null
                    ? realValue.sumRefundYear.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="RollingPeriod">
                  <h1>Indicateurs sur l'année roulante</h1>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {/* {realValue.RollingYear.toFixed(2)} */}
                      {realValue != null && realValue.RollingYear != null
                    ? realValue.RollingYear.toFixed(2)
                      : 0}
                    </span>
                  </div>
    
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Chiffre d'affaire généré sans remboursement 
                    </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {realValue.RollingYearWoRetractation? realValue.RollingYearWoRetractation.toFixed(2):0}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title" data-tooltip-id="my-tooltip" >
                      {" "}
                      Chiffre d'affaire encaissé 
                    </span> 
                    <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire encaissé sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {ValidatedPaymentValue?.validatedPaymentRollingYear} */}
                      {ValidatedPaymentValue != null && ValidatedPaymentValue.validatedPaymentRollingYear != null
                    ? ValidatedPaymentValue.validatedPaymentRollingYear.toFixed(2)
                      : 0}
                    </span>
                  </div>
                  <div className="indicator-item">
                <span className="indicator-title" >
                  {" "}
                  TVA 
                </span> 
                <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="TVA facturée depuis sur la dernière année" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {/* {realValue.totalTVARollingYear.toFixed(2)} */}
                  {realValue != null && realValue.totalTVARollingYear != null
                    ? realValue.totalTVARollingYear.toFixed(2)
                      : 0}
                </span>
                </div>
                  <div className="indicator-item">
                  <span className="indicator-title" >
                  {" "}
                  Panier Moyen 
                </span>
                 <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Panier Moyen des clients sur la dernière année" /><br></br>
                <span className="indicator-value">
                  {" "}
                  {realValue.RollingYear != null && realValue.nbCustomerRollingYear != null
                    ? realValue.nbCustomerRollingYear !== 0
                      ? realValue.RollingYear / realValue.nbCustomerRollingYear
                      : "N/A"
                    : "Données non disponibles"}
                </span>
                </div>
                  <div className="indicator-item">
                    <span className="indicator-title">Nombre de clients </span>
                     <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingYear}
                    </span>
                  </div>        
                  
               
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de clients sans retractation
                    </span>  <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbCustomerRollingYearWoRetractation}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Nombre de retractations 
                    </span> <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractations ayant fait un achat sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {realValue.nbRetractationRollingYear}
                    </span>
                  </div>
                  <div className="indicator-item">
                    <span className="indicator-title">
                      Somme des remboursements 
                    </span> <img className="infoImage" alt="Background" src="question7.png"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements sur la dernière année" /><br></br>
                    <span className="indicator-value">
                      {" "}
                      {/* {realValue.sumRefundRollingYear.toFixed(2)} */}
                      {realValue != null && realValue.sumRefundRollingYear != null
                    ? realValue.sumRefundRollingYear.toFixed(2)
                      : 0}
                    </span>
                  </div>
                </div>
                <br></br>
                <div className="GraphSection">
                  <GraphComponentYear />
                </div>
              </div>
            )}
            {!(
              realValue.Week > 0 ||
              realValue.Month > 0 ||
              realValue.Year > 0
            ) && <span className="indicator-value">Pas de valeur</span>}
          </p>
        </div>
      )}
      {/* {realValue && (
    <div className="export-button">
      <button onClick={exportToPDF}>Exporter en PDF</button>
    </div>
  )} */}
  <FooterComponent id ="Footer"  className="Footer" />
    </div>
  );
}

export default DashData;



// import React, { useState, useEffect , useRef }from "react";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import GraphComponentWeek from "./GraphWeek.js";
// import GraphComponentMonth from "./GraphMonth.js";
// import GraphComponentYear from "./GraphYear.js";

// import { useAuthState } from "react-firebase-hooks/auth";
// import { useNavigate } from "react-router-dom";

// import { auth, db, logout } from "./firebase";
// import { query, getDocs, collection, where } from "firebase/firestore";
// import ls from 'localstorage-slim'; 
// import { Oval } from "react-loader-spinner"; // Import the Oval spinner
// import { Grid } from "react-loader-spinner"; // Import the Oval spinner
// import { Tooltip  } from 'react-tooltip';
// const encryptionKey = parseInt(process.env.REACT_APP_TEST,10);



// function DashData() {
//   const [areGraphsRendered, setAreGraphsRendered] = useState(false);

//   const weekGraphRef = useRef(null);
//   const monthGraphRef = useRef(null);
//   const yearGraphRef = useRef(null);

//   const [pdfLoading, setPdfLoading] = useState(false);
//   const user = ls.get('userUid', { secret: encryptionKey });
//   const [name, setName] = useState("");
//   const navigate = useNavigate();
//   const [loadingDataWeek, setLoadingDataWeek] = useState(true);
//   const [loadingValidatedPaymentValue, setLoadingDataValidated] = useState(true);

//   useEffect(() => {
//     const currentUserID =  ls.get('userUid',{ secret: encryptionKey });
   
//     if (!currentUserID) {
//       navigate('/Login'); // Redirige l'utilisateur vers la page de connexion
//     }
//   }, [user]);

//   // const fetchUserName = async () => {
//   //   try {
//   //     const q = query(collection(db, "users"), where("uid", "==", user));
//   //     const doc = await getDocs(q);
//   //     const data = doc.docs[0].data();
//   //     setName(data.name);
//   //   } catch (err) {
//   //     console.error(err);
//   //     alert("An error occured while fetching user data");
//   //   }
//   // };

  
//   const [inputValue, setInputValue] = useState({
//     Monthly: "",
//     Yearly: "",
//     Weekly: "",
//   });
//   const [realValue, setRealValue] = useState(null);
//   const [ValidatedPaymentValue, setValidatedPaymentValue] = useState(null);
//   const [data, setData] = useState([]);
//   const [selectedPeriod, setSelectedPeriod] = useState("Week");
//   const [error, setError] = useState(null);
//   // const exportToPDF = () => {
//   //   const container = document.querySelector(".dashboard-container");
   


//   //   html2canvas(container).then((canvas) => {
//   //     const imgData = canvas.toDataURL("image/png");
//   //     const pdf = new jsPDF();
//   //     const imgProps = pdf.getImageProperties(imgData);
//   //     const pdfWidth = pdf.internal.pageSize.getWidth();
//   //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//   //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//   //     pdf.save("dashboard.pdf");
//   //   });
//   // };


//   // useEffect(() => {
//   //   if (weekGraphRef.current || monthGraphRef.current || yearGraphRef.current) {
//   //     setAreGraphsRendered(true);
//   //   }
//   // }, [weekGraphRef, monthGraphRef, yearGraphRef]);

//   // const exportToPDF = () => {
//   //   console.log(weekGraphRef.current, monthGraphRef.current ,yearGraphRef.current );
//   //   if (areGraphsRendered) {
//   //     setPdfLoading(true);

//   //     // Use html2canvas to capture the entire content
//   //     html2canvas(document.body).then((canvas) => {
//   //       const imgData = canvas.toDataURL("image/png");
//   //       const pdf = new jsPDF();
//   //       const pdfWidth = pdf.internal.pageSize.getWidth();
//   //       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
//   //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//   //       pdf.save("dashboard.pdf");

//   //       setPdfLoading(false);
//   //     });
//   //   }
//   // };
//   const exportToPDF = () => {
//     // Capture la zone spécifique (par exemple, div contenant les graphiques)
//     const graphSection = document.querySelector(".GraphSection");
//     const indicatorSection = document.querySelector(".indicator");

//     // Vérifie si la section est présente
//     if (graphSection) {
//       // Capture la section avec html2canvas
//       html2canvas(graphSection).then((canvas) => {
//         // Convertit le canvas en image au format base64
//         const graphImage = canvas.toDataURL("image/png");

//         // Initialise le PDF
//         const pdf = new jsPDF();
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//         // Ajoute l'image capturée au PDF
//         pdf.addImage(graphImage, "PNG", 0, 0, pdfWidth, pdfHeight);

//         // Enregistre le PDF
//         pdf.save("resultats.pdf");
//       });
//     }
//     if (indicatorSection) {
//     const pdf = new jsPDF();

//       // Récupère les dimensions de la section à convertir en PDF
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();

//       // Convertit le contenu de la div en PDF
//       pdf.text(15, 15, indicatorSection.innerText);

//       // Enregistre le PDF
//       pdf.save("indicator.pdf");
//       };
//   };


//  const [userFirebase, loading] = useAuthState(auth);
//   useEffect(() => {
//     console.log(userFirebase)
//     if (userFirebase) {
//       console.log("User connected");

//       // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
//       firebase
//         .firestore()
//         .collection("users")
//         .doc(userFirebase.uid)
//         .get()
//         .then((doc) => {
//           if (doc.exists) {
//             const userData = doc.data();
//             const storedToken = userData.token;

//             if (storedToken) {
//               const currentUserID = ls.get('userUid', { secret: encryptionKey });
//               // Utilisez le token stocké pour vos requêtes API
//               // const apiUrl =  `http://localhost:8080/api/data/${currentUserID}`;
//               const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/data/${currentUserID}`;

//               fetch(apiUrl, {
//                 headers: {
//                   Authorization: `Bearer ${storedToken}`,
//                 },
//               })
//                 .then((response) => {
//                   if (!response.ok) {
//                     throw new Error('Réponse réseau incorrecte');
//                     setLoadingDataWeek(false);
//                   }
//                   return response.json();
//                 })
//                 .then((data) => {
//                   setRealValue(data[0]);
//                   setLoadingDataWeek(false);
//                   console.log('Récupération des données ventes avec succès',realValue);
//                 })
//                 .catch((error) => {
//                   setLoadingDataWeek(false);
//                   setError(error.message);
//                 });
//             } else {
//               setLoadingDataWeek(false);
//               console.error("Le token n'est pas présent dans Firestore.");
//             }
//           } else {
//             setLoadingDataWeek(false);
//             console.error("L'utilisateur n'existe pas dans Firestore.");
//           }
//         })
//         .catch((error) => {
//           setLoadingDataWeek(false);
//           console.error("Erreur lors de la récupération du token depuis Firestore:", error);
//         });
//     }
//   }, [userFirebase]);



//   useEffect(() => {
//     console.log(userFirebase)
//     if (userFirebase) {
//       console.log("User connected");

//       // Utilisateur connecté, obtenez le token Firebase à partir de Firestore
//       firebase
//         .firestore()
//         .collection("users")
//         .doc(userFirebase.uid)
//         .get()
//         .then((doc) => {
//           if (doc.exists) {
//             const userData = doc.data();
//             const storedToken = userData.token;

//             if (storedToken) {
//               const currentUserID = ls.get('userUid', { secret: encryptionKey });
//               // Utilisez le token stocké pour vos requêtes API
//               const apiUrl =  `http://localhost:3008/api/validatedPaymentAgg/${currentUserID}`;
//               // const apiUrl =  `https://dataction-api-service-lyipbgzvaq-ew.a.run.app/api/validatedPaymentAgg/${currentUserID}`;

//               fetch(apiUrl, {
//                 headers: {
//                   Authorization: `Bearer ${storedToken}`,
//                 },
//               })
//                 .then((response) => {
//                   if (!response.ok) {
//                     throw new Error('Réponse réseau incorrecte');
//                     setValidatedPaymentValue(false);
//                   }
//                   return response.json();
//                 })
//                 .then((data) => {
//                   setValidatedPaymentValue(data[0]);
//                   setLoadingDataValidated(false);
//                   console.log('Récupération des données ventes avec succès',ValidatedPaymentValue);
//                 })
//                 .catch((error) => {
//                   setLoadingDataValidated(false);
//                   setError(error.message);
//                 });
//             } else {
//               setLoadingDataValidated(false);
//               console.error("Le token n'est pas présent dans Firestore.");
//             }
//           } else {
//             setLoadingDataValidated(false);
//             console.error("L'utilisateur n'existe pas dans Firestore.");
//           }
//         })
//         .catch((error) => {
//           setLoadingDataValidated(false);
//           console.error("Erreur lors de la récupération du token depuis Firestore:", error);
//         });
//     }
//   }, [userFirebase]);

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     try {
//   //       const currentUserID = ls.get('userUid', { secret: encryptionKey });
  
//   //       if (!currentUserID) {
//   //         throw new Error("ID utilisateur non valide");
//   //       }
  
//   //       const response = await fetch(
//   //         `http://localhost:3008/api/data/${currentUserID}`,
//   //         {
//   //           headers: {
//   //             'x-api-key': process.env.REACT_APP_API_KEY,
//   //           },
//   //         }
//   //       );
  
//   //       if (!response.ok) {
//   //         throw new Error("Erreur lors de la récupération des données");
//   //       }
  
//   //       const jsonData = await response.json();
  
//   //       // Filtrer les données par userID
//   //       const filteredData = jsonData.filter(
//   //         (realData) => realData.userId === currentUserID
//   //       );
//   //       console.log(filteredData);
//   //       setData(filteredData);
//   //       setRealValue(filteredData[0]);
//   //     } catch (error) {
//   //       console.error("Erreur lors de la récupération des données :", error.message);
//   //       // Gérer l'erreur de manière appropriée pour l'utilisateur
//   //     }
//   //   };
  
//   //   fetchData();
//   // }, []);



// //  useEffect(() => {
// //   const fetchData = async () => {
// //     try {
// //       const currentUserID = ls.get('userUid', { secret: encryptionKey });

// //       const response = await fetch(
// //         `http://localhost:3008/api/data/${currentUserID}`,
// //         {
// //           headers: {
// //             'x-api-key': process.env.REACT_APP_API_KEY, // Remplacez par votre véritable clé API
// //           },
// //         }
// //       );

// //       const jsonData = await response.json();

// //       // Filtrer les données par userID
// //       const filteredData = jsonData.filter(
// //         (realData) => realData.userId === currentUserID
// //       );
// //       console.log(filteredData);
// //       setData(filteredData);
// //       setRealValue(filteredData[0]);
// //     } catch (error) {
// //       console.error("Erreur lors de la récupération des données :", error);
// //     }
// //   };

// //   fetchData();
// // }, []);

//   const handlePeriodChange = (period) => {
//     setSelectedPeriod(period);
//   };

//   return (
//     <div className="dashboard-container">
//       <h1> Analyse tes chiffres sur les différentes périodes </h1>
//       <div className="period-buttons">
//         <button
//           onClick={() => handlePeriodChange("Week")}
//           className={selectedPeriod === "Week" ? "active" : "period-button"}
//         >
//           Semaine
//         </button>
//         <button
//           onClick={() => handlePeriodChange("Month")}
//           className={selectedPeriod === "Month" ? "active" : "period-button"}
//         >
//           Mois
//         </button>
//         <button
//           onClick={() => handlePeriodChange("Year")}
//           className={selectedPeriod === "Year" ? "active" : "period-button"}
//         >
//           Année
//         </button>
//       </div>
//       <br></br>
// {loadingDataWeek==true &&(
//         //   <div className="loading-container">
//         //   <div className="loading-animation">
//         //     {/* Add your loading animation here */}
//         //     Calcul et chargement de vos données de paiement prestataires
//         //     <Oval
//         //       color="#A6B1F7" // Set the color
//         //       height={100} // Set the size
//         //       width={50}
//         //       secondaryColor="#7F8BCC"
//         //       fill="none"
//         //       strokeWidth={8}
//         //     />
//         //   </div>
//         // </div>
//         <div className="loading-container">
//              <div className="loading-animation">
//                {/* Add your loading animation here */}
//                {/* Calcul et chargement de vos données de paiement prestataires */}
//                <Grid
//                  color="#A6B1F7" // Set the color
//                  height={100} // Set the size
//                  width={50}
//                  secondaryColor="#7F8BCC"
//                  fill="none"
//                  strokeWidth={8}
//                />
//              </div>
//            </div>
// )}
//       {!realValue && !loadingDataWeek && (
//         <span> Il n'y a pas de données pour le moment, ajoute des données pour pouvoir les analyser </span>
//       )}
//       {realValue && ValidatedPaymentValue(
//         <div className="chart-container">
//           <p>
//             {/* <h2>
//               {" "}
//               Analyse : {selectedPeriod === "Week" && "Vue Hebdomadaire"}{" "}
//               {selectedPeriod === "Month" && "Vue Mensuelle"}{" "}
//               {selectedPeriod === "Year" && "Vue Anuelle"}{" "}
//             </h2> */}
//             {selectedPeriod === "Week" && (
//               <div className="indicator">
//                <Tooltip id="my-tooltip" />
//                 <div className="LastPeriode" >
//                   <h1>Indicateurs sur la semaine en cours</h1>
//                   <div className="indicator-item" >
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré           <img className="infoImage" alt="Background" src="question2.jpg"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de la semaine en cours" /><br></br>
//                         {" "}
//                     </span>
//                     <span className="indicator-value" >{realValue.Week.toFixed(2)}</span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis le début de la semaine en cours remboursements déduis">
//                       {" "}
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.WeekWoRetractation.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentWeek}
                      
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVAWeek.toFixed(2)}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.Week.toFixed(2) != null && realValue.nbCustomerWeek != null
//                     ? realValue.nbCustomerWeek !== 0
//                       ? realValue.Week / realValue.nbCustomerWeek
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de la semaine en cours">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerWeek}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis le début de la semaine en cours, clients ayant fait une retractation déduis ">
//                       Nombre de clients sans retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerWeekWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractation depuis le début de la semaine en cours">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbRetractationWeek)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements depuis le début de la semaine en cours">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.sumRefundWeek.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="RollingPeriod">
//                   <h1>Indicateurs sur la semaine roulante</h1>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis les 7 derniers jours">
//                       Chiffre d'affaire généré
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingWeek.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Chiffre d'affaire généré depuis les 7 derniers jours remboursements déduits">
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingWeekWoRetractation.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentRollingWeek}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVARollingWeek.toFixed(2)}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                   <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.RollingWeek.toFixed(2) != null && realValue.nbCustomerRollingWeek != null
//                     ? realValue.nbCustomerRollingWeek !== 0
//                       ? realValue.RollingWeek / realValue.nbCustomerRollingWeek
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat sur les 7 derniers jours">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingWeek}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de client ayant fait un achat depuis les 7 derniers jours, clients ayant fait une retractation déduis ">
//                       Nombre de clients sans retractation
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingWeekWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Nombre de retractation depuis les 7 derniers jours">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbRetractationRollingWeek)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title"  data-tooltip-id="my-tooltip" data-tooltip-content="Somme des remboursements depuis les 7 derniers jours">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.sumRefundRollingWeek.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="GraphSection"  ref={weekGraphRef}>
//                   <GraphComponentWeek />
//                 </div>
//               </div>
//             )}
//             {selectedPeriod === "Month" && (
//               <div className="indicator">
//                 <div className="LastPeriode">
//                   <h1>Indicateurs sur le mois en cours</h1>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré
//                     </span>
//                     <span className="indicator-value">{realValue.Month.toFixed(2)}</span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       {" "}
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.MonthWoRetractation.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentMonth}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVAMonth.toFixed(2)}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                   <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.Month.toFixed(2) != null && realValue.nbCustomerMonth != null
//                     ? realValue.nbCustomerMonth !== 0
//                       ? realValue.Month / realValue.nbCustomerMonth
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>


//                   <div className="indicator-item">
//                     <span className="indicator-title">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerMonth}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de clients sans retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerMonthWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbRetractationMonth)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.sumRefundMonth.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="RollingPeriod">
//                   <h1>Indicateurs sur le mois roulant</h1>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingMonth.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingMonthWoRetractation.toFixed(2)}
//                     </span>
//                   </div>     
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentRollingMonth}
//                     </span>
//                   </div>      
//                 <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVARollingMonth.toFixed(2)}
//                 </span>
//                 </div>

//                   <div className="indicator-item">
//                   <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.RollingMonth.toFixed(2) != null && realValue.nbCustomerRollingMonth != null
//                     ? realValue.nbCustomerRollingMonth !== 0
//                       ? realValue.RollingMonth / realValue.nbCustomerRollingMonth
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>

//                   <div className="indicator-item">
//                     <span className="indicator-title">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingMonth}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de clients sans retractation
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingMonthWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbRetractationRollingMonth)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.sumRefundRollingMonth.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="GraphSection"ref={monthGraphRef}>
//                   <GraphComponentMonth />
//                 </div>
//               </div>
//             )}
//             {selectedPeriod === "Year" && (
//               <div className="indicator">
//                 <div className="LastPeriode">
//                   <h1>Indicateurs sur l'année en cours</h1>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré
//                     </span>
//                     <span className="indicator-value">{realValue.Year.toFixed(2)}</span>
//                   </div>

//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       {" "}
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.YearWoRetractation.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentYear}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVAYear.toFixed(2)}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                   <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.Year.toFixed(2) != null && realValue.nbCustomerYear != null
//                     ? realValue.nbCustomerYear !== 0
//                       ? realValue.Year / realValue.nbCustomerYear
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerYear}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de clients sans retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbCustomerYearWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.nbRetractationYear)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.sumRefundYear.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="RollingPeriod">
//                   <h1>Indicateurs sur l'année roulante</h1>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingYear.toFixed(2)}
//                     </span>
//                   </div>
    
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Chiffre d'affaire généré sans remboursement
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.RollingYearWoRetractation.toFixed(2)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title" data-tooltip-id="my-tooltip" >
//                       {" "}
//                       Chiffre d'affaire encaissé
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {ValidatedPaymentValue.validatedPaymentRollingYear}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                 <span className="indicator-title" >
//                   {" "}
//                   TVA
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.totalTVARollingYear.toFixed(2)}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                   <span className="indicator-title" >
//                   {" "}
//                   Panier Moyen
//                 </span>
//                 <span className="indicator-value">
//                   {" "}
//                   {realValue.RollingYear.toFixed(2) != null && realValue.nbCustomerRollingYear != null
//                     ? realValue.nbCustomerRollingYear !== 0
//                       ? realValue.RollingYear / realValue.nbCustomerRollingYear
//                       : "N/A"
//                     : "Données non disponibles"}
//                 </span>
//                 </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">Nombre de clients</span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingYear}
//                     </span>
//                   </div>        
                  
               
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de clients sans retractation
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbCustomerRollingYearWoRetractation}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Nombre de retractations
//                     </span>
//                     <span className="indicator-value">
//                       {realValue.nbRetractationRollingYear)}
//                     </span>
//                   </div>
//                   <div className="indicator-item">
//                     <span className="indicator-title">
//                       Somme des remboursements
//                     </span>
//                     <span className="indicator-value">
//                       {" "}
//                       {realValue.sumRefundRollingYear.toFixed(2)}
//                     </span>
//                   </div>
//                 </div>
//                 <br></br>
//                 <div className="GraphSection" ref={yearGraphRef}>
//                   <GraphComponentYear />
//                 </div>
//               </div>
//             )}
//             {!(
//               realValue.Week > 0 ||
//               realValue.Month > 0 ||
//               realValue.Year > 0
//             ) && <span className="indicator-value">Pas de valeur</span>}
//           </p>
//         </div>
//       )}  
//       {realValue && ValidatedPaymentValue(
//     <div className="export-button">
//       <button onClick={exportToPDF}>Exporter en PDF</button>
//     </div>
//   )}
//     </div>
//   );
// }

// export default DashData;
